import { axiosInstance } from "../config/configApi";

const getPaymentPageUrl = async (id) => {
  return await axiosInstance.get("/pay/purchase?productId=" + id);
};

const confirmUserPayment = async (mpay) => {
  return await axiosInstance.get("/pay/check?mPay=" + mpay);
};

export { getPaymentPageUrl, confirmUserPayment };
