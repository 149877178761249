import { getColectAgent } from "../../statics/config";
import {
  reportingCustomEvent,
  reportingEvent
} from "../../statics/reportEventId";
import moment from "moment";

let collectAgent;

export const customInitCollectAgent = () => {
  collectAgent = getColectAgent();
};

export const sendCustomReport = (
  eventId,
  reserve1 = "",
  returncode = "",
  errorMsg = ""
) => {
  if (collectAgent) {
    let customReportObject = {
      eventtype: "E_CUSTOM",
      eventid: "",
      eventtime: "",
      actiontime: "",
      result: "0",
      reseverse1: "",
      reseverse10: window.location.origin
    };
    let now = moment().utc().format("yyyyMMDDHHmmss");
    customReportObject.eventid = eventId;
    customReportObject.eventtime = now;
    customReportObject.reseverse1 = reserve1;
    customReportObject.result = returncode ? returncode : "0";
    customReportObject.errmsg = errorMsg ? errorMsg : "no error";

    collectAgent.logCustom(customReportObject);
  }
};

export const sendCustomFavoriteReport = (
  favType,
  contentCode,
  columnCode,
  programCode,
  action,
  returncode,
  errorMsg
) => {
  if (collectAgent) {
    let customReportObject = {
      eventtype: "E_CUSTOM",
      eventid: "",
      eventtime: "",
      actiontime: "",
      result: "0",
      reseverse1: "",
      reseverse10: window.location.origin
    };
    let now = moment().utc().format("yyyyMMDDHHmmss");
    customReportObject.eventid = reportingCustomEvent.E_CUSTOM_FAVORITE;
    customReportObject.eventtime = now;
    customReportObject.reseverse1 = favType;
    customReportObject.reseverse2 = contentCode;
    customReportObject.reseverse3 = columnCode;
    customReportObject.reseverse4 = programCode;
    customReportObject.reseverse5 = action;
    customReportObject.result = returncode ? returncode : "0";
    customReportObject.errmsg = errorMsg ? errorMsg : "no error";
    collectAgent.logCustom(customReportObject);
  }
};

export const sendCustomBookmarkReport = (
  contentCode,
  programCode,
  breakpoint,
  columnCode,
  bookmarkType,
  action,
  returncode,
  errorMsg
) => {
  if (collectAgent) {
    let customReportObject = {
      eventtype: "E_CUSTOM",
      eventid: "",
      eventtime: "",
      actiontime: "",
      result: "0",
      reseverse1: "",
      reseverse10: window.location.origin
    };
    let now = moment().utc().format("yyyyMMDDHHmmss");
    customReportObject.eventid = reportingCustomEvent.E_CUSTOM_BOOKMARK;
    customReportObject.eventtime = now;
    customReportObject.reseverse1 = contentCode;
    customReportObject.reseverse2 = programCode;
    customReportObject.reseverse3 = breakpoint;
    customReportObject.reseverse4 = columnCode;
    customReportObject.reseverse5 = bookmarkType;
    customReportObject.reseverse6 = action;
    customReportObject.result = returncode ? returncode : "0";
    customReportObject.errmsg = errorMsg ? errorMsg : "no error";
    collectAgent.logCustom(customReportObject);
  }
};

export const sendCustomNpvrReport = (
  prevueCode,
  channelCode,
  seriesheadid,
  action,
  returncode,
  errorMsg
) => {
  if (collectAgent) {
    let customReportObject = {
      eventtype: "E_CUSTOM",
      eventid: "",
      eventtime: "",
      actiontime: "",
      result: "0",
      reseverse1: "",
      reseverse10: window.location.origin
    };
    let now = moment().utc().format("yyyyMMDDHHmmss");
    customReportObject.eventid = reportingCustomEvent.E_CUSTOM_NPVR;
    customReportObject.eventtime = now;
    customReportObject.reseverse1 = prevueCode;
    customReportObject.reseverse2 = channelCode;
    customReportObject.reseverse3 = prevueCode;
    customReportObject.reseverse4 = seriesheadid;
    customReportObject.reseverse5 = action;
    customReportObject.result = returncode ? returncode : "0";
    customReportObject.errmsg = errorMsg ? errorMsg : "no error";
    collectAgent.logCustom(customReportObject);
  }
};

export const sendCustomRatingReport = (
  value,
  contentCode,
  contentType,
  programCode,
  seriesProgramCode,
  returncode,
  errorMsg
) => {
  if (collectAgent) {
    let customReportObject = {
      eventtype: "E_CUSTOM",
      eventid: "",
      eventtime: "",
      actiontime: "",
      result: "0",
      reseverse1: "",
      reseverse10: window.location.origin
    };
    let now = moment().utc().format("yyyyMMDDHHmmss");
    customReportObject.eventid = reportingCustomEvent.E_CUSTOM_RATING;
    customReportObject.eventtime = now;
    customReportObject.reseverse1 = "";
    customReportObject.reseverse2 = value;
    customReportObject.reseverse3 = contentCode;
    customReportObject.reseverse4 = contentType;
    customReportObject.reseverse5 = programCode;
    customReportObject.reseverse6 = seriesProgramCode;
    customReportObject.result = returncode ? returncode : "0";
    customReportObject.errmsg = errorMsg ? errorMsg : "no error";
    collectAgent.logCustom(customReportObject);
  }
};

export const sendCustomProfileReport = (
  profileCode,
  action,
  returncode,
  errorMsg
) => {
  if (collectAgent) {
    let customReportObject = {
      eventtype: "E_CUSTOM",
      eventid: "",
      eventtime: "",
      actiontime: "",
      result: "0",
      reseverse1: "",
      reseverse10: window.location.origin
    };
    let now = moment().utc().format("yyyyMMDDHHmmss");
    customReportObject.eventid = reportingCustomEvent.E_CUSTOM_PROFILE;
    customReportObject.eventtime = now;
    customReportObject.reseverse1 = profileCode;
    customReportObject.reseverse2 = action;
    customReportObject.result = returncode ? returncode : "0";
    customReportObject.errmsg = errorMsg ? errorMsg : "no error";
    collectAgent.logCustom(customReportObject);
  }
};

export const sendAliveReport = () => {
  if (collectAgent) {
    let customReportObject = {
      eventtype: "E_CUSTOM",
      eventid: "",
      eventtime: "",
      actiontime: "",
      result: "0",
      reseverse1: "",
      reseverse10: window.location.origin
    };
    let now = moment().utc().format("yyyyMMDDHHmmss");
    customReportObject.eventid = reportingEvent.E_ALIVE;
    customReportObject.eventtime = now;
    customReportObject.status = 1;
    collectAgent.logCustom(customReportObject);
  }
};

export const sendCustomSubscribeReport = (
  productCode,
  price,
  contentid,
  returncode,
  errorMsg
) => {
  if (collectAgent) {
    let customReportObject = {
      eventtype: "E_CUSTOM",
      eventid: "",
      eventtime: "",
      actiontime: "",
      result: "0",
      reseverse1: "",
      reseverse10: window.location.origin
    };
    let now = moment().utc().format("yyyyMMDDHHmmss");
    customReportObject.eventid = reportingCustomEvent.E_CUSTOM_SUBSCRIBE;
    customReportObject.eventtime = now;
    customReportObject.reseverse1 = productCode;
    customReportObject.reseverse2 = price;
    customReportObject.reseverse3 = contentid;
    customReportObject.reseverse4 = "VOD";
    customReportObject.result = returncode ? returncode : "0";
    customReportObject.errmsg = errorMsg ? errorMsg : "no error";
    collectAgent.logCustom(customReportObject);
  }
};
