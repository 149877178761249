import React from "react";

const EyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.22"
      height="7.378"
      viewBox="0 0 9.22 7.378"
    >
      <path
        id="eye"
        d="M3,9.688a4.725,4.725,0,0,1,9.22,0,4.725,4.725,0,0,1-9.22,0Zm4.61,2.3a.953.953,0,1,0,0,.005Zm0-.922a1.38,1.38,0,1,0,0,.005Z"
        transform="translate(-3 -5.999)"
        fill="currentColor"
      />
    </svg>
  );
};

export default EyeIcon;
