import React, { useContext } from "react";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";

const ConfirmPayment = ({
  vod,
  product,
  confirm,
  notConfirm,
  ppvProduct,
  program
}) => {
  const { userFrameConfig } = useContext(SessionContext);

  return (
    <div className="payment-container-2">
      <div className="payment-confirm-text">
        {product?.type === "RENTAL"
          ? localizationStrings.formatString(
              localizationStrings.payment.confirmText,
              {
                action: <span>{localizationStrings.payment.rent}</span>,
                day: (
                  <span>
                    {` ${product?.duration} ${localizationStrings.days}`}
                  </span>
                ),
                content: <span> {vod?.title}</span>,
                price: <span>{`${product?.price} TL`}</span>
              }
            )
          : product?.type === "PURCHASE"
          ? localizationStrings.formatString(
              localizationStrings.payment.confirmTextBuy,
              {
                action: <span>{localizationStrings.payment.purchase}</span>,
                content: <span> {vod?.title}</span>,
                price: <span>{`${product?.price} TL`}</span>
              }
            )
          : product?.purchasetype == 2
          ? product?.bundle == 1
            ? localizationStrings.formatString(
                localizationStrings.payment.confirmTextBundle,
                {
                  action: <span>{localizationStrings.payment.rent}</span>,
                  day: (
                    <span>
                      {` ${product?.limittimes} ${localizationStrings.days}`}
                    </span>
                  ),
                  content: <span> {vod?.programname}</span>,
                  price: (
                    <span>{`${
                      product?.fee / parseInt(userFrameConfig?.Fee_Rate)
                    } TL`}</span>
                  )
                }
              )
            : product?.bundle == 2
            ? localizationStrings.formatString(
                localizationStrings.payment.confirmTextBuyBundle,
                {
                  action: <span>{localizationStrings.payment.purchase}</span>,
                  content: <span> {vod?.programname}</span>,
                  price: (
                    <span>{`${
                      product?.fee / parseInt(userFrameConfig?.Fee_Rate)
                    } TL`}</span>
                  )
                }
              )
            : null
          : null}
      </div>
      <div className="confirm-button-box">
        <button
          className="tr-button"
          style={{ marginRight: "30px" }}
          onClick={() => notConfirm()}
        >
          {localizationStrings.payment.cancel}
        </button>
        <button
          className="yellow-button"
          onClick={() => confirm()}
          style={{ marginRight: "15px" }}
        >
          {localizationStrings.payment.confirm}
        </button>
      </div>
    </div>
  );
};

export default ConfirmPayment;
