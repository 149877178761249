import React from "react";

const CancelIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_338"
            data-name="Path 338"
            d="M8.258,7.582h35v35h-35Zm0,0"
            transform="translate(-8.258 -7.582)"
          />
        </clipPath>
      </defs>
      <g id="Cancel" transform="translate(0 0.428)">
        <circle
          id="Oval"
          cx="17.5"
          cy="17.5"
          r="17.5"
          transform="translate(0 -0.428)"
          fill="rgba(27,22,41,0.64)"
        />
        <g
          id="Group_1067"
          data-name="Group 1067"
          transform="translate(0 -0.428)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Path_337"
            data-name="Path 337"
            d="M25.758,7.582a17.5,17.5,0,1,0,17.5,17.5A17.52,17.52,0,0,0,25.758,7.582Zm0,31.613A14.113,14.113,0,1,1,39.871,25.082,14.129,14.129,0,0,1,25.758,39.195Zm0,0"
            transform="translate(-8.258 -7.582)"
            fill="#fff"
          />
        </g>
        <path
          id="Path_339"
          data-name="Path 339"
          d="M121.066,110.387h-5.9v-5.9a2.051,2.051,0,0,0-4.1,0v5.9h-5.9a2.051,2.051,0,0,0,0,4.1h5.9v5.9a2.051,2.051,0,0,0,4.1,0v-5.9h5.9a2.051,2.051,0,0,0,0-4.1Zm0,0"
          transform="translate(17.52 -141.919) rotate(45)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default CancelIcon;
