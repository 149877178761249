import React from "react";

const DislikeIcon = ({ fill }) => {
  return (
    <svg
      id="Actions_Controls_pause--filled_24"
      data-name="Actions / Controls / pause--filled / 24"
      xmlns="http://www.w3.org/2000/svg"
      width="16.637"
      height="16.045"
      viewBox="0 0 16.637 16.045"
    >
      <path
        id="Fill"
        d="M8.538,16.045a1.916,1.916,0,0,1-.226-.013H7.38a.9.9,0,0,1-.849-.695v-.071L5.937,11.5l-2.1-3.188H0V0H11.281c4.417,0,5.344,2.583,5.344,4.75V8.313A2.161,2.161,0,0,1,14.7,10.688a1.91,1.91,0,0,1-.451,0H10.688v2.969a2.161,2.161,0,0,1-1.924,2.375A1.916,1.916,0,0,1,8.538,16.045ZM4.75,1.188V7.54l2.375,3.521.433,3.188.083.594h.671c.854,0,1.187-.333,1.187-1.187V9.5h4.75c.855,0,1.188-.333,1.188-1.187V4.75c0-2.43-1.321-3.563-4.157-3.563Zm-3.563,0V7.125H3.563V1.188Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DislikeIcon;
