import React from "react";

const CastNextIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="15"
      viewBox="0 0 8 15"
    >
      <g
        id="Actions_Navigation_arrow--left_24"
        data-name="Actions / Navigation / arrow--left / 24"
        transform="translate(0 -18)"
      >
        <path
          id="Fill"
          d="M8,7.5.982,15,0,13.95,6.035,7.5,0,1.05.982,0Z"
          transform="translate(0 18)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default CastNextIcon;
