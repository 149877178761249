import React, { useEffect } from "react";
import { useRef } from "react";

const useOutsideEnter = (ref, outsideEntered) => {
  const lastEventRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleMouseOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        outsideEntered();
      }
    }

    // Bind the event listener
    document.addEventListener("mousemove", handleMouseOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousemove", handleMouseOutside);
    };
  }, [ref]);

  useEffect(() => {
    return () => {};
  }, []);
};

export default useOutsideEnter;
