import React, { useContext, useEffect, useRef, useState } from "react";
import localizationStrings from "../../statics/localization/Localization";
import VerticalProgramList from "../verticalProgramList";
import RtukGeneralIcon from "../../assets/icons/svg/RtukGeneralIcon";
import DisplayDate from "../displayDate";
import {
  checkNpvrLimit,
  checkNpvrStatus,
  deleteNpvrRecord,
  deleteSerieNpvrRecord,
  startNpvr,
  startSerieNpvr
} from "../../api/npvr/npvr";
import { checkPpvAuth, getCurrentPrevue } from "../../api/ltv/ltv";
import Modal from "../modal/Modal";
import { SessionContext } from "../../context/SessionContext";
import RtukSevenAgeIcon from "../../assets/icons/svg/RtukSevenAgeIcon";
import RtukThirteenAgeIcon from "../../assets/icons/svg/RtukThirteenAgeIcon";
import RtukAgeIcon from "../../assets/icons/svg/RtukAgeIcon";
import ReactDOM from "react-dom";
import Notification from "../notification";
import PpvPayment from "../ppvPayment/PpvPayment";
import moment from "moment";
import { sendCustomNpvrReport } from "../../utils/reporting/customReporting";
import PopupCloseIcon from "../../assets/icons/svg/PopupCloseIcon";
import ShareAction from "../ShareAction";

const ProgramInfo = ({
  currentChannel,
  scrollToTop,
  setCanPlayPpv,
  isPltv,
  setPrevueCode
}) => {
  // const [recordStatus, setRecordStatus] = useState(0);
  const [currentProgram, setCurrentProgram] = useState(null);
  const [product, setProduct] = useState(null);
  const [showLimitWarning, setShowLimitWarning] = useState(false);
  const [limitMessage, setLimitMessage] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [ppvProduct, setPpvProduct] = useState(null);
  const {
    setMyProgram,
    selectedChannel,
    setPpvProgram,
    recordStatus,
    setRecordStatus,
    isClickedRecord,
    setIsClickedRecord,
    selectedCutvProgram,
    currentProgramTimes,
    userInfo,
    programInfo,
    setProgramInfo
  } = useContext(SessionContext);
  const elRef = useRef(null);
  const isSerieProgram = useRef(false);
  const ppvInterval = useRef(null);
  const canPlayPpv = useRef(null);
  const recordOptionEl = useRef(null);
  const currentChannelCodeRef = useRef(null);

  const renderRatingIcon = () => {
    return programInfo?.ratingid === "1" ? (
      <RtukGeneralIcon />
    ) : programInfo?.ratingid === "2" ? (
      <RtukSevenAgeIcon />
    ) : programInfo?.ratingid === "4" ? (
      <RtukThirteenAgeIcon />
    ) : programInfo?.ratingid === "8" ? (
      <RtukAgeIcon />
    ) : null;
  };

  const getCurrentProgram = async (channel) => {
    // setLoading(true);
    if (channel?.begintime && channel?.endtime) {
      const data = await getCurrentPrevue(channel?.channelcode);
      if (data.data.returncode === "0") {
        let program = data.data.data[0][0];
        setPrevueCode(program.prevuecode);
        setMyProgram(program);
        setCurrentProgram(program);
        setProgramInfo(program);
        if (
          channel.auth_returncode === "0" &&
          program?.systemrecordenable === "1" &&
          // program?.privaterecordenable === "1" &&
          userInfo.usertype === "1"
        ) {
          getNpvrStatus(program);
        } else {
          setRecordStatus(null);
        }
      } else {
        setCurrentProgram({ prevuecode: 0 });
        setProgramInfo({ prevuecode: 0 });
      }
    } else {
      setCurrentProgram({ prevuecode: 0 });
      setProgramInfo({ prevuecode: 0 });
      setMyProgram({});
    }
    // setLoading(false);
  };

  const getNpvrStatus = async (program, isSerie = false) => {
    const { data } = await checkNpvrStatus(
      program?.prevuecode,
      program?.begintime,
      program?.endtime,
      program?.seriesheadid
    );
    if (data.returncode === "0") {
      setRecordStatus(data.status);
    } else if (data.returncode === "10402503") {
      //have no data
      setRecordStatus(null);
    }
    currentChannelCodeRef.current = currentChannel?.channelcode;
  };

  const getPpvAuth = async (prevueCode) => {
    const { data } = await checkPpvAuth(prevueCode);
    if (data.returncode === "5") {
      let prod = data.productlist.find((item) => item.purchasetype == 3);
      setProduct(prod);
      setPpvProduct(prod);
      if (
        data.otherprevuesauthresult === "0" ||
        data.postbuffer != "0" ||
        data.prebuffer != "0"
      ) {
        if (ppvInterval.current) {
          clearInterval(ppvInterval.current);
        }
        ppvInterval.current = setInterval(() => {
          checkPrebufferAndPostbuffer(data.prebuffer, data.postbuffer);
        }, 60000);
      } else {
        setCanPlayPpv(false);
      }
    } else {
      if (data.returncode === "0") {
        if (!canPlayPpv.current) {
          canPlayPpv.current = true;

          setCanPlayPpv(true, data.productcode);
          setProduct(null);
          setPpvProduct(null);
        }
      } else {
        setProduct(null);
        setPpvProduct(null);
        setCanPlayPpv(false);
      }
    }
  };

  const checkPrebufferAndPostbuffer = (prebuffer, postbuffer) => {
    let now = moment() - parseInt(localStorage.getItem("timeDiff"));

    if (prebuffer != "0") {
      if (now > moment(currentProgram.endtime)) {
        if (!canPlayPpv.current) {
          canPlayPpv.current = true;
          setCanPlayPpv(true, ppvProduct.productcode);
        }
      } else {
        if (canPlayPpv.current) {
          canPlayPpv.current = false;
          setCanPlayPpv(false);
        }
      }
    }

    if (postbuffer != "0") {
      if (now > moment(currentProgram.begintime)) {
        if (!canPlayPpv.current) {
          canPlayPpv.current = true;

          setCanPlayPpv(true, ppvProduct.productcode);
        }
      } else {
        if (canPlayPpv.current) {
          canPlayPpv.current = false;
          setCanPlayPpv(false);
        }
      }
    }
  };

  useEffect(() => {
    if (currentProgramTimes && currentChannel && !isPltv) {
      getCurrentProgram(currentChannel);
    }
  }, [currentChannel]); //currentProgramTimes

  useEffect(() => {
    if (
      currentProgramTimes &&
      selectedChannel?.channelcode === currentChannelCodeRef.current
    ) {
      getCurrentProgram(currentChannel);
    }
  }, [currentProgramTimes]);

  useEffect(() => {
    if (!!selectedCutvProgram?.prevuecode) {
      getNpvrStatus(selectedCutvProgram);
      setProgramInfo(selectedCutvProgram);
      scrollToTop();
    }
    return () => {
      // setSelectedCutvProgram(null);
    };
  }, [selectedCutvProgram]);

  useEffect(() => {
    if (currentProgram?.begintime && currentProgram?.endtime) {
      if (selectedChannel?.isppv === "1") {
        // getPpvAuth(currentProgram?.prevuecode);
        // if (programInfo?.isppv === "1") setPpvProgram(programInfo);
      } else {
        setPpvProgram(null);
        setProduct(null);
      }
    }
  }, [currentProgram]);

  const renderProgramInfo = () => {
    if (!!programInfo && programInfo?.prevuecode) {
      return (
        <div>
          <div className="program-title">{programInfo?.prevuename}</div>
          <div className="program-data-row">
            <div className="program-rating">
              <div style={{ color: "white" }}>
                {moment(programInfo?.begintime, "YYYY.MM.DD HH:mm").calendar(
                  null,
                  {
                    sameDay: `[${localizationStrings.today}]`,
                    nextDay: `[${localizationStrings.tomorrow}]`,
                    lastDay: `[${localizationStrings.yesterday}]`,
                    nextWeek: "DD MMMM yyyy",
                    lastWeek: "DD MMMM yyyy",
                    sameElse: "DD MMMM yyyy"
                  }
                )}
              </div>
              <span style={{ margin: "0 5px " }}>-</span>
              <div className="date">
                <DisplayDate onlyHour={true} value={programInfo?.begintime} />
              </div>
              <span style={{ margin: "0 5px" }}>-</span>
              <div className="date">
                <DisplayDate
                  onlyHour={true}
                  value={programInfo?.endtime}
                  style={{ color: "#587086" }}
                />
              </div>
              <div className="program-rating icon">{renderRatingIcon()}</div>
              <div className="program-rating genre" style={{ color: "white" }}>
                {programInfo?.genre}
              </div>
              <div className="share-channel">
                <ShareAction programInfo={programInfo} />
              </div>
            </div>
          </div>
          <div className="program-description">{programInfo?.description}</div>
        </div>
      );
    } else if (programInfo?.prevuecode == 0) {
      return (
        <div className="no-program-info">{localizationStrings.noProgram}</div>
      );
    }
  };

  return (
    <div id="vert-program-list" ref={elRef}>
      <div className="channel-info-container">{renderProgramInfo()}</div>
      <div>
        <VerticalProgramList currentProgram={currentProgram} />
      </div>
    </div>
  );
};

export default ProgramInfo;
