import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import localizationStrings from "../../statics/localization/Localization";
import Icon from "../../assets/icons/svg/Icon";
import moment from "moment";
import VerticalProgramListWidget from "../verticalProgramListWidget";
import { SessionContext } from "../../context/SessionContext";
import Notification from "../notification";
import { checkTvodSavetime } from "../../utils/checkTvodSaveTime";
import { useLocation } from "react-router-dom";
import { getProgramsById } from "../../api/ltv/ltv";

const VerticalProgramList = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [dates, setDates] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [allPrograms, setAllPrograms] = useState([]);
  const {
    selectedChannel,
    selectedCutvProgram,
    setSelectedCutvProgram,
    setIsLiveSelected,
    userInfo,
    userFrameConfig
  } = useContext(SessionContext);
  const location = useLocation();

  const getAllPrograms = async () => {
    try {
      const data = await getProgramsById(
        selectedChannel?.id,
        selectedDate?.name
      );
      setAllPrograms(data.data);
    } catch (err) {
      setAllPrograms([]);
    }
  };

  useLayoutEffect(() => {
    calcDatesForOneWeek();
  }, [selectedChannel]);

  useEffect(() => {
    selectedChannel && getAllPrograms();
  }, [selectedChannel, selectedDate]);

  const calcDatesForOneWeek = () => {
    let dates = [];

    for (let i = -7; i < 7; ++i) {
      dates.push({
        id: i,
        name: moment().add(i, "days").calendar(null, {
          /* sameDay: `[${localizationStrings.today}]`,
            nextDay: `[${localizationStrings.tomorrow}]`,
            lastDay: `[${localizationStrings.yesterday}]`, */
          sameDay: "DD.MM.yyyy",
          nextDay: "DD.MM.yyyy",
          lastDay: "DD.MM.yyyy",
          nextWeek: "DD.MM.yyyy",
          lastWeek: "DD.MM.yyyy",
          sameElse: "DD.MM.yyyy"
        })
      });
    }
    setSelectedDate(dates[7]);
    setDates(dates);
  };

  const renderedDateItems = dates.map((item, index) => {
    let dateName = item?.name;
    if (index === 6) {
      dateName = localizationStrings.yesterday;
    } else if (index === 7) {
      dateName = localizationStrings.today;
    } else if (index === 8) {
      dateName = localizationStrings.tomorrow;
    }
    return (
      <div
        key={index}
        className="option"
        onClick={() => {
          setSelectedDate(item);
          setShowDateFilter(!showDateFilter);
        }}
      >
        <div
          className={
            selectedDate?.id === item?.id
              ? "option-text selected"
              : "option-text"
          }
          style={{ fontFamily: item?.id === 0 ? "'Open Sans', sans-serif" : "" }}
        >
          {dateName}
        </div>
      </div>
    );
  });

  let dateName;

  if (dates[6]?.name === selectedDate?.name) {
    dateName = localizationStrings.yesterday;
  } else if (dates[7]?.name === selectedDate?.name) {
    dateName = localizationStrings.today;
  } else if (dates[8]?.name === selectedDate?.name) {
    dateName = localizationStrings.tomorrow;
  } else {
    dateName = selectedDate?.name;
  }

  return (
    <div className="channelguide-container">
      <div className="filters-row">
        <div className="next-program-title">
          {`${selectedChannel?.name ?? ""} ${localizationStrings.programFlow}`}
        </div>
        <div
          className={`filter ${showDateFilter ? "selected" : ""}`}
          onClick={() => {
            setShowDateFilter(!showDateFilter);
          }}
        >
          <div className="text">{dateName}</div>
          <div className="icon">
            <Icon />
          </div>
          <div
            className={`primary-select-box-options ${
              showDateFilter ? "show" : ""
            }`}
          >
            {renderedDateItems}
          </div>
        </div>
      </div>

      <div id="program-list-container" className="program-container">
        <VerticalProgramListWidget
          programs={allPrograms}
          onSelectCutvProgram={(val) => {
            if (
              checkTvodSavetime(val.begintime, selectedChannel?.tvodsavetime)
            ) {
              /* setSelectedCutvProgram(val); */
            } else {
              setShowNotification(true);
            }
          }}
          onSelectLive={() => setIsLiveSelected(true)}
          isLocked={selectedChannel?.islocked === "1" ? true : false}
          userRating={userInfo?.userlevel}
          tvodSaveTime={selectedChannel?.tvodsavetime}
          setSelectedCutvProgram={setSelectedCutvProgram}
          selectedChannel={selectedChannel}
          userFrameConfig={userFrameConfig}
          userInfo={userInfo}
          location={location}
        />
      </div>
      {showNotification ? (
        <Notification
          text={localizationStrings.tvodSaveTime}
          confirmText={localizationStrings.login.confirm}
          onClickConfirm={() => {
            setShowNotification(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default VerticalProgramList;
