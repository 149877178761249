import React from "react";

import ProfileList from "../../components/profileList/ProfileList";

const ProfileSelection = () => {
  let logControl = JSON.parse(localStorage.getItem("firstLogin"));
  return <ProfileList isFromProfileLogin={logControl} />;
};

export default ProfileSelection;
