import React from "react";
import { useHistory } from "react-router-dom";
import errorLogo from "../../assets/images/error.png";

const ErrorPage = () => {
  const history = useHistory();

  return (
    <div className="account-wrapper">
      <div className="account-success-icon">
        <img
          src={errorLogo}
          alt="success logo"
          style={{ maxWidth: "200px", height: "auto" }}
        />
      </div>
      <div className="account-text">
        Bir hata oluştu. Tekrar giriş yapmayı deneyebilirsiniz.
      </div>
      <button
        onClick={() => {
          history.push("/login");
        }}
        className="continue-button"
      >
        Giriş Yap
      </button>
    </div>
  );
};

export default ErrorPage;
