import React from "react";

const RtukIcon = () => {
  return (
    <div>
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.832vw"
        height="2.808vw"
        viewBox="0 0 266.689 54.795"
      >
        <g
          id="Group_619"
          data-name="Group 619"
          transform="translate(-826.655 -450)"
        >
          <path
            id="Fill_1"
            data-name="Fill 1"
            d="M27,54A27,27,0,0,1,7.908,7.908,27,27,0,1,1,46.092,46.092,26.823,26.823,0,0,1,27,54ZM27,4.81A22.192,22.192,0,0,0,9.1,40.123V37.9A4.758,4.758,0,0,1,12,33.3a3.9,3.9,0,1,1,2.485.026A4.622,4.622,0,0,1,17.2,36.781a7.593,7.593,0,0,1,4.576-6.821,4.867,4.867,0,1,1,3.716-.021,6.9,6.9,0,0,1,3.859,3.7,8.349,8.349,0,0,1,6.292-7.062,6.027,6.027,0,1,1,2.9-.014A8.47,8.47,0,0,1,45.02,35.08v4.872A22.191,22.191,0,0,0,27,4.81Z"
            transform="translate(826.655 450)"
            fill="#fff"
          />
          <path
            id="Fill_1-2"
            data-name="Fill 1"
            d="M27.114,54.228A27.115,27.115,0,0,1,7.941,7.941,27.115,27.115,0,0,1,46.287,46.287,26.937,26.937,0,0,1,27.114,54.228Zm0-49.4A22.284,22.284,0,0,0,11.357,42.871,22.284,22.284,0,1,0,42.871,11.357,22.138,22.138,0,0,0,27.114,4.83Zm1.453,33.8a6.438,6.438,0,0,1-6.605-6.241,6.141,6.141,0,0,1,3.059-5.265,5.754,5.754,0,0,1-2.39-4.678,5.937,5.937,0,0,1,11.872,0,5.753,5.753,0,0,1-2.39,4.678,6.142,6.142,0,0,1,3.058,5.265A6.437,6.437,0,0,1,28.568,38.635Zm0-9.883a3.3,3.3,0,0,0-3.032,3.512,3.3,3.3,0,0,0,3.032,3.514A3.3,3.3,0,0,0,31.6,32.265,3.3,3.3,0,0,0,28.568,28.752Zm0-8.78a2.683,2.683,0,0,0-2.378,2.9,2.683,2.683,0,0,0,2.378,2.9,2.683,2.683,0,0,0,2.378-2.9A2.683,2.683,0,0,0,28.568,19.972ZM20.728,38.265H8.75V34.651H13.4V20.739H8.509V17.126h8.5V34.651h3.718v3.613Zm23.266-5.091H40.381V28.992H36.2V25.377h4.182V21.2h3.613v4.182h4.183v3.615H43.994v4.182Z"
            transform="translate(1039.116 450.367)"
            fill="#fff"
          />
          <path
            id="Fill_1-3"
            data-name="Fill 1"
            d="M27.114,54.228A27.115,27.115,0,0,1,7.941,7.941,27.114,27.114,0,1,1,46.286,46.287,26.937,26.937,0,0,1,27.114,54.228Zm0-49.4A22.284,22.284,0,0,0,11.357,42.871,22.284,22.284,0,1,0,42.871,11.357,22.138,22.138,0,0,0,27.114,4.83ZM25.485,41.516H19.348a55.659,55.659,0,0,1,3.969-12.51c1.811-4.324,3.771-8.287,4.778-10.265H16.674v-4.85H33.941v2.578a66.177,66.177,0,0,0-7.023,17.263,52.809,52.809,0,0,0-1.432,7.783ZM42.16,33H38.545V28.822H34.363V25.208h4.182V21.026H42.16v4.182h4.182v3.615H42.16V33Z"
            transform="translate(898.689 450.369)"
            fill="#fff"
          />
          <g
            id="Group_618"
            data-name="Group 618"
            transform="translate(968.769 450.371)"
          >
            <path
              id="Path_211"
              data-name="Path 211"
              d="M-516.391-226.56h3.951c.869.133,1.739.259,2.606.4a27.268,27.268,0,0,1,22.117,31.9,27.183,27.183,0,0,1-30.107,21.942,27.226,27.226,0,0,1-23.359-21.8c-.232-1.267-.366-2.552-.546-3.829v-2.724a6.354,6.354,0,0,0,.115-.663,27.181,27.181,0,0,1,20.479-24.381C-519.579-226.1-517.974-226.285-516.391-226.56Zm24.276,27.311a22.357,22.357,0,0,0-22.062-22.435,22.358,22.358,0,0,0-22.647,22.057,22.375,22.375,0,0,0,22.27,22.652A22.373,22.373,0,0,0-492.116-199.249Z"
              transform="translate(541.729 226.561)"
              fill="#fff"
            />
            <path
              id="Path_212"
              data-name="Path 212"
              d="M-379.29-84.053V-87.93c1.041,0,2.056.035,3.068-.009a16.4,16.4,0,0,0,3.36-.307,5.919,5.919,0,0,0,2.257-1.307,2.852,2.852,0,0,0,.643-3.258A2.6,2.6,0,0,0-372.8-94.2c-.648.068-1.288.216-2.042.346l-1.137-2.229c1.175-.686,2.331-1.309,3.429-2.023a4.982,4.982,0,0,0,1.361-1.29,2.394,2.394,0,0,0-.331-3.01,1.9,1.9,0,0,0-2.552-.255,15.11,15.11,0,0,0-2.115,1.571,9.826,9.826,0,0,0-.936,1.024l-2.085-2.72a16.288,16.288,0,0,1,5.017-2.964,8.56,8.56,0,0,1,1.781-.427,4.722,4.722,0,0,1,5.241,3.3,3.39,3.39,0,0,1-.291,3.015,12.9,12.9,0,0,1-1.483,1.754c-.379.41-.809.773-1.247,1.187.68.34,1.3.605,1.877.946,2.683,1.589,3.527,4.15,2,6.859-1.745,3.1-4.342,5.07-8.1,5.065C-376.018-84.055-377.624-84.053-379.29-84.053Z"
              transform="translate(401.474 122.657)"
              fill="#fff"
            />
            <path
              id="Path_213"
              data-name="Path 213"
              d="M-470.017-100.284v17.519h3.673v3.611h-11.933v-3.569h4.56V-96.67h-4.8v-3.614Z"
              transform="translate(487.148 117.53)"
              fill="#fff"
            />
            <path
              id="Path_214"
              data-name="Path 214"
              d="M-270.985-58.585v-4.128h-4.142v-3.649h4.144v-4.169h3.634v4.141h4.166v3.637h-4.12v4.167Z"
              transform="translate(311.536 91.84)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default RtukIcon;
