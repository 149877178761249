import localizationStrings from "../statics/localization";

export const fancyTimeFormat = (duration) => {
  // Hours, minutes and seconds
  let hrs = ~~(duration / 3600);
  let mins = ~~((duration % 3600) / 60);

  let ret = "";

  if (hrs > 0) {
    ret +=
      "" + hrs + ` ${localizationStrings.shortHour} ` + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ` ${localizationStrings.shortMinutes} `;
  return ret;
};
