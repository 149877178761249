import React, { useEffect } from "react";
import localizationStrings from "../../../statics/localization";

const NextEpisode = ({
  playNextEpisode,
  nextEpisode,
  selectedSeason,
  vod,
  hideNextEpisode,
  player,
  isCutvVod
}) => {
  // useEffect(() => {
  //   if (!!player) {
  //     player.off("ended");
  //     player.on("ended", () => {
  //       console.log("VIDEO ENDED NEXT EPISDOE:");
  //       playNextEpisode();
  //     });
  //   }
  // }, [player]);

  return (
    <div className="next-episode-wrapper">
      <div className="next-episode-box">
        <div
          className="yellow-button"
          style={{ width: "200px",marginBottom: "0" }}
          onClick={() => {
            hideNextEpisode();
          }}
        >
          {localizationStrings.continue}
        </div>
        <div
          className="continue-button"
          onClick={() => {
            player.off("ended");
            playNextEpisode();
          }}
        >
          {localizationStrings.next}
        </div>
        <div className="episode-info-box">
          <label className="episode-title">{vod?.programname}</label>
          {isCutvVod ? 
          <span className="episode-info">
            "{nextEpisode?.programname}"
        </span> :
          <span className="episode-info">
            {selectedSeason?.seriesseason}. S - {nextEpisode?.seriesnum}.{" "}
            {localizationStrings.getLanguage() === "eng" ? "E" : "B"} "
            {nextEpisode?.programname}"
          </span>
          }
        </div>
      </div>
    </div>
  );
};

export default NextEpisode;
