import React from "react";

const InvoiceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1.3em"
      viewBox="0 0 41.267 54.197"
    >
      <g id="icon" transform="translate(0 0)">
        <path
          id="path"
          d="M33.451,54.2a1.4,1.4,0,0,1-1-.412l-2.209-2.209-2.209,2.209a1.406,1.406,0,0,1-1.99,0l-2.209-2.209-2.21,2.209a1.407,1.407,0,0,1-1.99,0l-2.21-2.209L15.22,53.785a1.406,1.406,0,0,1-1.99,0l-2.209-2.209-2.21,2.209a1.407,1.407,0,0,1-1.99,0L4.612,51.575,2.4,53.785a1.407,1.407,0,0,1-2.4-1V1.407A1.408,1.408,0,0,1,1.407,0H39.86a1.408,1.408,0,0,1,1.407,1.407V52.79a1.407,1.407,0,0,1-2.4,1l-2.209-2.209-2.209,2.209A1.4,1.4,0,0,1,33.451,54.2ZM11.021,48.179a1.4,1.4,0,0,1,.995.411l2.209,2.21,2.21-2.21a1.409,1.409,0,0,1,1.991,0l2.209,2.21,2.209-2.21a1.408,1.408,0,0,1,1.99,0l2.209,2.21,2.21-2.21a1.408,1.408,0,0,1,1.99,0l2.209,2.21,2.21-2.21a1.416,1.416,0,0,1,.994-.411,1.4,1.4,0,0,1,1,.411l.8.8V2.814H2.814V49.393l.8-.8a1.409,1.409,0,0,1,1.991,0L7.816,50.8l2.209-2.21A1.4,1.4,0,0,1,11.021,48.179Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
        <path
          id="path-2"
          data-name="path"
          d="M25.531,2.814H1.407A1.407,1.407,0,1,1,1.407,0H25.531a1.407,1.407,0,1,1,0,2.814"
          transform="translate(7.117 7.527)"
          fill="currentColor"
        />
        <path
          id="path-3"
          data-name="path"
          d="M13.517,2.814H1.407A1.407,1.407,0,1,1,1.407,0h12.11a1.407,1.407,0,0,1,0,2.814"
          transform="translate(7.117 13.842)"
          fill="currentColor"
        />
        <path
          id="path-4"
          data-name="path"
          d="M25.531,2.814H1.407A1.407,1.407,0,1,1,1.407,0H25.531a1.407,1.407,0,1,1,0,2.814"
          transform="translate(7.117 19.702)"
          fill="currentColor"
        />
        <path
          id="path-5"
          data-name="path"
          d="M13.517,2.814H1.407A1.407,1.407,0,1,1,1.407,0h12.11a1.407,1.407,0,0,1,0,2.814"
          transform="translate(7.117 26.018)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default InvoiceIcon;
