import React, { useState } from "react";
import { createPortal } from "react-dom";

const ModalPortal = ({ children, viewClass }) => {
  const [open, setOpen] = useState(false);

  setTimeout(() => {
    setOpen(true);
  }, 10);

  return createPortal(
    <div className={`modal-root ${open ? "modal-open" : null}`}>
      <div className={`tt-modal ${viewClass}`}>{children}</div>
    </div>,
    document.getElementById("modal_root")
  );
};

export default ModalPortal;
