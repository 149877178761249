import React, { useEffect, useRef, useState } from "react";
import AudioOnIcon from "../../../assets/icons/svg/AudioOnlIcon";
import AudioOffIcon from "../../../assets/icons/svg/AudioOffIcon";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { setCookieApiCall } from "../../../api/config/configApi";
import { setHandleKeyRef } from "../../../statics/config";
import localizationStrings from "../../../statics/localization";

const AudioControl = ({ player, isFullscreen }) => {
  const [volume, setVolume] = useState(null);
  const listenerRef = useRef(null);
  let timeout1;
  let timeout2;

  function handleKeyPress(e) {
    let vol = player.volume();
    let newVol;
    switch (e.key) {
      case "ArrowUp":
        player.volume(vol + 0.1);
        if (vol + 0.1 < 1) {
          newVol = vol + 0.1;
        } else {
          newVol = 1;
        }
        setVolume(newVol * 100);
        localStorage.setItem("volume", newVol * 100);
        !!timeout1 && clearTimeout(timeout1);
        timeout1 = setTimeout(() => {
          setCookieApiCall("volume", newVol * 100);
        }, 500);

        break;
      case "ArrowDown":
        player.volume(vol - 0.1);
        if (vol - 0.1 > 0) {
          newVol = vol - 0.1;
        } else {
          newVol = 0;
        }
        setVolume(newVol * 100);
        localStorage.setItem("volume", newVol * 100);
        !!timeout2 && clearTimeout(timeout2);
        timeout2 = setTimeout(() => {
          setCookieApiCall("volume", newVol * 100);
        }, 500);

        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (player) player.volume(volume / 100);
  }, [volume]);

  useEffect(() => {
    let vol = +localStorage.getItem("volume");
    if (player) {
      if (vol || vol == 0) {
        setVolume(parseInt(vol));
        player.volume(parseInt(vol) / 100);
      } else {
        setVolume(player?.volume() * 100);
      }
      if (listenerRef.current) {
        document.removeEventListener("keydown", handleKeyPress);
      }

      listenerRef.current = document.addEventListener(
        "keydown",
        handleKeyPress
      );
      setHandleKeyRef(handleKeyPress);
    }
  }, [player]);

  return (
    <div className="rc-slider-wrapper audio-slider">
      <button
        className="control-button"
        style={{
          marginRight: isFullscreen ? "1.2vw" : "0.8vw",
          marginLeft: isFullscreen ? "1.2vw" : null
        }}
        title={
          volume
            ? localizationStrings.playerOptions.audioOff
            : localizationStrings.playerOptions.audioOn
        }
        onClick={() => {
          if (volume) {
            setVolume(0);
            localStorage.setItem("volume", 0);
          } else {
            setVolume(100);
            localStorage.setItem("volume", 100);
          }
        }}
      >
        {/* {volume ? <AudioOnIcon /> : <AudioOffIcon />} */}
        <div style={{ display: volume ? "flex" : "none" }}>
          <AudioOnIcon />
        </div>
        <div style={{ display: volume ? "none" : "flex" }}>
          <AudioOffIcon />
        </div>
      </button>
      <Slider
        min={0}
        max={100}
        value={volume}
        onChange={(value) => {
          setVolume(value);
          localStorage.setItem("volume", value);
        }}
        onAfterChange={(val) => {
          setCookieApiCall("volume", val);
        }}
      />
    </div>
  );
};

export default AudioControl;
