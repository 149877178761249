import { useEffect, useRef } from "react";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      /*
      React performs the cleanup when the component unmounts. However, as we learned earlier, effects run for every render and not just once. 
      This is why React also cleans up effects from the previous render before running the effects next time. 
      */
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;
