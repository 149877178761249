import React from "react";

const LockOpenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.091"
      height="24.508"
      viewBox="0 0 15.091 24.508"
    >
      <path
        id="Path_66"
        data-name="Path 66"
        d="M775.508,713.293a.817.817,0,0,1-.816.816H774.4a.817.817,0,0,1-.816-.816v-2.137a.817.817,0,0,1,.816-.816h.293a.817.817,0,0,1,.816.816Zm6.076-7.081a2.538,2.538,0,0,0-.569-.545,2.509,2.509,0,0,0-1.091-.424,1.764,1.764,0,0,0-.179-.015c-.118-.006-.235,0-.353,0l-.384,0-.734,0c-.057,0-.114,0-.171,0h-7.115V699.3a3.627,3.627,0,0,1,3.215-3.645,3.561,3.561,0,0,1,3.9,3.541v.767a.821.821,0,0,0,1.642,0v-.629A5.314,5.314,0,0,0,774.458,694a5.2,5.2,0,0,0-5.111,5.2v6.027A2.5,2.5,0,0,0,767,707.72v8.286a2.5,2.5,0,0,0,2.5,2.5h10.087a2.5,2.5,0,0,0,2.5-2.5V707.72a2.478,2.478,0,0,0-.507-1.508"
        transform="translate(-767 -694)"
        fill="#fff"
      />
    </svg>
  );
};

export default LockOpenIcon;
