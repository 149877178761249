import React from "react";

const CheckedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.074"
      height="10.495"
      viewBox="0 0 14.074 10.495"
    >
      <path
        id="Icon_awesome-check"
        data-name="Icon awesome-check"
        d="M4.78,14.866.206,10.292a.7.7,0,0,1,0-1l1-1a.7.7,0,0,1,1,0l3.081,3.081,6.6-6.6a.7.7,0,0,1,1,0l1,1a.7.7,0,0,1,0,1L5.775,14.866A.7.7,0,0,1,4.78,14.866Z"
        transform="translate(0 -4.577)"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckedIcon;
