import React from "react";

const ReturnIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.5em"
      height="1em"
      viewBox="0 0 9 18"
    >
      <path
        id="Fill"
        d="M-9,9l7.895,9L0,16.74-6.789,9,0,1.26-1.105,0Z"
        transform="translate(9)"
        fill="#fff"
      />
    </svg>
  );
};

export default ReturnIcon;
