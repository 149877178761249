import React from "react";

const PromoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1.1em"
      viewBox="0 0 21.397 23.023"
    >
      <g id="gift" transform="translate(-3.375 -2.277)">
        <path
          id="Path_97"
          data-name="Path 97"
          d="M13.308,8.614h2.058V6.557a2.058,2.058,0,1,0-2.058,2.058Z"
          transform="translate(-2.114 -0.597)"
          fill="none"
        />
        <path
          id="Path_98"
          data-name="Path 98"
          d="M23.24,6.558a2.058,2.058,0,1,0-4.115,0V8.615h2.058A2.058,2.058,0,0,0,23.24,6.558Z"
          transform="translate(-4.229 -0.597)"
          fill="none"
        />
        <path
          id="Path_99"
          data-name="Path 99"
          d="M5.625,26.834a3.292,3.292,0,0,0,3.292,3.292h4.732a.206.206,0,0,0,.206-.206V20.456a.206.206,0,0,0-.206-.206H6.037a.412.412,0,0,0-.412.412Z"
          transform="translate(-0.604 -4.826)"
          fill="currentColor"
        />
        <path
          id="Path_100"
          data-name="Path 100"
          d="M13.25,13.573V8.018H14.9v5.555a.206.206,0,0,0,.206.206h7.2a2.469,2.469,0,0,0,2.469-2.469v-.823A2.469,2.469,0,0,0,22.3,8.018H20.218a.1.1,0,0,1-.088-.154,3.7,3.7,0,0,0-6.057-4.227A3.7,3.7,0,0,0,8.016,7.864a.1.1,0,0,1-.088.154H5.844a2.469,2.469,0,0,0-2.469,2.469v.823a2.469,2.469,0,0,0,2.469,2.469h7.2A.206.206,0,0,0,13.25,13.573ZM14.9,5.961a2.058,2.058,0,1,1,2.058,2.058H14.9ZM11.046,3.909a2.058,2.058,0,0,1,2.2,2.052V8.019H11.193a2.058,2.058,0,0,1-.147-4.109Z"
          fill="currentColor"
        />
        <path
          id="Path_101"
          data-name="Path 101"
          d="M19.331,30.125h4.732a3.292,3.292,0,0,0,3.292-3.292V20.662a.412.412,0,0,0-.412-.412H19.331a.206.206,0,0,0-.206.206V29.92a.206.206,0,0,0,.206.206Z"
          transform="translate(-4.229 -4.826)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default PromoIcon;
