import React from "react";

const LinkedinIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.607"
      height="9.607"
      viewBox="0 0 9.607 9.607"
    >
      <path
        id="Shape"
        d="M5.361,9.607h-2.1c0-.052.027-5.989,0-6.526h2.1V4.1a1.4,1.4,0,0,1,.579-.711,2.267,2.267,0,0,1,1.29-.376,2.17,2.17,0,0,1,1.687.722,3.347,3.347,0,0,1,.689,2.238V9.606H7.484V6.229c0-.959-.377-1.486-1.063-1.486a1.094,1.094,0,0,0-1.014.8A1.351,1.351,0,0,0,5.361,6c0,.025,0,.048,0,.071V9.607Zm-3.247,0H.242V3.08H2.115V9.606ZM1.131,2.264H1.117A1.087,1.087,0,0,1,0,1.133,1.09,1.09,0,0,1,1.144,0,1.085,1.085,0,0,1,2.273,1.132,1.088,1.088,0,0,1,1.131,2.264Z"
        fill="#0c1927"
      />
    </svg>
  );
};

export default LinkedinIcon;
