import React, { useState } from "react";
import FacebookFooterIcon from "../../assets/icons/svg/FacebookFooterIcon";
import TwitterFooterIcon from "../../assets/icons/svg/TwitterFooterIcon";
import YoutubeFooterIcon from "../../assets/icons/svg/YoutubeFooterIcon";
import localizationStrings from "../../statics/localization";
import moment from "moment";
import Notification from "../notification";

const Footer = () => {
  const [showNotification, setShowNotification] = useState(false);
  let year = moment().format("YYYY");

  return (
    <div className="footer-container">
      <div className="footer-column">
        <div className="footer-top-line">
          <div className="footer-icon">
            <a
              href="https://www.facebook.com/filboxtv"
              className="span"
              target="_blank"
            >
              <FacebookFooterIcon />
            </a>
            <a
              href="https://twitter.com/FilboxPlatform"
              className="span"
              target="_blank"
            >
              <TwitterFooterIcon />
            </a>
            <a
              href="https://www.youtube.com/@filboxofficial605"
              className="span"
              target="_blank"
            >
              <YoutubeFooterIcon />
            </a>
          </div>
        </div>
        <div className="footers">
          <div className="footers-info">
            <div className="label">Bilgilendirme</div>
            <div className="label">Aydınlatma Metni</div>
          </div>
          <div className="label">©&nbsp;{year}&nbsp;Filbox</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
