import React, { useEffect, useState } from "react";
import OpenFullScreenIcon from "../../../assets/icons/svg/OpenFullScreenIcon";
import localizationStrings from "../../../statics/localization";

const FullscreenButton = ({ player, setFullscreen, isFullscreenVod }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (player) {
      player.on("fullscreenchange", () => {
        player.setTimeout(() => {
          setFullscreen(player.isFullscreen());
          setIsFullscreen(player.isFullscreen());
        }, 50);
      });
    }
  }, [player]);

  return (
    <button
      className="control-button"
      style={{
        marginLeft: !!isFullscreenVod ? "1.2vw" : isFullscreen ? "1.2vw" : null
      }}
      title={
        isFullscreen
          ? localizationStrings.playerOptions.offFullscreen
          : localizationStrings.playerOptions.onFullscreen
      }
      onClick={() => {
        isFullscreen ? player.exitFullscreen() : player.requestFullscreen();
        setIsFullscreen(!isFullscreen);
      }}
    >
      <OpenFullScreenIcon />
    </button>
  );
};

export default FullscreenButton;
