import React from "react";

const CreditCardIcon = () => {
  return (
    <svg
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1.16em"
      viewBox="0 0 50.061 57.96"
    >
      <path
        id="Fill_1"
        data-name="Fill 1"
        d="M.583,2.53,2,3.544,6.684,6.895a8.063,8.063,0,0,1,3.364,6.544V37.077a1.4,1.4,0,1,0,2.79,0V13.438A10.859,10.859,0,0,0,8.307,4.625L2.207.261A1.4,1.4,0,0,0,.583,2.53"
        transform="translate(37.223 19.488)"
        fill="currentColor"
      />
      <path
        id="Fill_4"
        data-name="Fill 4"
        d="M3.936,48.061h18.35a1.4,1.4,0,0,0,0-2.79H3.936A1.147,1.147,0,0,1,2.79,44.125V3.936A1.147,1.147,0,0,1,3.936,2.79H31.549A1.147,1.147,0,0,1,32.7,3.936V26.56L30.684,22.4a5.153,5.153,0,1,0-9.278,4.486l4.977,10.294V42.9a11.042,11.042,0,0,0,.361,2.8,10.916,10.916,0,0,0,3.771,5.774l2.337,1.863v3.221a1.4,1.4,0,1,0,2.79,0V52.672a1.394,1.394,0,0,0-.443-1.017c-.027-.025-.053-.05-.082-.074L32.253,49.3a8.141,8.141,0,0,1-3.081-6.4V36.859a1.394,1.394,0,0,0-.139-.607L23.917,25.67a2.363,2.363,0,0,1,4.254-2.057l8.083,16.718a1.4,1.4,0,0,0,2.512-1.215l-3.281-6.786V3.936A3.941,3.941,0,0,0,31.549,0H3.936A3.941,3.941,0,0,0,0,3.936V44.125a3.941,3.941,0,0,0,3.936,3.936"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        id="Fill_7"
        data-name="Fill 7"
        d="M1.4,0A1.4,1.4,0,0,0,0,1.4V5.167a1.4,1.4,0,1,0,2.79,0V1.4A1.4,1.4,0,0,0,1.4,0"
        transform="translate(7.198 7.272)"
        fill="currentColor"
      />
      <path
        id="Fill_9"
        data-name="Fill 9"
        d="M1.4,0A1.4,1.4,0,0,0,0,1.4V5.167a1.4,1.4,0,0,0,2.79,0V1.4A1.4,1.4,0,0,0,1.4,0"
        transform="translate(7.198 17.093)"
        fill="currentColor"
      />
      <path
        id="Fill_11"
        data-name="Fill 11"
        d="M1.4,0A1.4,1.4,0,0,0,0,1.4V5.167a1.4,1.4,0,1,0,2.79,0V1.4A1.4,1.4,0,0,0,1.4,0"
        transform="translate(7.198 26.914)"
        fill="currentColor"
      />
      <path
        id="Fill_13"
        data-name="Fill 13"
        d="M8.123,12.636h-5.8A2.328,2.328,0,0,1,0,10.31V2.326A2.328,2.328,0,0,1,2.325,0h5.8a2.328,2.328,0,0,1,2.326,2.326V10.31A2.328,2.328,0,0,1,8.123,12.636ZM2.79,2.79V9.845H7.657V2.79Z"
        transform="translate(13.767 7.272)"
        fill="currentColor"
      />
    </svg>
  );
};

export default CreditCardIcon;
