import React from "react";
import Moment from "react-moment";
import moment from "moment";

const DisplayDate = ({ onlyHour, onlyDate, fullDate, value, format }) => {
  value = moment(value, format ? format : "YYYY.MM.DD HH:mm").valueOf();

  const renderDate = () => {
    if (onlyHour) {
      return <Moment format="HH:mm">{value}</Moment>;
    } else if (onlyDate) {
      return <Moment format="DD.MM.yyyy">{value}</Moment>;
    } else if (fullDate) {
      return <Moment format="DD.MM.yyyy hh:mm">{value}</Moment>;
    }
  };

  return <div>{renderDate()}</div>;
};

export default DisplayDate;
