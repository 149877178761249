import React from "react";

const YayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.17"
      height="12.395"
      viewBox="0 0 16.17 12.395"
    >
      <g
        id="Group_505"
        data-name="Group 505"
        transform="translate(598.322 -309.859)"
      >
        <path
          id="Path_138"
          data-name="Path 138"
          d="M-373.508,322.253a3.162,3.162,0,0,1-1.807-.455.327.327,0,0,1-.134-.518q.484-.849.978-1.692c.138-.236.2-.251.453-.148a2.589,2.589,0,0,0,1.661.132,1.139,1.139,0,0,0,.887-1.033c.015-.138-.027-.138-.139-.1a3.555,3.555,0,0,1-1.428.234,1.982,1.982,0,0,1-1.908-1.561,5.446,5.446,0,0,1-.107-2.04c.107-1.146.206-2.293.309-3.439.021-.239.041-.477.069-.715s.109-.329.35-.36q.93-.118,1.863-.222c.264-.029.332.033.311.3-.053.67-.118,1.339-.177,2.008-.072.808-.148,1.617-.215,2.426a1.677,1.677,0,0,0,.029.6.465.465,0,0,0,.5.392,1.573,1.573,0,0,0,.651-.17c.091-.041.1-.112.105-.194.061-.689.125-1.378.187-2.067.095-1.06.187-2.121.284-3.181a.337.337,0,0,1,.339-.349c.62-.079,1.241-.157,1.862-.229.279-.033.347.038.322.324q-.216,2.456-.436,4.911c-.082.914-.159,1.829-.253,2.743a7.59,7.59,0,0,1-.429,1.86,3.639,3.639,0,0,1-3.088,2.463A6.31,6.31,0,0,1-373.508,322.253Z"
          transform="translate(-213.892)"
          fill="#0c1927"
        />
        <path
          id="Path_139"
          data-name="Path 139"
          d="M-595.4,351.131a2.825,2.825,0,0,1-1.266.181,1.379,1.379,0,0,1-1.164-.907,2.774,2.774,0,0,1-.181-1.293c.091-1.114.2-2.227.295-3.341.017-.2.093-.3.3-.322.437-.043.872-.1,1.308-.154.188-.021.245.036.228.225q-.14,1.591-.284,3.181c0,.026-.007.053-.009.079-.042.594.262.8.8.554a.209.209,0,0,0,.141-.192c.1-1.227.214-2.453.321-3.679.023-.27.082-.329.351-.36q.585-.067,1.168-.144c.293-.039.339-.007.314.285-.08.948-.166,1.9-.251,2.844-.074.828-.145,1.657-.227,2.485a5.349,5.349,0,0,1-.445,1.771,2.616,2.616,0,0,1-2.154,1.511,3.569,3.569,0,0,1-1.485-.033,2.221,2.221,0,0,1-.544-.243.234.234,0,0,1-.1-.355c.239-.422.484-.841.726-1.262.078-.135.175-.152.318-.089a1.833,1.833,0,0,0,1.191.1A.808.808,0,0,0-595.4,351.131Z"
          transform="translate(0 -33.686)"
          fill="#0c1927"
        />
        <path
          id="Path_140"
          data-name="Path 140"
          d="M-473.735,471.515a2.679,2.679,0,0,1-.964-.132,1.207,1.207,0,0,1-.758-1.5,1.445,1.445,0,0,1,1.481-1.2,3.99,3.99,0,0,1,.718.012c.056.007.1.018.114-.06a.38.38,0,0,0-.317-.487,1.794,1.794,0,0,0-.953.152c-.223.089-.232.089-.3-.144s-.145-.484-.22-.725c-.03-.1-.037-.18.07-.239a2.953,2.953,0,0,1,2.17-.36,1.234,1.234,0,0,1,.943,1.175,7.754,7.754,0,0,1-.1,1.63c-.041.43-.1.859-.146,1.289-.033.31-.041.325-.337.406A5.7,5.7,0,0,1-473.735,471.515Zm.161-1.241c.163.023.264-.038.281-.272.029-.387,0-.429-.393-.379-.179.022-.319.093-.348.29C-474.069,470.151-473.917,470.28-473.574,470.274Z"
          transform="translate(-117.92 -150.624)"
          fill="#0c1927"
        />
        <path
          id="Path_141"
          data-name="Path 141"
          d="M-463.436,341.6c.08,0,.16,0,.239,0,.263.014.339-.105.219-.35a.39.39,0,0,0-.384-.2,1.946,1.946,0,0,0-.889.187c-.139.067-.209.029-.25-.111q-.117-.4-.242-.8a.173.173,0,0,1,.1-.229,3.041,3.041,0,0,1,2.117-.359,1.239,1.239,0,0,1,.97,1.131,5.537,5.537,0,0,1-.058,1.391c-.057.542-.13,1.083-.183,1.626a.347.347,0,0,1-.3.36,4.982,4.982,0,0,1-2.153.125,1.137,1.137,0,0,1-1.024-.992,1.467,1.467,0,0,1,.729-1.575A2.2,2.2,0,0,1-463.436,341.6Zm.012,1.6c.3,0,.362-.078.351-.517-.006-.216-.2-.135-.3-.142-.3-.018-.471.132-.464.364C-463.831,343.1-463.687,343.2-463.424,343.2Z"
          transform="translate(-127.716 -28.629)"
          fill="#0c1927"
        />
      </g>
    </svg>
  );
};

export default YayIcon;
