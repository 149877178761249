import React from "react";

const FacebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.176"
      height="12.351"
      viewBox="0 0 6.176 12.351"
    >
      <path
        id="Facebook"
        d="M6.176,4h-2.1V2.717a.542.542,0,0,1,.584-.594H6.141V.008L4.1,0A2.488,2.488,0,0,0,1.312,2.589V4H0V6.18H1.312v6.171H4.073V6.18H5.934Z"
        fill="#0c1927"
      />
    </svg>
  );
};

export default FacebookIcon;
