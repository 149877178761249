import React from "react";

const TvLockIcon = () => {
  return (
    <svg
      className="channel-list-item-icons"
      xmlns="http://www.w3.org/2000/svg"
      // width="80%"
      // height="80%"
      viewBox="0 0 8.68 11.808"
    >
      <g id="Group_7" data-name="Group 7" transform="translate(0 0)">
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M4.333,7.393A4.334,4.334,0,0,1,.094,3.953,3.968,3.968,0,0,1,0,3.1C0,2.22,0,1.321,0,.452V.445A.4.4,0,0,1,.446,0H8.2c.343,0,.483.141.483.487v.869q0,.869,0,1.738A4.344,4.344,0,0,1,5.4,7.265,4.56,4.56,0,0,1,4.333,7.393Zm.01-5.605h0a.822.822,0,0,0-.595.25.805.805,0,0,0-.23.584.836.836,0,0,0,.272.592.18.18,0,0,1,.065.176q-.048.373-.093.746c-.031.25-.061.5-.093.747a.325.325,0,0,0,.053.263.309.309,0,0,0,.242.084h.773a.285.285,0,0,0,.223-.08A.3.3,0,0,0,5.014,4.9q-.034-.269-.066-.537c-.039-.318-.079-.647-.122-.969a.193.193,0,0,1,.068-.187.839.839,0,0,0,.269-.6.817.817,0,0,0-.245-.589.8.8,0,0,0-.575-.236Z"
          transform="translate(0 4.415)"
          fill="currentColor"
        />
        <path
          id="Fill_4"
          data-name="Fill 4"
          d="M0,3.233A3.162,3.162,0,0,1,.113,2.12,3.181,3.181,0,0,1,1.506.494a3.3,3.3,0,0,1,4.246.665,3.169,3.169,0,0,1,.772,2.08c0,.213,0,.429,0,.638,0,.083-.025.11-.108.109-.348,0-.7,0-1.045,0-.091,0-.119-.029-.118-.116,0-.207,0-.407,0-.611a1.991,1.991,0,0,0-3.979-.106c-.013.235-.011.47-.009.705,0,.091-.022.128-.12.127-.344-.005-.688,0-1.031,0C.029,3.987,0,3.956,0,3.871c0-.214,0-.425,0-.638"
          transform="translate(1.076 0)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default TvLockIcon;
