import axios from "axios";

const checkNpvrStatus = async (epgProgramId) => {
  return await axios.get("/npvr/list/check", {
    params: {
      epgProgramId: epgProgramId
    }
  });
};

const checkNpvrQuota = async () => {
  return await axios.get("/npvr/list/quota");
};

const addRecord = async (
  epgProgramId,
  seriesId,
  currentTime,
  isRecordedNextEpisodes
) => {
  let requestData = {
    epgProgramId: epgProgramId,
    requestDateTime: currentTime,
    recordNextEpisodes: isRecordedNextEpisodes
  };
  if (seriesId !== null) requestData.seriesId = seriesId;

  return await axios.post("/npvr", requestData);
};

const removeRecord = async (epgProgramId, isRemovedAll) => {
  return await axios.delete("/npvr/" + epgProgramId, {
    params: {
      deleteSeries: isRemovedAll
    }
  });
};

const getRecordedList = async () => {
  return await axios.get("/npvr/list/recorded");
};

const getWillBeRecordedList = async () => {
  return await axios.get("/npvr/list/recording");
};

const getSeriesDetail = async (epgProgramId, seriesId) => {
  return await axios.get("/npvr/list/seriesDetail", {
    params: {
      epgProgramId: epgProgramId,
      seriesId: seriesId
    }
  });
};

const getNpvrWatchUrl = async (epgProgramId) => {
  return await axios.get("/npvr/watch/" + epgProgramId);
};

const getUserRecords = async (prevueCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getusernpvrlist.jsp`, {
    params: { pageno: "1", numperpage: "500", prevuecodes: prevueCode }
  });
};

const getNpvrQuotaLimit = async () => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getnpvrtime.jsp`);
};

const getUserSerieRecords = async (seriesHeadId) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getuserseriesnpvrlist.jsp`, {
    params: { pageno: "1", numperpage: "500", seriesheadid: seriesHeadId }
  });
};

const startNpvr = async (prevueCode, channelId) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/addnpvr.jsp`, {
    params: {
      prevuecode: prevueCode,
      channelcode: channelId,
      mediaservices: 2
      // autodelete: "1"
    }
  });
};

const updateNpvr = async (prevueCode, autoDelete) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_updatenpvr.jsp`, {
    params: {
      prevuecode: prevueCode,
      autodelete: autoDelete
    }
  });
};

const checkNpvrLimit = async (prevueCode, seriesHeadId) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_limitnpvr.jsp`, {
    params: {
      prevuecode: prevueCode,
      seriesheadid: seriesHeadId
    }
  });
};

const startSerieNpvr = async (prevueCode, channelId, seriedHeadId) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/addnpvrseries.jsp`, {
    params: {
      prevuecode: prevueCode,
      channelcode: channelId,
      mediaservices: 2,
      seriesheadid: seriedHeadId,
      confirm: 0,
      istotalseason: "1"
      // autodelete: "1"
    }
  });
};

const getNpvrPlayUrl = async (prevueCode, channelCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getnpvrplayurl.jsp`, {
    params: {
      prevuecode: prevueCode,
      channelcode: channelCode,
      urlredirect: 1
    }
  });
};

const getProgramPlayUrl = async (prevueCode, channelCode, contentType) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_gettvodplayurl.jsp`, {
    params: {
      prevuecode: prevueCode,
      channelcode: channelCode,
      urlredirect: 1,
      contenttype: contentType
    }
  });
};

const getRecordDetail = async (prevueCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_gettvoddetail.jsp`, {
    params: { prevuecode: prevueCode }
  });
};

const deleteNpvrRecord = async (prevueCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/delnpvr.jsp`, {
    params: { prevuecode: prevueCode }
  });
};

const deleteSerieNpvrRecord = async (seriedHeadId) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/delnpvrseries.jsp`, {
    params: { seriesheadid: seriedHeadId, istotalseason: "1" }
  });
};

export {
  checkNpvrStatus,
  checkNpvrQuota,
  addRecord,
  removeRecord,
  getRecordedList,
  getWillBeRecordedList,
  getSeriesDetail,
  getNpvrWatchUrl,
  getUserRecords,
  getNpvrPlayUrl,
  getRecordDetail,
  deleteNpvrRecord,
  startNpvr,
  startSerieNpvr,
  getUserSerieRecords,
  deleteSerieNpvrRecord,
  getNpvrQuotaLimit,
  getProgramPlayUrl,
  updateNpvr,
  checkNpvrLimit
};
