import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import "./ForgotPasswordPage.scss";
import localizationStrings from "../../statics/localization";
import LoginWrapper from "../loginWrapper";
import DoneIcon from "../../assets/icons/svg/DoneIcon";
import { callForgotPassword, callVerifyToken } from "../../api/login/loginApi";
import successLogo from "../../assets/images/success.png";
import errorLogo from "../../assets/images/error.png";
import useQuery from "../../utils/useQuery";
import mainLoadingGif from "../../assets/loading.gif";

const ForgotPasswordPage = () => {
  const [isSumbitted, setIsSumbited] = useState(false);
  const [logo, setLogo] = useState();
  const [responseMessage, setResponseMessage] = useState("");
  const [showButtonLoading, setShowButtonLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { goBack, push } = useHistory();

  let content;
  const {
    forgotPassword,
    enterText,
    mobilePhoneNumber,
    doneMessage,
    confirm,
    validNumberText
  } = localizationStrings.login;

  const { emptyMessageText } = localizationStrings;
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const registerOptions = {
    required: `${emptyMessageText}`, // Error message for required field
    pattern: {
      value: emailRegex,
      message: `Geçerli bir e-posta adresi girin.` // Error message for incorrect format
    }
  };

  const handleRegistration = async (_data) => {
    setShowButtonLoading((prevState) => !prevState);
    const { data } = await callForgotPassword(_data.email);
    console.log("data:", data);
    if (data == 0) {
      setResponseMessage(
        "E-posta adresinize ulaşım sağlanmıştır. Bir kaç dakika içinde e-posta göndereceğiz."
      );
      setLogo(successLogo);
    } else if (data == 10404) {
      setResponseMessage(
        "Bu e-posta adresiyle kullanıcı kaydı bulunamadı. Lütfen e-posta adresini kontrol ediniz."
      );
      setLogo(errorLogo);
    } else {
      setResponseMessage(
        "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."
      );
      setLogo(errorLogo);
    }
    setIsSumbited((prevState) => !prevState);
    setShowButtonLoading((prevState) => !prevState);
  };

  if (isSumbitted) {
    content = (
      <div className="done-section">
        <img
          src={logo}
          alt="success logo"
          style={{ maxWidth: "200px", height: "auto" }}
        />
        <p className="done-message">{responseMessage}</p>
        <button className="continue-button" onClick={goBack}>
          Tamam
        </button>
      </div>
    );
  } else {
    content = (
      <>
        <p className="forgot-password-title">{forgotPassword}</p>
        <p className="forgot-password-subtitle">{enterText}</p>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={registerOptions}
          render={({ field }) => (
            <input
              {...field}
              type="email"
              className="input-phone-number"
              placeholder={`E-posta`}
              style={errors.email ? { border: "2px solid red" } : {}}
            />
          )}
        />

        <div className="input-error">
          <p> {errors?.email && errors.email.message}</p>
        </div>
        <button
          onClick={handleSubmit(handleRegistration)}
          className="continue-button"
          style={{ cursor: showButtonLoading ? "not-allowed" : "" }}
          disabled={showButtonLoading}
        >
          {showButtonLoading ? (
            <div className="loading-gif">
              <img src={mainLoadingGif} />
            </div>
          ) : (
            localizationStrings.continue
          )}
        </button>

        <button
          onClick={() => {
            push("/login");
          }}
          style={{ color: "#5a398a", backgroundColor: "white" }}
          className="continue-button"
        >
          Geri
        </button>
      </>
    );
  }

  return <LoginWrapper>{content}</LoginWrapper>;
};

export default ForgotPasswordPage;
