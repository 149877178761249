import React from "react";

const MobilePhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1.6em"
      viewBox="0 0 36.398 58.66"
    >
      <g id="icon" transform="translate(0.35 0.35)">
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M30.02,57.96H5.678A5.685,5.685,0,0,1,0,52.282V5.678A5.685,5.685,0,0,1,5.678,0H30.02A5.685,5.685,0,0,1,35.7,5.678v46.6A5.685,5.685,0,0,1,30.02,57.96ZM2.52,49.929v2.353A3.162,3.162,0,0,0,5.678,55.44H30.02a3.162,3.162,0,0,0,3.158-3.158V49.929ZM5.678,2.52A3.162,3.162,0,0,0,2.52,5.678V47.409H33.179V5.678A3.162,3.162,0,0,0,30.02,2.52Z"
          transform="translate(0)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.7"
        />
        <path
          id="Fill_4"
          data-name="Fill 4"
          d="M1.26,2.52h7.8a1.26,1.26,0,1,0,0-2.52H1.26a1.26,1.26,0,0,0,0,2.52"
          transform="translate(12.692 6.215)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.7"
        />
      </g>
    </svg>
  );
};

export default MobilePhoneIcon;
