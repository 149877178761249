import moment from "moment";

//It's formating thumbnails which is coming from ZTE BE to videojs-thumbnail-sprite plugin.
export const formatProgramThumbnails = (thumbnailObj, startTime, endTime) => {
  let interval =
    parseInt(thumbnailObj.thumbnails[0].segments[1].realtime) -
    parseInt(thumbnailObj.thumbnails[0].segments[0].realtime);
  interval = interval / 1000;

  startTime = +moment(startTime, "YYYYMMDDHHmmss").format("YYYYMMDDHHmmssSSS");
  endTime = +moment(endTime, "YYYYMMDDHHmmss").format("YYYYMMDDHHmmssSSS");

  let thumbnails_ = [];
  if (!!thumbnailObj && thumbnailObj != undefined) {
    thumbnailObj.thumbnails.forEach((item) => {
      // if (
      //   +item.segments[0].realtime >= startTime &&
      //   +item.segments[0].realtime < endTime
      // ) {
      //   thumbnails_.push(item);
      // }

      for (let index = 0; index < item.segments.length; index++) {
        if (
          +item.segments[index].realtime >= startTime &&
          +item.segments[index].realtime < endTime
        ) {
          thumbnails_.push(item);
          break;
        }
      }
    });

    let bufferTime =
      (startTime - parseInt(thumbnailObj.thumbnails[0].segments[0].realtime)) /
      1000;
    let formatedThumbnails = thumbnails_.map((item, index) => {
      let duration = parseInt(item.spritenum) * interval;

      return {
        duration: duration,
        url: item.templateurl,
        width: thumbnailObj.width,
        height: thumbnailObj.height,
        interval: interval,
        start: index * duration
      };
    });
    return formatedThumbnails;
  }
};

export default formatProgramThumbnails;
