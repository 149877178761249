import React from "react";

import PlayerExitIcon from "../../../assets/icons/svg/PlayerExitIcon";

const ExitButton = ({ player, goBack }) => {
  return (
    <div>
      <button
        className="exit-button"
        onClick={() => {
          goBack();
          player.pause();
        }}
      >
        <PlayerExitIcon />
      </button>
    </div>
  );
};

export default ExitButton;
