import React from "react";

const AudioOnIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 0 28.896 28.896"
    >
      <path
        id="Volume"
        d="M16.254,28.9V25.844c5.436-1.318,9.464-5.707,9.464-11.4,0-5.707-4.028-10.1-9.464-11.414V0C23.46,1.355,28.9,7.314,28.9,14.448,28.9,21.564,23.46,27.524,16.254,28.9ZM4.334,18.855H0v-8.85H4.316L12.642.867V27.993Zm11.92,1.805V8.2c3.143.922,5.418,3.36,5.418,6.231C21.672,17.32,19.4,19.74,16.254,20.66Z"
        fill="#fff"
      />
    </svg>
  );
};

export default AudioOnIcon;
