import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

const MainboardThumbnail = ({ items, selectIndex }) => {
  const [progressWidth, setProgressWidth] = useState(0);
  const [startProgress, setStartProgress] = useState(false);
  const slider = useRef(null);
  const itemRef = useRef(null);
  const timeoutRef = useRef(null);
  let autoplaySpeed = 10000;

  var settings = {
    dots: false,
    infinite: items?.length >= 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: autoplaySpeed,
    draggable: false,
    pauseOnHover: false,
    beforeChange: (current, next) => {
      if (current !== next && current >= 0 && next >= 0) {
        setStartProgress(false);
      }
    },
    afterChange: (index) => {
      setStartProgress(true);
      if (items?.length == index + 1) {
        setTimeout(() => {
          slider.current.slickGoTo(0);
        }, autoplaySpeed);
      }
    }
  };

  const renderedItems = items?.map((item, index) => {
    const horizontalPoster = item?.posters.find((poster) => !poster.vertical);
    return (
      <div
        key={item?.id}
        className="thumbnail-item"
        onClick={(e) => {
          slider.current.slickGoTo(index);
          selectIndex(index);
        }}
      >
        <div className="img-wrapper">
          <img
            ref={itemRef}
            src={horizontalPoster.url}
            onLoad={() => {
              if (index == 0) {
                setStartProgress(true);
              }
            }}
          />
          <div className="mask"></div>
        </div>
      </div>
    );
  });

  useEffect(() => {
    if (itemRef.current) {
      setProgressWidth(itemRef.current.width);
    }
  }, [itemRef.current]);

  useEffect(() => {
    if (items?.length) {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        slider.current.slickGoTo(0);
      }, 300);
    }
  }, [items]);

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div className="thumbnail-slider">
      <Slider ref={slider} {...settings}>
        {renderedItems}
      </Slider>
      <div
        className="thumbnail-progress"
        style={{ width: `${progressWidth}px` }}
      >
        <div className="progress-all">
          <div
            className={`progress ${startProgress ? "progress-animation" : ""}`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MainboardThumbnail;
