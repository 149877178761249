import React, { useEffect, useRef, useState } from "react";
import TrashIcon from "../../assets/icons/svg/TrashIcon";
import defaultPoster from "../../assets/images/no-portrait-poster.png";
import defaultHorizontalPoster from "../../assets/images/default-vod-horizontal-poster.png";
import localizationStrings from "../../statics/localization";
import useOutsideClick from "../../utils/useOutsideClick";
import Notification from "../notification";

const RecordItem = ({
  item,
  onSelect,
  title,
  deleteRecord,
  fromSearch,
  onHoverProgramItem
}) => {
  const [posterUrl, setPosterUrl] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const titleRef = useRef(null);
  const optionRef = useRef(null);
  const timeoutRef = useRef(null);
  const itemRef = useRef(null);

  useOutsideClick(optionRef, () => {
    setShowOptions(false);
  });

  useEffect(() => {
    if (item) {
      item.posterimage = `${item.posterpath}/${
        item.posterfilelist?.split(";")[fromSearch ? 2 : 0]
      }`;
      setPosterUrl(item.posterimage);
    }
  }, [item]);

  return (
    <div
      ref={itemRef}
      className="vod-item"
      style={{
        position: "relative",
        width: fromSearch ? "20%" : ""
      }}
      onClick={(e) => {
        if (!showNotification) onSelect(item);
      }}
      onMouseEnter={() => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          let pos = itemRef.current?.getClientRects();
          if (pos?.length) {
            pos = pos[0];
          }
          let _data = {
            position: pos,
            data: item
          };
          onHoverProgramItem(true, _data);
        }, 500);
      }}
      onMouseLeave={() => {
        clearTimeout(timeoutRef.current);
      }}
    >
      <div key={item?.programcode} className="carousel-item">
        <img
          className="carousel-poster"
          src={posterUrl}
          onError={() =>
            setPosterUrl(fromSearch ? defaultHorizontalPoster : defaultPoster)
          }
          style={{ width: "100%", height: "auto" }}
        />
      </div>

      <div
        className="record-title"
        ref={titleRef}
        style={{ display: !title ? "none" : "" }}
      >
        {title}
      </div>

      <div
        ref={optionRef}
        className="dot-options"
        style={{ display: fromSearch ? "none" : "" }}
      >
        <div className="dots-wrapper">
          <div className={`options-box ${showOptions ? "show" : ""}`}>
            <div className="tri-angle"></div>
            <div className="options">
              <div className="option" onClick={() => setShowNotification(true)}>
                <TrashIcon />
                <span>{localizationStrings.removeContent}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showNotification ? (
        <Notification
          text={localizationStrings.deleteRecordConfirmText}
          confirmText={localizationStrings.delete}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={() => {
            deleteRecord(item);
          }}
          onClickNotConfirm={() => setShowNotification(false)}
        />
      ) : null}
    </div>
  );
};

export default RecordItem;
