export const findPoster = (posters, posterType) => {
  let posterUrl;
  if (posterType === "banner") {
    const posterItem = posters?.find((poster) => poster?.banner);
    posterUrl = posterItem?.url;
    return posterUrl;
  } else if (posterType === "vertical") {
    const posterItem = posters?.find((poster) => poster?.vertical);
    posterUrl = posterItem?.url;
    return posterUrl;
  } else if (posterType === "horizontal") {
    const posterItem = posters?.find((poster) => poster?.vertical == false);
    posterUrl = posterItem?.url;
    return posterUrl;
  } else if (posterType === "horizontal-low") {
    const posterItem = posters?.find(
      (poster) => poster?.type == "HorizontalLow"
    );
    posterUrl = posterItem?.url;
    return posterUrl;
  }
};
