import React from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import VodDetail from "../vodDetail";
import ProtectedRoute from "../../secure/protectedRoute";
import Payment from "../payment/Payment";
import ProgramDetail from "../programDetail";

const ModalRouting = () => {
  let location = useLocation();
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/voddetail/:vodId`} component={VodDetail} />
      {/* <ProtectedRoute path={`${path}/voddetail/:vodId`}>
        <VodDetail key={location.pathname} />
      </ProtectedRoute> */}
      <ProtectedRoute path={`${path}/payment/:programId`}>
        <Payment key={location.pathname} />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/program/:id`}>
        <ProgramDetail key={location.pathname} />
      </ProtectedRoute>
    </Switch>
  );
};

export default ModalRouting;
