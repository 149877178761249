import React, { useContext, useEffect, useState } from "react";

import CheckedIcon from "../../assets/icons/svg/CheckedIcon";
import FavoriteIcon from "../../assets/icons/svg/FavoriteIcon";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";
import { sendCustomFavoriteReport } from "../../utils/reporting/customReporting";
import { toggleFavById } from "../../api/vod/vod";

const FavoriteAction = ({ vod }) => {
  const { setIsChangeFavList, setShowLoginMessage, hasLoggedIn } =
    useContext(SessionContext);
  const [isFav, setIsFav] = useState(false);

  useEffect(() => {
    let vodStatus = vod?.interactions;

    let hasFav =
      vodStatus?.length &&
      vodStatus.find((element) => element?.type === "VOD_WATCHLIST");
    setIsFav(!!hasFav);
  }, [vod]);

  const addContentToFavorite = async () => {
    /* 
    if (data.returncode === "0") {
      setIsChangeFavList(true);
      setIsChangeFavList(false);
      sendCustomFavoriteReport(
        favType,
        vodInfo.contentcode,
        vodInfo.columncode,
        vodInfo.programcode,
        1,
        data.returncode,
        data.errormsg
      );
    } */
  };

  const removeContentFromFavorite = async () => {
    /* if (data.returncode === "0") {
      setIsChangeFavList(true);
      setIsChangeFavList(false);
      sendCustomFavoriteReport(
        favType,
        vodInfo.contentcode,
        vodInfo.columncode,
        vodInfo.programcode,
        0,
        data.returncode,
        data.errormsg
      );
    } */
  };

  return (
    <div
      className={`action-button secondary ${isFav ? "selected" : ""}`}
      onClick={() => {
        if (hasLoggedIn) {
          setIsFav((prevState) => !prevState);

          toggleFavById(vod?.id);
        } else {
          setShowLoginMessage(true);
        }
      }}
    >
      <span className="icon">
        {!isFav ? <FavoriteIcon /> : <CheckedIcon />}
      </span>
      {isFav ? (
        <span className="text">{localizationStrings.inMyList}</span>
      ) : null}
    </div>
  );
};

export default FavoriteAction;
