import React from "react";

const HidePasswordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.237"
      height="15.854"
      viewBox="0 0 20.609 17.519"
    >
      <path
        id="eye-2"
        transform="translate(0 -2.441)"
        d="M11.5 3.152q-.59-.064-1.2-.073a12.655 12.655 0 00-5.34 1.263A13.607 13.607 0 001.33 6.99 4.488 4.488 0 000 9.253a4.526 4.526 0 001.33 2.263 13.4 13.4 0 003.635 2.648l.142.068-1.289 2.251 1.752 1.036L15.04 1.03 13.354 0 11.5 3.152zm4 1.124l-1.287 2.23a4.482 4.482 0 01.943 2.746 4.751 4.751 0 01-4.856 4.643c-.116 0-.228-.013-.342-.02l-.848 1.474c.393.042.79.073 1.195.078a12.707 12.707 0 005.339-1.263 13.612 13.612 0 003.637-2.648 4.486 4.486 0 001.33-2.263 4.526 4.526 0 00-1.33-2.263 13.405 13.405 0 00-3.637-2.648c-.047-.023-.1-.043-.142-.065zm-5.2.334c.117 0 .234 0 .35.013l-1 1.727a3 3 0 00-2.45 2.9 2.847 2.847 0 00.187 1.019l-1 1.732a4.486 4.486 0 01-.943-2.751A4.751 4.751 0 0110.3 4.611zm2.9 3.642l-2.248 3.9a3 3 0 002.435-2.9 2.848 2.848 0 00-.187-1z"
        fill="#fff"
      />
    </svg>
  );
};

export default HidePasswordIcon;
