import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./style/Main.scss";
import SessionProvider from "./context/SessionContext";
import { CookiesProvider } from "react-cookie";
import { HashRouter, Switch } from "react-router-dom";

ReactDOM.render(
  <SessionProvider>
    <CookiesProvider>
      <HashRouter basename="/">
        <Switch>
          <App />
        </Switch>
      </HashRouter>
    </CookiesProvider>
  </SessionProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
