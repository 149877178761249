import React from "react";

const BigLockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50.601"
      height="71.824"
      viewBox="0 0 50.601 71.824"
    >
      <path
        id="Path_34"
        data-name="Path 34"
        d="M3019.538,770.978c0-4.066.1-8.137-.041-12.2a4.013,4.013,0,0,1,4.051-4.259c1.131.06,1.329-.407,1.313-1.41-.052-3.3-.043-6.6-.01-9.9a19.952,19.952,0,0,1,39.9-.138c.021,3.382.028,6.764,0,10.146-.008.869.146,1.292,1.172,1.312,2.683.052,4.136,1.573,4.144,4.282.022,8.052.061,16.1-.009,24.156a12.121,12.121,0,0,1-12.221,12.2q-13.044.1-26.09,0a12.142,12.142,0,0,1-12.2-12.223C3019.494,778.95,3019.539,774.964,3019.538,770.978Zm25.18-16.483c4.067,0,8.135-.028,12.2.022.913.011,1.188-.265,1.175-1.156-.053-3.583-.042-7.167-.068-10.75a13.222,13.222,0,0,0-26.419-.388c-.15,3.739-.046,7.488-.1,11.231-.013.871.318,1.065,1.133,1.057C3036.664,754.472,3040.691,754.493,3044.718,754.495Zm-2.482,23.667a15.881,15.881,0,0,0,.011,1.8,2.573,2.573,0,0,0,5.13-.028,10.1,10.1,0,0,0,.051-1.444,5.151,5.151,0,0,1,.941-3.593,3.9,3.9,0,0,0-.827-5.1,4.193,4.193,0,0,0-5.593.093,4,4,0,0,0-.636,5.154A4.32,4.32,0,0,1,3042.235,778.162Z"
        transform="translate(-3019.495 -723.389)"
        fill="#fff"
        opacity="0.06"
      />
    </svg>
  );
};

export default BigLockIcon;
