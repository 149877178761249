export const fancyHourFormatter = (startTime, endTime) => {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  // Format time strings in "HH:mm" format
  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const startTimeString = formatTime(startDate);
  const endTimeString = formatTime(endDate);

  return { startTimeString, endTimeString };
};
