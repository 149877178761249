import axios from "axios";
import { apiEndPointConstant } from "../../statics/constants";
import { axiosInstance } from "../config/configApi";

const getLoginToken = async (userName, password) => {
  return await axiosInstance.post(
    apiEndPointConstant.authClient + "/auth/login",
    {
      username: userName,
      password: password
    }
  );
};

const getSubscription = async () => {
  return await axiosInstance.get(
    apiEndPointConstant.authClient + "/subscription/my-subscriptions"
  );
};

const getAllPackages = async () => {
  return await axiosInstance.get("/subscription");
};

const registerUser = async (username, name, surname, email, password) => {
  return await axiosInstance.post(
    apiEndPointConstant.authClient + "/auth/register",
    {
      userName: username,
      name: name,
      surname: surname,
      email: email,
      password: password
    }
  );
};

const logout = async () => {
  return await axiosInstance.post(
    apiEndPointConstant.authClient + "/auth/logout"
  );
};

const callForgotPassword = async (email) => {
  return await axiosInstance.get(
    apiEndPointConstant.authClient + "/auth/forgot-password?email=" + email
  );
};

const callVerifyToken = async (token) => {
  return await axiosInstance.get(
    apiEndPointConstant.authClient + "/auth/validate-fp-token?token=" + token
  );
};

const resetPassword = async (token, password) => {
  return await axiosInstance.put(
    apiEndPointConstant.authClient + "/auth/reset-password",
    {
      token: token,
      password: password
    }
  );
};

export {
  getLoginToken,
  logout,
  registerUser,
  getSubscription,
  getAllPackages,
  callForgotPassword,
  callVerifyToken,
  resetPassword
};
