import React from "react";
import RtukBadBehaviour from "../assets/icons/svg/RtukBadBehaviour";
import RtukSexualIcon from "../assets/icons/svg/RtukSexualIcon";
import RtukViolanceIcon from "../assets/icons/svg/RtukViolanceIcon";

export const getParentalAdvisoryElements = (parentalAdvisory) => {
  let parentalAdvisoryArr = parentalAdvisory;
  let parentalAdvisoryElement = parentalAdvisoryArr?.map((item) => {
    if (item === "SEXUALITY") {
      return (
        <div className="rating">
          <RtukSexualIcon />
        </div>
      );
    } else if (item === "VIOLENCE") {
      return (
        <div className="rating">
          <RtukViolanceIcon />
        </div>
      );
    } else if (item === "BAD_BEHAVIOR") {
      return (
        <div className="rating">
          <RtukBadBehaviour />
        </div>
      );
    }
  });

  return parentalAdvisoryElement;
};

export default getParentalAdvisoryElements;
