export const checkTvodSavetime = (programBegintime, _tvodSaveTime) => {
  let tvodSavetime = parseInt(_tvodSaveTime);
  let timeDiff = parseInt(localStorage.getItem("timeDiff"));
  if (!!tvodSavetime && tvodSavetime > 0) {
    let nowTime = new Date().getTime() - timeDiff;
    let prevueStartTime = new Date(
      programBegintime.replace(/\./g, "/")
    ).getTime();
    let tvodToNowTime = (nowTime - prevueStartTime) / 60000;

    return tvodSavetime >= tvodToNowTime;
  } else {
    return false;
  }
};
