import React from "react";
import CoveragePhone from "../../assets/icons/CoveragePhone";
import CoveragePackageInstall from "../../assets/icons/svg/CoveragePackageInstall";
import localizationStrings from "../../statics/localization";

const ChannelCoverage = () => {
  return (
    <div className="coverage">
      <div className="channel-coverage">
        {localizationStrings.channelCoverage}
      </div>
      <div className="coverage-phone">
        <div className="phone">
          <CoveragePhone /> &nbsp;444 5 375
        </div>
      </div>
      <div className="package-install">
        <CoveragePackageInstall />
        &nbsp;
        <div style={{ marginBottom: "0.5vw" }}>
          {localizationStrings.installationProcess}
        </div>
      </div>
      <div className="package-change">
        {localizationStrings.formatString(
          localizationStrings.packageInstall.packageChange,
          {
            phone: (
              <span style={{ color: "#00deff" }}>
                {localizationStrings.packageInstall.phone}
              </span>
            ),
            watchChannel: (
              <span style={{ color: "#00deff" }}>
                {localizationStrings.packageInstall.watchChannel}
              </span>
            ),
            number: (
              <span style={{ color: "#00deff" }}>
                {localizationStrings.packageInstall.number}
              </span>
            )
          }
        )}
      </div>
    </div>
  );
};

export default ChannelCoverage;
