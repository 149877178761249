import React from "react";

const LikeIcon = ({ fill }) => {
  return (
    <svg
      id="Actions_Controls_pause--filled_24"
      data-name="Actions / Controls / pause--filled / 24"
      xmlns="http://www.w3.org/2000/svg"
      width="16.637"
      height="16.045"
      viewBox="0 0 16.637 16.045"
    >
      <path
        id="Fill"
        d="M11.281,16.045H0V7.732H3.835l2.1-3.188L6.531.779V.708A.894.894,0,0,1,7.38.013h.933a1.911,1.911,0,0,1,.451,0,2.161,2.161,0,0,1,1.924,2.375V5.357H14.25a1.911,1.911,0,0,1,.451,0,2.161,2.161,0,0,1,1.924,2.375v3.563C16.625,13.461,15.7,16.045,11.281,16.045ZM7.642,1.2l-.083.594L7.125,4.983,4.75,8.5v6.353h6.531c2.836,0,4.157-1.132,4.157-3.563V7.732c0-.855-.333-1.188-1.188-1.188H9.5V2.388C9.5,1.533,9.167,1.2,8.313,1.2ZM1.188,8.92v5.937H3.563V8.92Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LikeIcon;
