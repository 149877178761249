import React from "react";

const PlayerExitIcon=()=> {
  return (<svg xmlns="http://www.w3.org/2000/svg"
    width="1.2em"
    height="1em"
    viewBox="0 0 32.103 28.093"
    > <path id="arrow-back-outline"
    d="M36.1,19.539H12.3l7.283-8.748a2.009,2.009,0,0,0-3.09-2.568L6.461,20.262a2.388,2.388,0,0,0-.181.3.254.254,0,0,1-.14.261,1.928,1.928,0,0,0,0,1.445.254.254,0,0,0,.14.261,2.388,2.388,0,0,0,.181.3L16.494,34.869a2.009,2.009,0,1,0,3.09-2.568L12.3,23.552H36.1a2.006,2.006,0,0,0,0-4.013Z"
    transform="translate(-6 -7.498)"
    fill="#fff"
    /> </svg>);
}

;

export default PlayerExitIcon;