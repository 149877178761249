import React, { useContext, useState } from "react";
import { SessionContext } from "../../context/SessionContext";
import {
  doPaymentTvod,
  checkProfilePassword,
  getWirecardPayResult
} from "../../api/user/user";
import Modal from "../modal/Modal";
import ConfirmPayment from "../confirmPayment";
import localizationStrings from "../../statics/localization";
import EnterIcon from "../../assets/icons/svg/EnterIcon";
import InvoiceIcon from "../../assets/icons/svg/InvoiceIcon";
import { sendCustomSubscribeReport } from "../../utils/reporting/customReporting";
import MobilePhoneIcon from "../../assets/icons/svg/MobilePhoneIcon";
import CreditCardIcon from "../../assets/icons/svg/CreditCardIcon";
import { ttProduct } from "../../statics/config";
import WireCard from "../wireCard";

const PpvPayment = ({ program, ppvProduct, closePpv }) => {
  const [showConfirmPage, setShowConfirmPage] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [purchasePassword, setPurchasePassword] = useState(null);
  const { adminProfile, userFrameConfig } = useContext(SessionContext);
  const [selectedPayOption, setSelectedPayOption] = useState(null);
  const [mPayGuid, setmPayGuid] = useState("");
  const [wirecardUrl, setWirecardUrl] = useState("");
  const [showWirecard, setShowWirecard] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const checkPinCode = async () => {
    let encodedPassword = encodeURI(
      window.EncryptUtils.encryptStr(purchasePassword)
    );
    const data = await checkProfilePassword(
      adminProfile.profilecode,
      encodedPassword
    );

    if (data.data.returncode == "0") {
      setShowConfirmPage(true);
      setShowPin(false);
    } else {
      alert(data.data.errormsg);
    }
  };

  const billPayment = async () => {
    let programCode;
    let type;

    programCode = program.prevuecode;
    type = "18";

    const data = await doPaymentTvod(
      type,
      programCode,
      ppvProduct.productcode,
      selectedPayOption,
      localStorage.getItem("deviceid")
    );
    if (data.data.returncode === "0") {
      closePpv(true);
    } else {
      setShowPin(false);
      setShowConfirmPage(false);
    }
    sendCustomSubscribeReport(
      ppvProduct.productcode,
      ppvProduct.fee,
      programCode,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const getPayResult = async (mPay, id) => {
    const data = await getWirecardPayResult(mPay);
    if (data.data.returncode === "0") {
      let status = data.data.status;
      if (status === "1") {
        setShowWirecard(false);
        clearInterval(id);
        closePpv(true);
      } else if (status === "3" || status === "4" || status === "5") {
        setShowWirecard(false);
        clearInterval(id);
      }
    }
  };

  const doMobileOrCreditCardPayment = async () => {
    const data = await doPaymentTvod(
      18,
      program.prevuecode,
      ppvProduct.productcode,
      selectedPayOption,
      localStorage.getItem("deviceid")
    );

    if (data.data.returncode === "0") {
      setmPayGuid(data.data.mpay);
      let _wireCardUrl =
        selectedPayOption === 11
          ? `https://www.wirecard.com.tr/sale-api/tr/step1.aspx?partner=20983&product=${
              ttProduct[ppvProduct.fee]
            }&mpay=${data.data.mpay}`
          : `https://www.wirecard.com.tr/pgate/tr/creditcard/productgateway?productId=${
              ttProduct[ppvProduct.fee]
            }&mpay=${data.data.mpay}`;

      if (process.env.NODE_ENV === "development") {
        _wireCardUrl =
          selectedPayOption === 11
            ? `https://www.wirecard.com.tr/sale-api/tr/step1.aspx?partner=20983&product=11453&mpay=${data.data.mpay}`
            : `https://www.wirecard.com.tr/pgate/tr/creditcard/productgateway?productId=11453&mpay=${data.data.mpay}`;
      }
      setWirecardUrl(_wireCardUrl);
      setShowWirecard(true);
      let intervalTime = userFrameConfig?.Pay_Result_Query_Interval;
      let myIntervalId = setInterval(
        () => {
          getPayResult(data.data.mpay, myIntervalId);
        },
        intervalTime ? intervalTime * 1000 : 5000
      );
      setIntervalId(myIntervalId);
    }
    sendCustomSubscribeReport(
      ppvProduct.productcode,
      ppvProduct.fee,
      program.prevuecode,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const selectPayOption = (option) => {
    setSelectedPayOption(option);
    setShowConfirmPage(true);
  };

  return (
    <Modal viewClass="ppv-modal">
      <div className="ppv-payment-wrapper">
        <div
          className="close-button"
          style={{ top: "-20px", right: "-35px" }}
          onClick={() => closePpv()}
        ></div>
        {showConfirmPage ? (
          <ConfirmPayment
            program={program}
            ppvProduct={ppvProduct}
            notConfirm={() => setShowConfirmPage(false)}
            confirm={() => {
              if (selectedPayOption === 0) {
                billPayment();
              } else {
                doMobileOrCreditCardPayment();
              }
            }}
          />
        ) : !showPin ? (
          <div>
            <div className="payment-confirm-text">
              {localizationStrings.formatString(
                localizationStrings.beforePpvText,
                {
                  content: <span>{program?.prevuename} </span>,
                  price: (
                    <span>{`${
                      ppvProduct?.fee / parseInt(userFrameConfig?.Fee_Rate)
                    } TL`}</span>
                  )
                }
              )}
            </div>
            <div className="payment-options-box">
              <div className="payment-options">
                <div
                  className="payment-option"
                  onClick={() => {
                    setSelectedPayOption(0);
                    setShowPin(true);
                  }}
                >
                  <div className="text">
                    {localizationStrings.payment.option1}
                  </div>
                  <div className="icon">
                    <InvoiceIcon />
                  </div>
                </div>
                <div
                  className="payment-option"
                  onClick={() => selectPayOption(11)}
                >
                  <div className="text">
                    {localizationStrings.payment.option2}
                  </div>
                  <div className="icon">
                    <MobilePhoneIcon />
                  </div>
                </div>
                <div
                  className="payment-option"
                  onClick={() => selectPayOption(16)}
                >
                  <div className="text">
                    {localizationStrings.payment.option3}
                  </div>
                  <div className="icon">
                    <CreditCardIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : !showConfirmPage ? (
          <div className="flex-container">
            <div>
              <div className="payment-text">
                {localizationStrings.profile.enterAdminProfilePassword}
              </div>
              <div className="payment-input-row">
                <input
                  className="profile-pin"
                  type="number"
                  id="ppv-payment-pwd"
                  name="ppvPaymentPwd"
                  className="payment-input"
                  onChange={(e) => setPurchasePassword(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-send-gray-tr btn-abs"
                  onClick={() =>
                    purchasePassword?.length ? checkPinCode() : null
                  }
                >
                  <EnterIcon />
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {showWirecard ? (
        <WireCard
          url={wirecardUrl}
          closeFrame={() => {
            getPayResult(mPayGuid, intervalId);
            clearInterval(intervalId);
            setShowWirecard(false);
          }}
        />
      ) : null}
    </Modal>
  );
};

export default PpvPayment;
