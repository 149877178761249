import React from "react";

const OpenFullScreenIcon=()=> {
  return (<svg xmlns="http://www.w3.org/2000/svg"
    width="1.37em"
    height="1em"
    viewBox="0 0 39.732 28.896"
    > <path id="Full_Screen"
    data-name="Full Screen"
    d="M39.732,28.9H28.9V23.478h5.418V18.06h5.418V28.9Zm-28.9,0H0V18.06H5.418v5.418h5.418V28.9Zm28.9-18.06H34.314V5.418H28.9V0H39.732V10.835Zm-34.314,0H0V0H10.836V5.418H5.418v5.417Z"
    fill="#fff"
    /> </svg>);
}

;

export default OpenFullScreenIcon;