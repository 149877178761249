import React from "react";

const RtukThirteenAgeIcon = () => {
  return (
    <svg
      id="rtük_Yaş"
      data-name="rtük Yaş"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 40 39.999"
    >
      <g id="rtük_Yaş-2" data-name="rtük Yaş">
        <g id="_13_" data-name="13+">
          <path
            id="Path_48"
            data-name="Path 48"
            d="M388.6,1861.6a20,20,0,1,0,20,20,20.039,20.039,0,0,0-20-20Zm-13.41,28.074v-2.491h2.753v-10.172h-2.959v-2.491H380.9v12.663h2.757v2.491Zm10.037.235v-2.764a4.611,4.611,0,0,0,.758.18c.166.021.3.035.388.041s.233.01.428.01a6.97,6.97,0,0,0,3.609-.839,2.443,2.443,0,0,0,1.4-2.108,1.845,1.845,0,0,0-.477-1.31,1.6,1.6,0,0,0-1.234-.511,2.67,2.67,0,0,0-.729.131c-.155.041-.317.093-.49.152s-.374.138-.607.232l-.894-2.094a12.683,12.683,0,0,0,2.833-1.694,2.216,2.216,0,0,0,.928-1.605,1.161,1.161,0,0,0-.385-.889,1.393,1.393,0,0,0-.994-.356,2.711,2.711,0,0,0-1.544.532,5.338,5.338,0,0,0-1.419,1.5l-1.531-2.11a12.429,12.429,0,0,1,1.242-.989,8.093,8.093,0,0,1,1.25-.715,5.94,5.94,0,0,1,2.5-.577,4.011,4.011,0,0,1,2.691.874,2.871,2.871,0,0,1,1.027,2.293,3.234,3.234,0,0,1-.554,1.853,4.962,4.962,0,0,1-1.75,1.489,3.792,3.792,0,0,1,2.313,1.223,3.661,3.661,0,0,1,.807,2.449,5.659,5.659,0,0,1-.108,1.119,4.722,4.722,0,0,1-.351,1.037,4.54,4.54,0,0,1-1.328,1.689,8.021,8.021,0,0,1-2.58,1.435,9.656,9.656,0,0,1-3.092.479c-.1,0-.291-.007-.56-.021s-.421-.017-.461-.017a3.99,3.99,0,0,1-.626-.052c-.215-.031-.371-.056-.46-.069Zm13.143-12.346h2.887v2.695h2.694v2.887h-2.694v2.695h-2.887v-2.695h-2.7v-2.887h2.7v-2.695Zm-9.77-12.629A16.666,16.666,0,1,0,405.27,1881.6a16.7,16.7,0,0,0-16.666-16.666Z"
            transform="translate(-368.604 -1861.605)"
            fill="currentColor"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default RtukThirteenAgeIcon;
