import React, { useState } from "react";

const Modal = ({ children, viewClass, isHide }) => {
  const [open, setOpen] = useState(false);

  setTimeout(() => {
    setOpen(true);
  }, 10);

  return (
    <div
      className={`modal-root ${open ? "modal-open" : null}`}
      style={{ background: isHide ? "transparent" : "" }}
    >
      <div className={`tt-modal ${viewClass}`}>{children}</div>
    </div>
  );
};

export default Modal;
