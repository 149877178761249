import React from "react";

const Namespace = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.659"
      height="12.697"
      viewBox="0 0 5.659 12.697"
    >
      <g
        id="Group_728"
        data-name="Group 728"
        transform="translate(9088.623 -5977.851)"
      >
        <path
          id="Path_253"
          data-name="Path 253"
          d="M18.551,10.04a.99.99,0,1,1-.99-.99A.99.99,0,0,1,18.551,10.04Z"
          transform="translate(-9103.373 5968.801)"
          fill="#fff"
        />
        <path
          id="Path_254"
          data-name="Path 254"
          d="M18.952,21.811H17.537V14.1H15.415a.707.707,0,0,0,0,1.415h.707v6.3H14.707a.707.707,0,1,0,0,1.415h4.244a.707.707,0,0,0,0-1.415Z"
          transform="translate(-9102.622 5967.322)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Namespace;
