import React, { useRef } from "react";
import { NavLink, useParams } from "react-router-dom";

const CategoryItem = ({ item, selectedCategoryId }) => {
  let { menuId } = useParams();
  const elRef = useRef(null);

  return (
    <div>
      <NavLink
        to={{
          pathname: `/dashboard/home/${menuId}/${item?.id}`
        }}
        style={{ textDecoration: "none" }}
      >
        <div
          ref={elRef}
          key={item?.id}
          className={`category-item ${
            selectedCategoryId == item?.id ? "selected" : ""
          }`}
        >
          {item?.name}
        </div>
      </NavLink>
    </div>
  );
};

export default CategoryItem;
