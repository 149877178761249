import React from "react";

const AudioOffIcon = () => {
  return (
    <svg
      id="Group_571"
      data-name="Group 571"
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 0 32.183 28.896"
    >
      <g
        id="Group_323"
        data-name="Group 323"
        transform="translate(13.503 14.448) rotate(-45)"
      >
        <path
          id="Line_7"
          data-name="Line 7"
          d="M14.815,3.356H1.678A1.678,1.678,0,0,1,1.678,0H14.815a1.678,1.678,0,0,1,0,3.356Z"
          transform="translate(0 6.569)"
          fill="#fff"
        />
        <path
          id="Line_8"
          data-name="Line 8"
          d="M1.678,16.493A1.678,1.678,0,0,1,0,14.815V1.678a1.678,1.678,0,0,1,3.356,0V14.815A1.678,1.678,0,0,1,1.678,16.493Z"
          transform="translate(6.569 0)"
          fill="#fff"
        />
      </g>
      <path
        id="Volume"
        d="M4.618,19.161H0V9.734H4.6L13.468,0V28.9Z"
        fill="#fff"
      />
    </svg>
  );
};

export default AudioOffIcon;
