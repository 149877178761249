import React from "react";

const ToBeReacordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 13 13"
    >
      <g id="Group_603" data-name="Group 603" transform="translate(0.5 -34.5)">
        <circle
          id="Oval"
          cx="6"
          cy="6"
          r="6"
          transform="translate(0 35)"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <circle
          id="Oval-2"
          data-name="Oval"
          cx="4"
          cy="4"
          r="4"
          transform="translate(2 37)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default ToBeReacordIcon;
