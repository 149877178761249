import React, { useContext, useState, useEffect } from "react";

import { toggleDislikeById, toggleLikeById } from "../../api/vod/vod";
import DislikeIcon from "../../assets/icons/svg/DislikeIcon";
import LikeIcon from "../../assets/icons/svg/LikeIcon";
import { SessionContext } from "../../context/SessionContext";

const LikeAction = ({ vod }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);

  const { setShowLoginMessage, hasLoggedIn } = useContext(SessionContext);

  useEffect(() => {
    let vodStatus = vod?.interactions;

    vodStatus?.length &&
      vodStatus.forEach((element) => {
        if (element?.type === "VOD_LIKE") {
          setIsLiked(true);
        } else if (element?.type === "VOD_DISLIKE") {
          setIsDisliked(true);
        }
      });
  }, [vod]);

  useEffect(() => {
    if (isLiked && isDisliked) {
      setIsDisliked(false);
    }
  }, [isLiked]);

  useEffect(() => {
    if (isLiked && isDisliked) {
      setIsLiked(false);
    }
  }, [isDisliked]);

  return (
    <div style={{ display: "flex" }}>
      <div
        className={`action-button secondary ${isLiked ? "selected" : ""}`}
        onClick={() => {
          if (hasLoggedIn) {
            setIsLiked((prevState) => !prevState);

            toggleLikeById(vod?.id);
          } else {
            setShowLoginMessage(true);
          }
        }}
      >
        <span className="icon">
          <LikeIcon />
        </span>
      </div>
      <div
        className={`action-button secondary ${isDisliked ? "selected" : ""}`}
        onClick={() => {
          if (hasLoggedIn) {
            setIsDisliked((prevState) => !prevState);

            toggleDislikeById(vod?.id);
          } else {
            setShowLoginMessage(true);
          }
        }}
      >
        <span className="icon">
          <DislikeIcon />
        </span>
      </div>
    </div>
  );
};

export default LikeAction;
