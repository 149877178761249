import LocalizedStrings from "react-localization";

// let lang = localStorage.getItem("language");
// if (!lang) {
//   lang = "tur";
//   localStorage.setItem("language", lang);
// }
const DEFAULT_LANGUAGE = "tur";

let localizationStrings = new LocalizedStrings({
  eng: {
    rentals: "leased",
    illuminationText: "Illumination Text",
    inform: "İnform",
    support: "Support",
    communication: "Communication",
    onePassword: "One Password",
    commetcial: "Commetcial Easy Media Suite",
    movie: "Movies",
    watchTv: "Live TV",
    series: "Series",
    tvGuide: "TV Guide",
    liveTv: "Live TV",
    catchupTv: "CATCHUP TV",
    specialForMe: "My Stuff",
    menuKid: "Kids",
    movieSerie: "Movie/Serie",
    menuRent: "Rent & Purchase",
    rentVod: "Rent",
    buyVod: "Buy",
    addToMyList: "ADD TO MY LIST",
    share: "SHARE",
    removeFromMyList: "Remove from my list",
    removeContent: "Remove content",
    recorded: "Recorded",
    recordSaved: "Recorded",
    toBeRecorded: "To be recorded",
    recordeds: "Recordeds",
    willRecord: "To be recorded",
    delete: "Delete",
    send: "Send",
    deleteAll: "Remove all",
    removeList: "Remove list",
    restartTv: "STARTOVER",
    toLive: "WATCH LIVE",
    recordProgram: "Record",
    nextPrograms: "Next Programs",
    prevPrograms: "Previous Programs",
    director: "Director",
    cast: "Cast",
    watch: "Watch",
    goChannel: "Watch Channel",
    goLink: "Open Link",
    goCategory: "Open Category",
    score: "SCORE",
    relatedVods: "Related Contents",
    season: "Season",
    seasons: "Seasons",
    episode: "Episode",
    episodes: "Episodes",
    filter: "Filter",
    allChannels: "All Channels",
    today: "Today",
    tomorrow: "Tomorrow",
    yesterday: "Yesterday",
    noProgram: "No program info",
    settings: "Easy Media Suite Settings",
    settingsIcon: "Settings",
    downloadIcon: "Downloads",
    accountIcon: "Account Settings",
    packageIcon: "My Package",
    packageOperations: "Package Operations",
    myRecords: "My Records",
    parentalControl: "Parental Control",
    mySuggestion: "I have a suggestion",
    lockChannel: "Channel Locking",
    favAndLock: "Lock Channel / Add to Favorite",
    favChannel: "Favorite Channels",
    favlockChannel: "Favorite Channels and Channel Lock",
    purchaseHistory: "Rent - Purchase History",
    purchaseHistoryy: "Hire - Purchase History",
    rentPurchase: "Rental and Purchase History",
    searchHistory: "Search History",
    historySearch: "Search History",
    menuLanguage: "Menu Language",
    versionAndUser: "Version and User Info",
    suggestionComplaint: "Suggestion / Complaint",
    tivibugo: "Web, Mobile, Tablet and Smart TV with Tivibu Go",
    tivibuHouse: "You can watch Tivibu from anywhere and on any device.",
    tivibuEv: "Unlimited television enjoyment with Tivibu Ev to live",
    tivibuPleasure: "Enjoy Tivibu from your TV whenever you want.",
    goTivibu: "Tivibu Go",
    houseTivibu: "Tivibu Ev",
    mesaage: "Write Your Message",
    logout1: "Exit",
    logout: "Log out",
    logout2: "Log out",
    delete: "Delete",
    userCode: "User Code",
    appVersion: "Application Version",
    networkStatus: "Network Status",
    ipStatus: "IP Status",
    mwAuth: "MW Authentication",
    videoStreamStatus: "Video Stream Availability",
    videoDecodeStatus: "Video Decoding Status",
    edit: "Edit",
    deleteAllHistory: "Delete All History",
    deleteAllPurchaseHistoryText:
      "Are you sure you want to delete all purchase history?",
    yes: "Yes",
    no: "No",
    lockChannelText: "Are you sure you want to lock this channel?",
    favChannelText:
      "Are you sure you want to add this channel to your favorites?",
    deleteRecordText: "Are you sure you want to delete selected records?",
    changeLangText: "Are you sure you want to change application language?",
    logoutText: "Are you sure want to log out?",
    deleteSearchHistoryText: "Are you sure want to delete search history?",
    userRecords: "MY RECORDS",
    records: "All My Records",
    signIn: "Sign In",
    logSignIn: "Log In / Sign In",
    yourPackage: "Package you have",
    pickPackage: "Which Filbox is for you?",
    pickPackageMassage: "Are you ready to meet the colorful world of Filbox?",
    myCurrentPackage: "My Current Package",
    cancelCurrentPackage: "Cancel Current Package",
    programFlow: "Program Flow",
    login: {
      filboxPassword: "Sign In With Filbox",
      title: "The latest movies, series, TV shows and much more",
      subtitle:
        "Are you ready to join the colorful and fun world of Easy Media Suite?",
      easyLogin: "EASY LOGIN",
      new: "New",
      onePasswordLogin: "Login",
      notYetSub: "Not yet a Filbox customer?",
      rememberMe: "Remember Me",
      doneMessage:
        "Your GSM information will be checked and the password will be sent. If you do not receive the password, you can check your information or call 444 5 375.",
      username: "E-Mail / Gsm No",
      password: "Password",
      fastLogin: "Fast Sign In",
      text: "Sign In",
      or: "or",
      beMember: "Are you not Easy Media Suite a member?",
      signUp: "Sign Up",
      guessUp: "Continue as a Guest",
      forgotPassword: "Forgot My Password",
      changePassword: "Change My Password",
      phoneNumberAndEmail: "phone number / e-mail",
      mobilePhoneNumber: "Username",
      validNumberText: "Please enter a valid 10-digit phone number",
      enterText:
        " Enter your phone number or e-mail address to reset your password.",
      enterTextPhone: "Enter your phone number to reset your password",
      confirmText:
        "Your new Password will be sent to your mobile phone number.",
      confirm: "Okay"
    },
    minute: "min",

    profile: {
      addProfile: "Add Profile",
      change: "Change Profile",
      createPassword: "Create Profile Password",
      editPassword: "Update Profile Password",
      save: "Save",
      new: "New Profile",
      add: "Add",
      delete: "Delete Profile",
      text: "Which profile do you want to enter?",
      logging: "Logging in",
      enterProfilePassword:
        "Profile password is required to watch this content.",
      enterProfilePasswordToLock:
        "Profile password is required to lock this content.",
      enterProfilePasswordToUnlock:
        "Profile password is required to unlock this content.",
      enterAdminProfilePassword:
        "Admin profile password is required to watch this content.",
      enterAdminProfilePasswordToLock:
        "Admin profile password is required to lock this content.",
      enterAdminProfilePasswordToUnlock:
        "Admin profile password is required to unlock this content.",
      removedProfile: "This profile is not existing in the system.",
      pinLength: "password length must be 4 digits number"
    },
    cancel: "Cancel",
    enter: "Enter",
    payment: {
      selectPayment: "Choose payment method",
      option1: "My Bill",
      option2: "Mobile Payment",
      option3: "Credit Card",
      option4: "Use Promotion Code",
      rentPassword: "Enter your rental password",
      purchasePassword: "Enter your purchase password",
      rent: "rent",
      purchase: "purchase",
      confirmText:
        "Do you approve to {action} {content} for {day} for {price}?",
      confirmTextBuy: "Do you approve to {action} {content} serie for {price}?",
      confirmTextBundle:
        "Do you approve to {action} {content} serie for {day} for {price}?",
      confirmTextBuyBundle: "Do you approve to {action} {content} for {price}?",
      confirm: "Confirm",
      cancel: "Cancel",
      enterPhoneNumber: "Enter mobile phone number",
      enterSmsCode: "Enter the SMS confirmation code sent to your phone",
      notEnteredSmsCode:
        "You did not enter the SMS confirmation code in the time given to you.",
      newSmsCode: "Request New SMS Verification Code",
      remainingTime: "Remaining time ",
      enterCreditCardInfo: "Enter credit card informations",
      nameOnCard: "Name on card",
      cardNo: "Card No",
      month: "Month",
      year: "Year",
      cvcCode: "CVC Code",
      readPrivacyPolicy: "I Accept The {privacyPolicy}",
      cancelPayment: "Cancel Payment",
      askAdminPassword: "Enter admin profile password",
      enterPromoCode: "Enter promotion code"
    },
    days: "days",
    privacyPolicy: "Privacy Policy",
    allChannels: "All Channels",
    minuteShort: "min",
    quality: "Quality",
    subtitle: "Subtitle",
    dubbing: "Audio",
    off: "Off",
    exitPlayerText: "Are you sure you want to exit the video?",
    channelList: "Channel List",
    myList: "My List",
    continueToWatch: "Continue to Watch",
    recomendedByTT: "Recommended by Easy Media Suite",
    youMaybeLike: "Reccomend for You",
    watchItAgain: "Watch It Again",
    myTvodPurchase: "My Rentals",
    myEstPurchase: "My Purchases",
    newReleased: "New Released",
    haveSeenAll: "You have seen it all",
    stopRecord: "STOP RECORD",
    deleteRecord: "Delete Record",
    toBeRecord: "To Be Record",
    recording: "Recording",
    recordDeleted: "Record Deleted",
    deleteRecordConfirmText: "Are you sure want to delete this record?",
    recordAllSerie:
      "Record this episode and any episodes that will release later",
    recordThisEpisode: "Record only this episode",
    deleteRecordEpisode: "Delete this episode record",
    deleteAllSerie: "Delete the whole series record",
    profilePincode: "Enter pin for {profileName} profile",
    error: {
      tvSubscription: "Your product does not contain this channel"
    },
    watchNowText: "Do you want to watch this content now?",
    watchNow: "Watch",
    watchLater: "Watch Later",
    parentalControlText: {
      general: "General Spectator",
      sevenPlus: "Over 7 Years Old",
      thirteenPlus: "Over 13 Years Old",
      allAge: "Over 18 Years Old",
      changeText:
        "Are you sure want to change parental control for this profile?"
    },
    categories: "Categories",
    kidsProfile: "Kids Profile",
    ppvText: "Do you approve to {action} {content} for {price}?",
    npvrQuota: "Total Time That Can Be Recorded",
    npvrQuotaa: "Recorded total content",
    npvrRemaining: "Remaining recording time",
    total: "You have used %{percentage} of your total quota.",
    expired: "Saved content will be deleted after 90 days.",
    hour: "hour",
    minutes: "minutes",
    lastWatchedChannels: "Last Watched Channels",
    purchaseFilter: "Last {count} {format}",
    live: "Live",
    searchFilters: {
      all: "All",
      movie: "Movie",
      series: "Series",
      program: "Programs",
      cuTv: "CUTV",
      tvChannel: "TV Channels"
    },
    searchResult: "Search Results",
    createProfilePinCodeText: "First you should create a profile password",
    createAdminProfilePinCodeText:
      "First you should create admin profile password",
    createProfilePinCode: "Create Password",
    lockedChannel: "the channel is locked",
    usePromoCode: "Use Promotion Code",
    loginText: "You should sign in to watch this content.",
    loginErrorText: "Your login information is incorrect. Please try again.",
    productSureNotification: "Are you sure you want to cancel your package ?",
    productOkNotification: "Your package has been successfully canceled.",
    outOfProduct:
      "Your package does not contain this content. You can look our other packages which contains this content.",
    cancelPaymentExpired:
      "Payment cancellation failed. Rental cancellation period has expired",
    askCancelPayment: "Do you want to cancel payment?",
    close: "Close",
    continue: "Continue",
    next: "Next",
    startOver: "Start Over",
    bookmarkText: "Do you want to continue?",
    child: "Child",
    normalProfile: "Normal Profile",
    blockAutoDelete: "Block automatically delete",
    allowAutoDelete: "Allow automatically delete",
    npvrHourLimitText:
      "Too much content added to the recording schedule today. The total duration of the recording exceeds {hour} hours. Would you still want to continue?",
    npvrEpisodeLimitText:
      "Too much content added to the recording schedule today. Too many episodes. Would you still want continue?",
    disableButtonParentalText: "Your parental control setting is not allow",
    vodLoginText: "You should sign in to do this process",
    wrongPinText: "Profile password is not correct. Please try again.",
    mesMessage: {
      suspend: "Your account has suspended. We will sign you out",
      removedProfile: "This profile is not active. We will sign you out",
      orderPackage: "Your product has been changed. Please relogin",
      recoverPackage: "Your product is recovered",
      deviceQuota: "New device signed in with this account. You will sign out.",
      userOnlineQuery:
        "There is a active session with this account. If you login, other user will kick out. Do you want to still continue? "
    },
    audioOriginal: "Original",
    bundleContentText: "Do you want to {action} {contentName} serie?",
    incompatibleBrowser:
      "Your browser is not compatible with Easy Media Suite application",
    shouldLogin: "You should login",
    turkish: "Turkish",
    english: "English",
    tvodSaveTime: "This program record does not exist.",
    descriptive: "Descriptive",
    returnCode: "Code",
    message: "Message",
    shortHour: "hrs",
    shortMinutes: "mins",
    shortSeconds: "secs",
    searchChannel: "Search Channel",
    tvSearchResultText: "Found {count} channel results for {term}",
    tvSearchNoResultText: "No channel result found for {term}",
    info: "Information",
    releaseDate: "Release Date",
    seeAll: "See All",
    goLive: "back live",
    inspect: "Inspect",
    inMyList: "In my list",
    shareNow: "Share Now",
    signInNow: "Sign In Now",
    packageUpNow: "Change Package",
    play: "Play",
    duration: "Duration",
    genre: "Genre",
    preview: "Preview",
    profileManagement: "Profile Management",
    closeSession: "Sign Out",
    backSession: "Go Back",
    profileLoginText: "Select the profile you want to login",
    myProfile: "My Profile",
    editProfile: "Edit Profile",
    enterProfilePassword: "Enter Your Profile Password",
    profileName: "Profile Name",
    optional: "optional",
    again: "Again",
    saveProfile: "Save Profile",
    passwordsMatch: "Passwords does not match",
    watch: "Watch",
    playSpeed: "Play Speed",
    contentError:
      "We have some problems to get content. Please try again later.",
    removeProfilePassword: "Remove Profile Password",
    removeProfilePasswordText: "Are you sure to remove this profile password?",
    rentedOnes: "Rented ones",
    noSearchHistory: "There is no search history",
    month: "month",
    lockedChannels: "Locked Channels",
    renteds: "Renteds",
    purchaseds: "Purchaseds",
    noChannelFound: "There is no channel with these filters.",
    noRecords: "There is no record",
    typeMessage: "Write your message",
    suggestion: "Suggestion",
    complaint: "Complaint",
    feedbackResponse:
      "Your demand is recieved to us. Thank you for your feedback.",
    feedbackResponseError:
      "We can not get your demand. Please try again later.",
    askProfilePin: "Please enter profile password.",
    askAdminPin: "Please enter admin profile password.",
    searchSuggestions: "Suggestions",
    noSearchResult: "your {term} returned no result.",
    sugg1: "You can try different keywords",
    sugg2: "Are you looking for movie or serie?",
    sugg3: "You can try movie, serie, program, channel, actor or director name",
    sugg4: "You can try like comedy, love, sport or dram genre",
    record: "Record",
    channelDrmErrorMessage:
      "Channel cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    vodDrmErrorMessage:
      "Content cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    errorCode: "Error Code",
    errorMessage: "Message",
    noNetworkMessage:
      "Try again after checking your internet connections. If the problem persists, you can get support from customer service. (444 5 375)",
    invalidParamError:
      "The requested operation could not be completed. Try again after closing and reopening your application. Then call 444 5 375 if the problem persists.",
    contentNotFoundError:
      "Channel/Content cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    manifestChannelError:
      "Channel cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    manifestVodError:
      "Content cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    videoFormatErrorChannel:
      "Channel cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    videoFormatErrorVod:
      "Content cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    recordOption: "Which option do you want record this content?",
    deleteRecordOption: "Which delete option do you want to use?",
    profileNameWarning: "You should enter profile name",
    autoBitrate: "Auto",
    searchSuggestText: "Year, Director, Actor...",
    informText:
      "Dear User, Your Easy Media Suite service is provided by Net Screen 11 Televizyon and Medya Hiz. AS is offered by As long as your membership continues, you can continue to receive service by maintaining your subscription rights.",
    installationProcess: "How do I upgrade my package?",
    channelCoverage: "Your package does not include this channel.",
    contentControl: "This program has content control.",
    programMonitoring: "How do I watch the program?",
    channelLocking: "You have locked this channel.",
    channelLockingPackage: "Bu içerik paket kapsamı dışındadır.",
    grooving: "How do I unlock a locked channel?",
    packageInstall: {
      packageChange:
        "You can contact our customer service at {phone} or write {watchChannel} and send an SMS to {number} to get information about our packages in which this Channel is included and to request a package change.",
      watchChannel: "WATCH CHANNEL",
      number: "6606",
      phone: "444 5 375",
      control:
        "{adjustment} menüsü altındaki {parentalControl} listesini güncelleyerek bu programı izleyebilirsiniz.",
      adjustment: "Settings",
      parentalControl: "Parental Control",
      channelLock:
        "You can remove the channel lock from the {lockChannel} list under the {adjustment} menu.",
      channelLockPackage:
        "Bu içerik paket kapsamı dışındadır, izlemek için paketinizi değiştirmeniz gerekmektedir",
      channelLockFree:
        "Bu içerik üyelere özeldir, izlemek için üye olmanız gerekmektedir",
      lockChannel: "Lock Channel"
    },
    forceLogoutMessage:
      "Only one device can be logged in at a time. Your session has been terminated due to logging in from a different device.",
    mainLoadingText: "Loading. Please wait...",
    emptyMessageText: "This area can not be empty.",
    rebuildSessionText: "Your session timed out, you should login.",
    playerOptions: {
      track: "Subtitle/Audio",
      quality: "Quality",
      record: "Record",
      info: "Program Info",
      secsToBack: "Rewind 15 secs",
      sectsToForward: "Forward 15 secs",
      play: "Play",
      pause: "Pause",
      audioOff: "Audio Off",
      audioOn: "Audio On",
      restart: "Restart",
      offFullscreen: "Toggle Off Fullscreen",
      onFullscreen: "Toggle On Fullscreen"
    }
  },

  tur: {
    illuminationText: "Aydınlatma Metni",
    inform: "Bilgilendirme",
    support: "Destek",
    communication: "İletişim",
    onePassword: "Tek Şifre",
    commetcial: "Ticari Easy Media Suite",
    watchVod: "SEÇ İZLE",
    movie: "Film",
    series: "Dizi",
    watchTv: "Canlı TV",
    tvGuide: "TV Rehberi",
    liveTv: "Yayın Görünümü",
    menuKid: "Çocuk",
    movieSerie: "Film/Dizi",
    catchupTv: "TEKRAR İZLE",
    specialForMe: "Bana Özel",
    menuRent: "Kirala & Satın Al",
    rentVod: "Kirala",
    buyVod: "Satın Al",
    renteds: "Kiralananlar",
    purchaseds: "Satın Alınanlar",
    addToMyList: "LİSTEME EKLE",
    share: "PAYLAŞ",
    removeFromMyList: "Listemden çıkar",
    removeContent: "İçeriği sil",
    recordeds: "Kayıt Edilenler",
    willRecord: "Kayıt Edilecekler",
    delete: "Sil",
    deleteAll: "Tümünü Sil",
    removeList: "Listeyi temizle",
    restartTv: "BAŞTAN BAŞLAT",
    toLive: "CANLI İZLE",
    recordProgram: "Kaydet",
    stopRecord: "KAYDI DURDUR",
    deleteRecord: "Kaydı Sil ",
    mySuggestion: "Önerim var",
    send: "Gönder",
    nextPrograms: "Sonraki Programlar",
    prevPrograms: "Önceki Programlar",
    director: "Yönetmen",
    cast: "Başroldekiler",
    score: "PUANLA",
    relatedVods: "Benzer İçerikler",
    season: "Sezon",
    seasons: "Sezonlar",
    episode: "Bölüm",
    episodes: "Bölümler",
    today: "Bugün",
    tomorrow: "Yarın",
    yesterday: "Dün",
    noProgram: "Program bilgisi bulunmamaktadır",
    settings: "Easy Media Suite Ayarları",
    settingsIcon: "Ayarlar",
    downloadIcon: "İndirilenler",
    accountIcon: "Hesap Ayarları",
    packageIcon: "Paketim",
    packageOperations: "Paket İşlemleri",
    packages: "Paketler",
    myRecords: "Kayıtlarım",
    parentalControl: "Ebeveyn Kontrolü",
    lockChannel: "Kanal Kilitleme",
    favChannel: "Favori Kanallar",
    favlockChannel: "Favori Kanallar ve Kanal Kilidi",
    purchaseHistory: "Kiralama ve Satın Alma Geçmişi",
    searchHistory: "Arama Geçmişi",
    menuLanguage: "Menü Dili",
    versionAndUser: "Versiyon ve Kullanıcı Bilgileri",
    suggestionComplaint: "Öneri / Şikayet",
    favAndLock: "Kanal Kilitle / Favoriye Ekle",
    allChannels: "Tüm Kanallar",
    filter: "Filtrele",
    logout: "Çıkış",
    logout2: "Çıkış Yap",
    delete: "Sil",
    userCode: "Kullanıcı Kodu",
    toBeRecorded: "Kayıt Edilecek",
    appVersion: "Uygulama Versiyonu",
    networkStatus: "Ağ Durumu",
    ipStatus: "IP Durumu",
    mwAuth: "MW Kimlik Doğrulama",
    videoStreamStatus: "Video Yayın Durumu",
    videoDecodeStatus: "Video Şifre Çözme Durumu",
    suggestionComplaint: "Öneri / Şikayet",
    myCurrentPackage: "Mevcut Paketim",
    cancelCurrentPackage: "Paketi İptal Et",
    edit: "Düzenle",
    deleteAllHistory: "Tüm Geçmişi Sil",
    npvrQuotaa: "Kaydedilen toplam içerik",
    npvrRemaining: "Kalan kaydetme hakkınız",
    expired: "Kaydedilen içerikler 90 gün sonra silinecektir",
    deleteAllPurchaseHistoryText:
      "Tüm geçmiş satınalmaları silmek istediğinize emin misiniz?",
    yes: "Evet",
    no: "Hayır",
    tivibugo: "Tivibu Go ile Web, Cep, Tablet ve Smart TV",
    tivibuHouse: "İstediğiniz yerden, istediğiniz cihazdan Tivibu izleyin",
    tivibuEv: "Tivibu Ev ile sınırsız televizyon keyfini yaşayın",
    tivibuPleasure:
      "İstediğiniz zaman televizyonunuzdan Tivibu keyfini yaşayın.",
    lockChannelText: "Bu kanalı kilitlemek istediğinize emin misiniz?",
    total: "Toplam kota kullanımınız %{percentage}",
    favChannelText:
      "Bu kanalı favorilerinize eklemek istediğinize emin misiniz?",
    deleteRecordText: "Seçili kayıtları silmek istediğinize emin misiniz?",
    changeLangText: "Uygulama dilini değiştirmek istediğinize emin misiniz?",
    logoutText: "Çıkış yapmak istediğinize emin misiniz?",
    deleteSearchHistoryText:
      "Arama geçmişini silmek istediğinize emin misiniz?",
    userRecords: "KAYITLARIM",
    signIn: "Giriş Yap",
    logSignIn: "Giriş yap/Kayıt ol",
    yourPackage: "Sahip Olduğunuz Paket",
    pickPackage: "Hangi Filbox size göre?",
    pickPackageMassage: "Filbox’nun renkli dünyası ile tanışmaya hazır mısın?",
    programFlow: "Program Akışı",
    login: {
      filboxPassword: "Filbox Şifre ile Giriş",
      title: "En yeni filmler, diziler, TV programları ve çok daha fazlası",
      subtitle:
        "Easy Media Suite'in renkli ve eğlenceli dünyasına katılmaya hazır mısınız?",
      easyLogin: "KOLAY GİRİŞ",
      new: "Yeni",
      onePasswordLogin: "Giriş Yap",
      notYetSub: "Filbox müşterisi değil misiniz?",
      rememberMe: "Beni Hatırla",
      doneMessage:
        "GSM bilginiz kontrol edilerek şifre gönderimi sağlanacaktır,şifre ulaşmadığı durumda bilgilerinizi kontrol edebilir ya da 444 5 375'i arayabilirsiniz.",
      username: "E-Posta / Gsm No",
      password: "Şifre",
      fastLogin: "Hızlı Giriş",
      text: "Giriş Yap",
      or: "veya",
      beMember: "Easy Media Suite üyesi değil misin?",
      signUp: "Üye Ol",
      guessUp: "Misafir olarak devam et",
      forgotPassword: "Şifremi Unuttum",
      changePassword: "Şifremi Değiştir",
      phoneNumberAndEmail: "telefon numarası / e-posta",
      mobilePhoneNumber: "Kullanıcı Adı veya E-posta",
      validNumberText: "Lütfen 10 haneli geçerli bir telefon numarası girin",
      enterText: "Şifrenizi yenilemek için e-posta adresinizi girin.",
      enterTextPhone: "Şifrenizi yenilemek için telefon numaranızı girin",
      confirmText: "Yeni Şifreniz cep telefonu numaranıza gönderilecektir.",
      confirm: "Tamam"
    },

    profile: {
      addProfile: "Profil Ekle",
      change: "Profil Değiştir",
      createPassword: "Profil Şifresi Oluştur",
      editPassword: "Profil Şifreni Değiştir",
      save: "Kaydet",
      new: "Yeni Profil",
      add: "Ekle",
      delete: "Profili Sil",
      text: "Hangi profil ile girmek istiyorsun?",
      logging: "Giriş yapılıyor",
      enterProfilePassword:
        "Bu içeriği izleyebilmek için profil şifreniz gerekmektedir.",
      enterProfilePasswordToLock:
        "Bu içeriği kilitleyebilmek için profil şifreniz gerekmektedir.",
      enterProfilePasswordToUnlock:
        "Bu içeriğin kilidini kaldırmak için profil şifreniz gerekmektedir.",
      enterAdminProfilePassword:
        "Bu içeriği izleyebilmek için ana profil şifreniz gerekmektedir.",
      enterAdminProfilePasswordToLock:
        "Bu içeriği kilitleyebilmek için ana profil şifreniz gerekmektedir.",
      enterAdminProfilePasswordToUnlock:
        "Bu içeriğin kilidini kaldırmak için ana profil şifreniz gerekmektedir.",
      removedProfile: "Bu profil sistemde mevcut değildir.",
      pinLength: "Profil şifresi 4 karakter olmalıdır."
    },
    minute: "dk",
    watch: "İzle",
    goChannel: "Kanala Git",
    goLink: "Linke Git",
    goCategory: "Kategoriye Git",
    cancel: "Vazgeç",
    enter: "Giriş",
    payment: {
      selectPayment: "Ödeme yöntemi seçin",
      option1: "Faturama Yansıt",
      option2: "Mobil Ödeme",
      option3: "Kredi Kartı ile",
      option4: "Promosyon Kodu Kullan",
      rentPassword: "Kiralama şifrenizi girin",
      purchasePassword: "Satın alma şifrenizi girin",
      rent: "kiralamayı",
      purchase: "satın almayı",
      confirmText:
        "{price}‘ye {content} içeriğini {day}  süresince {action} onaylıyor musunuz?",
      confirmTextBuy:
        "{price}‘ye {content} içeriğini {action} onaylıyor musunuz?",
      confirmTextBundle:
        "{price}‘ye {content} serisini {day}  süresince {action} onaylıyor musunuz?",
      confirmTextBuyBundle:
        "{price}‘ye {content} serisini {action} onaylıyor musunuz?",
      confirm: "Onaylıyorum",
      cancel: "İptal",
      enterPhoneNumber: "Cep telefon numaranızı girin",

      enterSmsCode: "Telefonunuza gelen SMS onay kodunu girin",
      newSmsCode: "Yeni SMS Onay Kodu İste",
      remainingTime: "Kalan süre ",
      enterCreditCardInfo: "Kredi kartı bilgilerinizi girin",
      nameOnCard: "Kart üzerindeki isim",
      cardNo: "Kart numarası",
      month: "Ay",
      year: "Yıl",
      cvcCode: "CVC Kodu",
      readPrivacyPolicy: "{privacyPolicy}'nı Kabul Ediyorum.",
      notEnteredSmsCode: "Size tanınan sürede SMS onay kodunu girmediniz.",
      cancelPayment: "Ödemeyi İptal Et",
      askAdminPassword: "Ana profil şifrenizi girin",
      enterPromoCode: "Promosyon kodunuzu girin"
    },
    days: "gün",
    privacyPolicy: "Gizlilik Politikası",
    allChannels: "Tüm Kanallar",
    minuteShort: "dk",
    quality: "Kalite",
    subtitle: "Altyazı",
    dubbing: "Seslendirme",
    off: "Kapalı",
    exitPlayerText: "Videodan çıkmak istediğinize emin misiniz?",
    channelList: "Kanal Listesi",
    myList: "Listem",
    continueToWatch: "İzlemeye Devam Et",
    recomendedByTT: "Easy Media Suite'in Önerdikleri",
    youMaybeLike: "Sizin İçin Seçtiklerimiz",
    watchItAgain: "Yeniden İzle",
    myTvodPurchase: "Kiraladıklarım",
    myEstPurchase: "Satın Aldıklarım",
    newReleased: "Yeni Eklenenler",
    haveSeenAll: "Hepsini gördünüz",
    records: "Tüm Kayıtlarım",
    toBeRecord: "Kaydedilecek",
    recording: "Kaydediliyor",
    recordDeleted: "Kayıt Silindi",
    deleteRecordConfirmText: "Kaydı silmek istediğinize emin misiniz?",
    recordAllSerie: "Bu bölümü ve sonra yayınlanacak tüm bölümleri kaydet",
    recordThisEpisode: "Yalnız bu bölümü kaydet",
    deleteRecordEpisode: "Bu bölümün kaydını sil",
    deleteAllSerie: "Bu dizinin tüm kaydını sil",
    profilePincode: "{profileName} profili için şifre girin.",
    error: {
      tvSubscription: "Bu kanal paketinizde bulunmamaktadır"
    },
    watchNowText: "Bu içeriği hemen izlemek ister misiniz?",
    watchNow: "İzle",
    watchLater: "Sonra İzle",
    parentalControlText: {
      general: "Genel İzleyici Kitlesi",
      sevenPlus: "7 Yaş ve Üzeri için",
      thirteenPlus: "13 Yaş ve Üzeri için",
      allAge: "18 Yaş ve Üzeri için",
      changeText:
        "Bu profilin ebeveyn kontrolünü değiştirmek istediğinize emin misiniz?"
    },
    categories: "Kategoriler",
    kidsProfile: "Çocuk Profili",
    ppvText: "{price}‘ye {content} içeriğini {action} onaylıyor musunuz?",
    npvrQuota: "Kaydedilebilecek Toplam Süre",
    hour: "saat",
    minutes: "dakika",
    lastWatchedChannels: "Son İzlenen Kanallar",
    purchaseFilter: "Son {count} {format}",
    live: "Canlı",
    searchFilters: {
      all: "Tümü",
      movie: "Film",
      series: "Dizi",
      program: "Programlar",
      cuTv: "Tekrar İzle",
      tvChannel: "TV Kanalları"
    },
    searchResult: "Arama Sonuçları",
    createProfilePinCodeText: "Önce profil şifresi oluşturmalısın",
    createAdminProfilePinCodeText: "Önce ana profil şifresi oluşturmalısın",
    createProfilePinCode: "Şifre Oluştur",
    lockedChannel: "Kanal kilitli",
    loginText: "Bu içeriği izleyebilmek için giriş yapmalısınız.",
    loginErrorText: "Giriş Bilgileriniz Hatalı. Tekrar Deneyin.",
    productSureNotification:
      "Paketinizi iptal etmek istediğinizden emin misiniz ?",
    productOkNotification: "Paketiniz başarılı bir şekilde iptal edilmiştir",
    outOfProduct:
      "Seçtiğiniz içerik paketinizde yer almamaktadır. Paketlerinize giderek istediğiniz içeriklerin bulunduğu diğer paketlerimize göz atabilirsiniz.",
    cancelPaymentExpired:
      "Ödeme iptali gerçekleştirilemedi. Kiralama iptal süresi dolmuştur.",
    askCancelPayment: "Ödemeyi iptal etmek istediğinize emin misiniz?",
    close: "Kapat",
    continue: "Devam Et",
    next: "Sonraki",
    startOver: "Baştan Başla",
    bookmarkText: "Devam Etmek İster Misin?",
    child: "Çocuk",
    normalProfile: "Normal Profil",
    blockAutoDelete: "Otomatik silmeyi engelle",
    allowAutoDelete: "Otomatik silmeye izin ver",
    npvrHourLimitText:
      "Kayıt listesine çok fazla içerik eklendi. Toplam kayıt süresi {hour} saati aşmaktadır. Devam etmek ister misiniz?",
    npvrEpisodeLimitText:
      "Kayıt listesine çok fazla içerik eklendi. Bölüm sayısı fazladır. Devam etmek ister misiniz?",
    disableButtonParentalText: "Ebeveyn kontrolün izin vermiyor.",
    vodLoginText: "Bu işlemi yapabilmeniz için giriş yapmalısınız",
    wrongPinText: "Profil şifresi doğru değil. Lütfen tekrar deneyiniz.",
    mesMessage: {
      suspend:
        "Hesabınız askıya alındı. Hesabınızdan otomatik çıkış yapılacaktır",
      removedProfile:
        "Bu profil kaldırıldığından tekrardan profil seçme ekranına yönlendirileceksiniz",
      orderPackage:
        "Paketiniz değiştiğinden otomatik olarak çıkış yapılacaktır.",
      recoverPackage: "Your product is recovered",
      deviceQuota:
        "Yeni bir cihazla giriş yapıldı. Otomatik çıkış yapılacaktır.",
      userOnlineQuery:
        "Bu hesapla başka bir aktif oturum bulunmaktadır. Devam etmek isterseniz diğer kullanıcının oturumu sonlanacaktır."
    },
    audioOriginal: "Orijinal",
    bundleContentText: "{contentName} serisini {action} istiyor musunuz?",
    incompatibleBrowser:
      "Tarayıcınız Easy Media Suite uygulamasıyla uyumlu değildir.",
    shouldLogin: "Giriş yapmanız gerekir",
    turkish: "Türkçe",
    english: "İngilizce",
    tvodSaveTime: "Bu programın kaydı bulunmamaktadır.",
    descriptive: "Betimlemeli",
    returnCode: "Hata Kodu",
    message: "Mesaj",
    shortHour: "sa",
    shortMinutes: "dk",
    shortSeconds: "sn",
    searchChannel: "Kanal Ara",
    tvSearchResultText: "{term} ile ilgili {count} kanal sonucu bulundu.",
    tvSearchNoResultText: "{term} ile ilgili kanal sonucu bulunamadı.",
    info: "Bilgiler",
    releaseDate: "Yayın Tarihi",
    recorded: "Kaydedildi",
    recordSaved: "Kayıt Edilenler",
    seeAll: "Tümünü Gör",
    goLive: "canlıya dön",
    inspect: "İncele",
    inMyList: "Listemde",
    shareNow: "Hemen Paylaş",
    signInNow: "Hemen Üye Ol",
    packageUpNow: "Paketi Değiştir",
    play: "Oynat",
    duration: "Süre",
    genre: "Tür",
    preview: "Ön İzleme",
    profileManagement: "Profil Yönetimi",
    closeSession: "Oturumu Kapat",
    backSession: "Geri Dön",
    profileLoginText: "Giriş yapmak istediğiniz profili seçin",
    myProfile: "Hesabım",
    editProfile: "Profili Düzenle",
    enterProfilePassword: "Profil Şifrenizi Giriniz",
    profileName: "Profil Adı",
    optional: "opsiyonel",
    again: "tekrar",
    saveProfile: "Profili Kaydet",
    passwordsMatch: "Şifreler aynı değil",
    watch: "İzle",
    playSpeed: "Oynatma Hızı",
    contentError:
      "İçerik bilgilerini alırken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
    removeProfilePassword: "Profil Şifresini Kaldır",
    removeProfilePasswordText:
      "Profil şifresini kaldırmak istediğinize emin misiniz?",
    noSearchHistory: "Arama geçmişiniz bulunmamaktadır.",
    month: "ay",
    lockedChannels: "Kilitli Kanallar",
    noChannelFound: "Uyguladığınız filtreler ile ilgili kanal bulunamadı.",
    noRecords: "Kayıt bulunmamaktadır.",
    typeMessage: "Mesajınızı yazınız",
    suggestion: "Önerim Var",
    complaint: "Şikayetim Var",
    feedbackResponse:
      "Talebiniz bize ulaşmıştır. Geri bildiriminiz için teşekkür ederiz.",
    feedbackResponseError:
      "Talebinizi alamadık. Lütfen daha sonra tekrar deneyiniz.",
    askProfilePin: "Profil şifrenizi giriniz.",
    askAdminPin: "Ana profil şifrenizi giriniz.",
    searchSuggestions: "Önerilenler",
    noSearchResult: "{term} aramanız herhangi bir sonuç getirmedi.",
    sugg1: "Farklı anahtar sözcükler deneyin",
    sugg2: "Bir filmi veya diziyi mi arıyorsunuz?",
    sugg3: "Film, dizi, program, kanal, oyuncu veya yönetmen adıyla deneyin",
    sugg4: "Komedi, aşk, spor veya drama gibi bir tür deneyin",
    record: "Kayıt",
    errorCode: "Hata kodu",
    errorMessage: "Mesaj",
    channelDrmErrorMessage:
      "Kanal oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    vodDrmErrorMessage:
      "İçerik oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    noNetworkMessage:
      "İnternet bağlantılarınızı kontrol ettikten sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    invalidParamError:
      "İstenen işlem tamamlanamadı. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz.  Problemin devam etmesi halinde 444 5 375'i arayınız.",
    contentNotFoundError:
      "Kanal/İçerik oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    manifestChannelError:
      "Kanal oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    manifestVodError:
      "İçerik oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    videoFormatErrorChannel:
      "Kanal oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    videoFormatErrorVod:
      "İçerik oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    recordOption: "Bu içeriği nasıl kaydetmek istersiniz?",
    deleteRecordOption: "Hangi silme seçeneğini kullanmak istersiniz?",
    profileNameWarning: "Profil ismi girmelisin",
    autoBitrate: "Otomatik",
    searchSuggestText: "Yıl, Yönetmen, Oyuncu...",
    informText:
      "Değerli Kullanıcımız, Easy Media Suite hizmetiniz, Türk Telekom Grup şirketi olan Net Ekran 11 Televizyon ve Medya Hiz. AŞ. tarafından sunulmaktadır. Üyeliğiniz devam ettiği sürece, abonesi olduğunuz paket haklarınızı aynen koruyarak hizmet almaya devam edebilirsiniz.",
    channelCoverage: "Paketiniz bu kanalı kapsamamaktadır.",
    installationProcess: " Paketimi nasıl yükseltirim?",
    contentControl: "Bu programda içerik kontrolü var.",
    programMonitoring: "Programı nasıl izlerim?",
    channelLocking: "Bu kanalı kilitlediniz.",
    channelLockingPackage: "Bu içerik paket kapsamı dışındadır.",
    grooving: "Kilitli kanalı nasıl açarım?",
    packageInstall: {
      packageChange:
        "Bu Kanalın dahil olduğu paketlerimiz ile ilgili bilgi almak ve paket değişikliği talebinde bulunmak için {phone} numaralı müşteri hizmetlerimiz ile iletişime geçebilir ya da {watchChannel} yazıp {number}'ya SMS gönderebilirsiniz.",
      watchChannel: "KANAL İZLE",
      number: "6606",
      phone: "444 5 375",
      control:
        "{adjustment} menüsü altındaki {parentalControl} listesini güncelleyerek bu programı izleyebilirsiniz.",
      adjustment: "Ayarlar",
      parentalControl: "Ebeveyn Kontrolü",
      channelLock:
        "Kanal kilidini, {adjustment} menüsü altındaki {lockChannel} listesinden kaldırabilirsiniz.",
      channelLockPackage:
        "Bu içerik paket kapsamı dışındadır, izlemek için paketinizi değiştirmeniz gerekmektedir",
      channelLockFree:
        "Bu içerik üyelere özeldir, izlemek için üye olmanız gerekmektedir",
      lockChannel: "Kanal Kilitleme"
    },
    forceLogoutMessage:
      "Aynı anda yalnızca bir cihazdan giriş yapılabilmektedir. Farklı bir cihazdan giriş yapıldığından dolayı oturumunuz sonlandırılmıştır.",
    mainLoadingText: "Yükleniyor. Lütfen bekleyiniz...",
    emptyMessageText: "Bu alanı boş bırakmayınız.",
    rebuildSessionText:
      "Oturumunuz zaman aşımına uğramıştır, tekrar giriş yapmanız gerekmektedir.",
    playerOptions: {
      track: "Altyazı/Ses",
      quality: "Kalite",
      record: "Kayıt",
      info: "Program Bilgisi",
      secsToBack: "15 sn geri al",
      sectsToForward: "15 saniye ileri al",
      play: "Oynat",
      pause: "Durdur",
      audioOff: "Sesi Kapat",
      audioOn: "Sesi Aç",
      restart: "Baştan Başlat",
      offFullscreen: "Tam Ekrandan Çık",
      onFullscreen: "Tam Ekran"
    }
  }
});

localizationStrings.setLanguage(DEFAULT_LANGUAGE);

export default localizationStrings;
