import React, { useContext, useRef } from "react";
import defaultCastPoster from "../../assets/images/default-cast-poster.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageView from "../imageView";
import CastNextIcon from "../../assets/icons/svg/CastNextIcon";
// import { SessionContext } from "../../context/SessionContext";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  arrows: false,
  draggable: false,
  lazyLoad: false
};

const CastCarousel = ({ casts, history }) => {
  // const { path } = useContext(SessionContext);
  const slider = useRef(null);

  const renderedActors = casts?.map((cast) => {
    return (
      <div
        key={cast.id}
        onClick={() => history.push(`/dashboard/search/${cast.name}`)}
        className="cast-link"
      >
        <div className="cast-poster-box">
          <div className="cast-poster">
            <ImageView
              src={cast?.posterUrl ?? defaultCastPoster}
              defaultPoster={defaultCastPoster}
              borderRadius="10px"
            />
          </div>
          <div className="name">{cast.name}</div>
        </div>
      </div>
    );
  });

  return (
    <div className="cast-slider-wrapper">
      <div
        className="cast-slider"
        style={{ width: casts?.length > 6 ? "90%" : "100%" }}
      >
        <Slider ref={slider} {...settings}>
          {renderedActors}
        </Slider>
      </div>
      {casts?.length > 6 ? (
        <div className="cast-next" onClick={() => slider.current.slickNext()}>
          <CastNextIcon />
        </div>
      ) : null}
    </div>
  );
};

export default CastCarousel;
