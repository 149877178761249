import React from "react";

const RtukBadBehaviour = () => {
  return (
    <svg
      id="olumsuz_ornek"
      data-name="olumsuz ornek"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 40 40.001"
    >
      <path
        id="Path_56"
        data-name="Path 56"
        d="M456.878,1778.951a20,20,0,1,1-20,20,20.04,20.04,0,0,1,20-20Zm-13.723,16.672H470.6v7.4H443.155v-7.4Z"
        transform="translate(-436.879 -1778.951)"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default RtukBadBehaviour;
