import React from "react";

const RtukViolanceIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5.34303e-05C22.3846 -0.00204025 24.75 0.425416 26.983 1.26202L20.05 7.72405L14.328 3.09197L13.92 9.15398L2.18301 12.298L15.555 13.1721L15.755 8.81304L18.138 13.0361L25.016 4.25103L23.996 12.9679L29.171 11.675L25.903 15.898L31.42 25.092L22.089 21.7551L22.289 27.8851L18.337 24.207L9.212 27.272L15.954 19.9841L1.47402 12.457C2.97889 8.77939 5.54258 5.63199 8.83965 3.41424C12.1367 1.19648 16.0184 0.00847665 19.992 0.00102999L20 5.34303e-05ZM29.129 2.21294C32.0991 3.74794 34.6418 5.99625 36.529 8.75603L25.911 10.6561L29.132 2.216L29.129 2.21294ZM37.529 10.3899C38.9757 13.0178 39.8105 15.9384 39.9712 18.9339C40.1318 21.9294 39.6143 24.9224 38.457 27.69L28.29 16.649L37.529 10.392V10.3899ZM38.422 27.7761C37.0243 31.0939 34.7563 33.9725 31.8576 36.1077C28.9589 38.2429 25.5372 39.5554 21.9542 39.9064C18.3711 40.2575 14.7597 39.6341 11.5017 38.1021C8.24373 36.5701 5.46009 34.1865 3.445 31.2031L17.597 26.249L27.131 32.649L23.793 23.999L38.421 27.774L38.422 27.7761ZM2.78701 30.163C0.141644 25.6996 -0.660071 20.3809 0.552025 15.336L12.152 20.801L2.79 30.163H2.78701Z"
      />
    </svg>
  );
};

export default RtukViolanceIcon;
