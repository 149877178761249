import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import CategoryItem from "../categoryItem";

const CategoryCarousel = ({ items }) => {
  let { categoryId } = useParams();

  const renderedItems = items.map((item) => {
    return (
      <CategoryItem key={item.id} item={item} selectedCategoryId={categoryId} />
    );
  });

  return (
    <div
      style={{
        display: !items.length ? "none" : ""
      }}
    >
      <div className="category-row">
        <div className="category-wrapper">{renderedItems}</div>
      </div>
    </div>
  );
};

export default CategoryCarousel;
