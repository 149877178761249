import React from "react";

const LoginEyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.237"
      height="15.854"
      viewBox="0 0 23.237 15.854"
    >
      <path
        id="eye"
        d="M11.643,2.441a12.016,12.016,0,0,1,7.06,2.2,12.413,12.413,0,0,1,4.533,5.722A12.423,12.423,0,0,1,18.7,16.091a12.013,12.013,0,0,1-7.06,2.2,12.127,12.127,0,0,1-7.085-2.2A12.365,12.365,0,0,1,0,10.368,12.371,12.371,0,0,1,4.558,4.646,12.125,12.125,0,0,1,11.643,2.441Zm0,13.229a5.02,5.02,0,0,0,3.716-1.561,5.145,5.145,0,0,0,1.536-3.74,5.145,5.145,0,0,0-1.536-3.74,5.02,5.02,0,0,0-3.716-1.561A5.1,5.1,0,0,0,7.9,6.628a5.106,5.106,0,0,0-1.561,3.74A5.1,5.1,0,0,0,7.9,14.109,5.1,5.1,0,0,0,11.643,15.67ZM11.619,7.2a3.117,3.117,0,0,1,2.254.917,3.023,3.023,0,0,1,.941,2.254,3.023,3.023,0,0,1-.941,2.254,3.117,3.117,0,0,1-2.254.917,3.035,3.035,0,0,1-2.229-.917,3.066,3.066,0,0,1-.917-2.254A3.13,3.13,0,0,1,11.618,7.2Z"
        transform="translate(0 -2.441)"
        fill="#fff"
      />
    </svg>
  );
};

export default LoginEyeIcon;
