import React from "react";

const RecordedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 8 8"
    >
      <circle id="Oval" cx="4" cy="4" r="4" fill="currentColor" />
    </svg>
  );
};

export default RecordedIcon;
