import React from "react";

const CoveragePackageInstall = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37"
        height="37"
        viewBox="0 0 37 37"
      >
        <g
          id="Group_317"
          data-name="Group 317"
          transform="translate(-15759.5 -10971.42)"
        >
          <g
            id="Group_316"
            data-name="Group 316"
            transform="translate(15761 10972.92)"
          >
            <g id="Group_2" data-name="Group 2">
              <circle
                id="Oval"
                cx="17"
                cy="17"
                r="17"
                transform="translate(0 0)"
                fill="none"
                stroke="#fff"
                strokeWidth="3"
              />
            </g>
            <path
              id="Path_161"
              data-name="Path 161"
              d="M1.537-3.174v-9.592H4.067v9.592ZM2.806-14.1a1.6,1.6,0,0,1-1.087-.357,1.167,1.167,0,0,1-.409-.922,1.163,1.163,0,0,1,.409-.926,1.607,1.607,0,0,1,1.087-.352,1.607,1.607,0,0,1,1.087.352,1.163,1.163,0,0,1,.409.926,1.161,1.161,0,0,1-.413.922A1.6,1.6,0,0,1,2.806-14.1Z"
              transform="translate(13.912 27.185)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default CoveragePackageInstall;
