import React from "react";

const PauseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 0 27.09 28.896"
    >
      <path
        id="Pause"
        d="M16.254,28.9V0H27.09V28.9ZM0,28.9V0H10.836V28.9Z"
        fill="#fff"
      />
    </svg>
  );
};

export default PauseIcon;
