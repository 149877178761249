import React from "react";

const FacebookFooterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      viewBox="0 0 54 54"
    >
      <g id="facebook" transform="translate(1 1)" opacity="0.5">
        <path
          id="base"
          d="M26,0A26,26,0,1,1,0,26,26,26,0,0,1,26,0Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#b783ff"
          strokeWidth="2"
        />
        <path
          id="Facebook-2"
          data-name="Facebook"
          d="M11.7,7.576H7.717V5.148A1.027,1.027,0,0,1,8.824,4.023h2.811V.014L7.764,0c-4.3,0-5.278,2.991-5.278,4.9V7.576H0v4.133H2.485V23.4H7.717V11.709h3.526Z"
          transform="translate(20.8 14.3)"
          fill="#b783ff"
        />
      </g>
    </svg>
  );
};

export default FacebookFooterIcon;
