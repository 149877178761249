import React, { useRef, useEffect } from "react";

const WireCard = ({ url, closeFrame, confirmFrame }) => {
  const hostLocation = window.location.hostname;
  const iframeRef = useRef();

  useEffect(() => {
    const frame = iframeRef.current;
    function loadHandler() {
      frame.contentDocument.addEventListener("click", (e) => {
        if (e.target.id === "uc_cppf_btnCancel") {
          closeFrame();
        }
      });
    }
    frame.addEventListener("load", loadHandler);
    return () => frame.removeEventListener("load", loadHandler);
  }, []);

  return (
    <div className="wirecard-container">
      <div className="close-button black" onClick={() => closeFrame()}></div>
      <iframe
        ref={iframeRef}
        className="wirecard-frame"
        onLoad={(e) => {
          if (
            iframeRef.current.contentWindow.location.href.includes(
              `${hostLocation}`
            )
          ) {
            confirmFrame();
          }
        }}
        src={url}
      ></iframe>
    </div>
  );
};

export default WireCard;
