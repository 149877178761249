import React from "react";

const CoveragePhone = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.5vw"
        height="1.5vw"
        viewBox="0 0 28.058 28.057"
      >
        <path
          id="phone"
          d="M28.057,22.159a6.577,6.577,0,0,1-.2,1.405,7.908,7.908,0,0,1-.419,1.365,5.345,5.345,0,0,1-2.431,2.112A7.726,7.726,0,0,1,21.3,28.057a7.953,7.953,0,0,1-1.056-.069,8.079,8.079,0,0,1-1.146-.249q-.627-.18-.937-.289t-1.106-.408q-.8-.3-.977-.359a18.04,18.04,0,0,1-3.487-1.654,30.043,30.043,0,0,1-5.261-4.3,30.043,30.043,0,0,1-4.3-5.261,18.07,18.07,0,0,1-1.654-3.487Q1.315,11.8,1.016,11T.608,9.894Q.5,9.585.319,8.957A8.259,8.259,0,0,1,.069,7.812,7.771,7.771,0,0,1,0,6.756,7.729,7.729,0,0,1,1.016,3.049,5.345,5.345,0,0,1,3.128.618,7.937,7.937,0,0,1,4.493.2,6.569,6.569,0,0,1,5.9,0a1.112,1.112,0,0,1,.419.06q.359.119,1.056,1.514.219.379.6,1.076t.7,1.266q.319.568.618,1.066.06.079.348.5a5.674,5.674,0,0,1,.429.708,1.287,1.287,0,0,1,.14.568,1.654,1.654,0,0,1-.568,1A9.734,9.734,0,0,1,8.4,8.848,12.025,12.025,0,0,0,7.164,9.9a1.518,1.518,0,0,0-.568.917,1.344,1.344,0,0,0,.1.448q.1.269.169.408t.279.479q.21.339.229.379a20.913,20.913,0,0,0,3.467,4.683,20.913,20.913,0,0,0,4.683,3.467q.04.02.379.229t.479.279a4.184,4.184,0,0,0,.408.169,1.37,1.37,0,0,0,.448.1,1.518,1.518,0,0,0,.917-.568,12.025,12.025,0,0,0,1.056-1.235,9.734,9.734,0,0,1,1.1-1.235,1.654,1.654,0,0,1,1-.568,1.3,1.3,0,0,1,.568.14,5.55,5.55,0,0,1,.708.429q.419.289.5.348.5.3,1.066.618t1.266.7q.7.379,1.076.6,1.4.7,1.514,1.056a1.112,1.112,0,0,1,.06.419Z"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

export default CoveragePhone;
