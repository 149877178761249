import React, { useEffect, useRef, useState } from "react";
import {
  checkNpvrLimit,
  checkNpvrStatus,
  deleteNpvrRecord,
  deleteSerieNpvrRecord,
  startNpvr,
  startSerieNpvr
} from "../../../api/npvr/npvr";
import PlayerRecordIcon from "../../../assets/icons/svg/PlayerRecordIcon";
import localizationStrings from "../../../statics/localization";
import { sendCustomNpvrReport } from "../../../utils/reporting/customReporting";
import ReactDOM from "react-dom";
import PopupCloseIcon from "../../../assets/icons/svg/PopupCloseIcon";
import Notification from "../../notification";

const RecordButton = ({ selectedChannel, programInfo, isFullscreen }) => {
  const [recordStatus, setRecordStatus] = useState(0);
  const [showText, setShowText] = useState("");
  const [showLimitWarning, setShowLimitWarning] = useState(false);
  const [limitMessage, setLimitMessage] = useState(null);
  const timeoutRef = useRef(null);
  const recordOptionEl = useRef(null);
  const limitWarningEl = useRef(null);
  const isSerieProgram = useRef(null);

  const getNpvrStatus = async () => {
    /* const { data } = await checkNpvrStatus(
      programInfo?.prevuecode,
      programInfo?.begintime,
      programInfo?.endtime,
      programInfo?.seriesheadid
    );
    if (data.returncode === "0") {
      setRecordStatus(data.status);
    } else if (data.returncode === "10402503") {
      //have no data
      setRecordStatus(null);
    } */
  };

  const checkProgramType = () => {
    if (programInfo?.seriesheadid === "0") {
      checkRecordLimit();
    } else {
      renderRecordOptions();
    }
  };

  const checkRecordLimit = async (isSerieRecord = false) => {
    isSerieProgram.current = isSerieRecord;
    const { data } = await checkNpvrLimit(
      programInfo?.prevuecode,
      programInfo?.seriesheadid
    );
    if (data.returncode === "0") {
      if (data.islimitnpvr === "0") {
        if (isSerieRecord) {
          startSerieRecord();
        } else {
          startRecord();
        }
      } else if (data.islimitnpvr === "1") {
        setLimitMessage(
          localizationStrings.formatString(
            localizationStrings.npvrHourLimitText,
            { hour: data.limitvalue }
          )
        );
        setShowLimitWarning(true);
      } else if (data.islimitnpvr === "2") {
        setLimitMessage(localizationStrings.npvrEpisodeLimitText);
        setShowLimitWarning(true);
      }
    }
  };

  const startRecord = async () => {
    const data = await startNpvr(
      programInfo?.prevuecode,
      selectedChannel.channelcode
    );
    if (data.data.returncode === "0") {
      getNpvrStatus(programInfo);
      showRecordingText(localizationStrings.recording);
    }
    sendCustomNpvrReport(
      programInfo?.prevuecode,
      selectedChannel.channelcode,
      programInfo?.seriesheadid,
      1,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const startSerieRecord = async () => {
    const data = await startSerieNpvr(
      programInfo?.prevuecode,
      programInfo?.channelcode,
      programInfo?.seriesheadid
    );
    if (data.data.returncode === "0") {
      getNpvrStatus(programInfo, true);
      showRecordingText(localizationStrings.recording);
    }
    sendCustomNpvrReport(
      programInfo?.prevuecode,
      selectedChannel.channelcode,
      programInfo?.seriesheadid,
      1,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const deleteRecord = async (code) => {
    const data = await deleteNpvrRecord(code);
    if (data.data.returncode === "0") {
      getNpvrStatus(programInfo);
      showRecordingText(localizationStrings.recordDeleted);
    }
    sendCustomNpvrReport(
      code,
      selectedChannel.channelcode,
      "",
      0,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const deleteSeriesRecord = async (seriesHeadId) => {
    const data = await deleteSerieNpvrRecord(seriesHeadId);
    if (data.data.returncode === "0") {
      getNpvrStatus(programInfo, true);
      showRecordingText(localizationStrings.recordDeleted);
    }

    sendCustomNpvrReport(
      programInfo.prevuecode,
      selectedChannel.channelcode,
      seriesHeadId,
      0,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const showRecordingText = (text) => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    setShowText(text);
    timeoutRef.current = setTimeout(() => {
      setShowText("");
    }, 2000);
  };

  const renderRecordOptions = () => {
    recordOptionEl.current = document.getElementById("record-option");

    let playerEl = document.getElementById("video-player-true");
    recordOptionEl.current = document.createElement("div");
    recordOptionEl.current.classList.add("record-options-container");
    recordOptionEl.current.setAttribute("id", `record-option`);
    playerEl.appendChild(recordOptionEl.current);

    ReactDOM.render(
      recordStatus === "-2" ? (
        <div className="record-option-wrapper" style={{ zIndex: "14" }}>
          <div className="close-row" style={{ marginTop: "2.4vw" }}>
            <div className="close" onClick={() => unMountRecordOption(false)}>
              <PopupCloseIcon />
            </div>
          </div>
          <div className="record-option-title">
            {localizationStrings.recordOption}
          </div>
          <label
            className="record-option yellow"
            onClick={() => {
              checkRecordLimit(true);
              unMountRecordOption();
            }}
          >
            {localizationStrings.recordAllSerie}
          </label>
          <label
            className="record-option transparent"
            onClick={() => {
              // startRecord(programInfo);
              checkRecordLimit();
              unMountRecordOption();
            }}
          >
            {localizationStrings.recordThisEpisode}
          </label>
        </div>
      ) : (
        <div className="record-option-wrapper" style={{ zIndex: "14" }}>
          <div className="close-row" style={{ marginTop: "2.4vw" }}>
            <div className="close" onClick={() => unMountRecordOption()}>
              <PopupCloseIcon />
            </div>
          </div>
          <label
            className="record-option yellow"
            onClick={() => {
              deleteSeriesRecord(programInfo?.seriesheadid);
              unMountRecordOption();
            }}
          >
            {localizationStrings.deleteAllSerie}
          </label>
          <label
            className="record-option transparent"
            onClick={() => {
              deleteRecord(programInfo?.prevuecode);
              unMountRecordOption();
            }}
          >
            {localizationStrings.deleteRecordEpisode}
          </label>
        </div>
      ),
      document.getElementById(`record-option`)
    );
  };

  const unMountRecordOption = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById(`record-option`));
    recordOptionEl.current.remove();
  };

  const renderLimitWarning = () => {
    limitWarningEl.current = document.getElementById("limit-warning-box");

    let playerEl = document.getElementById("video-player-true");
    limitWarningEl.current = document.createElement("div");
    limitWarningEl.current.classList.add("limit-warning-container");
    limitWarningEl.current.setAttribute("id", `limit-warning-box`);
    playerEl.appendChild(limitWarningEl.current);

    ReactDOM.render(
      <Notification
        text={limitMessage}
        dontUsePortal={true}
        confirmText={localizationStrings.continue}
        notConfirmText={localizationStrings.cancel}
        onClickConfirm={() => {
          if (isSerieProgram.current) {
            startSerieRecord(programInfo);
          } else {
            startRecord(programInfo);
          }
          setShowLimitWarning(false);
        }}
        onClickNotConfirm={() => {
          setShowLimitWarning(false);
        }}
      />,
      document.getElementById(`limit-warning-box`)
    );
  };

  const unMountLimitWarning = () => {
    let element = document.getElementById(`limit-warning-box`);
    if (!!element) {
      ReactDOM.unmountComponentAtNode(
        document.getElementById(`limit-warning-box`)
      );
      limitWarningEl.current.remove();
    }
  };

  useEffect(() => {
    if (programInfo && programInfo?.prevuecode !== 0) {
      getNpvrStatus();
    }
  }, [programInfo]);

  useEffect(() => {
    if (showLimitWarning) {
      renderLimitWarning();
    } else {
      unMountLimitWarning();
    }
  }, [showLimitWarning]);

  return (
    <button
      className={`control-button ${
        !recordStatus
          ? "hide"
          : recordStatus === "999"
          ? "button-red"
          : recordStatus === "1"
          ? "button-orange"
          : recordStatus === "0"
          ? "button-blue"
          : "button-white"
      }`}
      title={localizationStrings.playerOptions.record}
      style={{
        cursor: localStorage.getItem("usertype") === "0" ? "not-allowed" : "",
        marginLeft: isFullscreen ? "1.2vw" : null
      }}
      onClick={() => {
        if (localStorage.getItem("usertype") === "1") {
          if (recordStatus !== "999" && recordStatus !== "1") {
            checkProgramType(programInfo);
          } else {
            deleteRecord(programInfo?.prevuecode);
          }
        }
      }}
    >
      <PlayerRecordIcon />
      {showText ? <div className="record-text">{showText}</div> : null}

      {showLimitWarning ? (
        <Notification
          text={limitMessage}
          confirmText={localizationStrings.continue}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={() => {
            if (isSerieProgram.current) {
              startSerieRecord(programInfo);
            } else {
              startRecord(programInfo);
            }
            setShowLimitWarning(false);
          }}
          onClickNotConfirm={() => {
            setShowLimitWarning(false);
          }}
        />
      ) : null}
    </button>
  );
};

export default RecordButton;
