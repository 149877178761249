import React from "react";

const ProfileEditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 28.799 28.8"
    >
      <g
        id="Group_808"
        data-name="Group 808"
        transform="translate(-13.286 -12.326)"
      >
        <path
          id="Fill_3"
          data-name="Fill 3"
          d="M.72,28.8a.719.719,0,0,1-.707-.854l1.526-8.067v-.017c0-.017.008-.035.013-.052a.163.163,0,0,1,.006-.02c0-.015.01-.03.015-.043l0-.007a.077.077,0,0,1,.005-.013.575.575,0,0,1,.025-.053l.013-.022.019-.032L1.66,19.6l.023-.03.015-.02c.013-.015.026-.03.04-.043l0,0L16.127,5.114,20.489.752a2.57,2.57,0,0,1,3.634,0l3.925,3.925a2.574,2.574,0,0,1,0,3.635L26.3,10.057l-17,17,0,0c-.013.014-.028.027-.043.04a.166.166,0,0,1-.02.015.256.256,0,0,1-.03.022l-.023.016-.031.018-.023.014a.573.573,0,0,1-.053.025l-.014.006-.007,0-.041.015-.011,0-.011,0-.049.013H8.921L.854,28.787A.761.761,0,0,1,.72,28.8Zm2.092-7.9-1.188,6.28L7.9,25.988A7.584,7.584,0,0,0,2.812,20.9h0ZM16.636,6.641h0L3.629,19.648a8.983,8.983,0,0,1,3.388,2.136,8.969,8.969,0,0,1,2.136,3.388L22.159,12.164,16.636,6.641Zm2.616-2.616h0l-1.6,1.6,5.523,5.523,1.6-1.6L19.253,4.025ZM22.306,1.44a1.123,1.123,0,0,0-.8.331L20.271,3.006l5.523,5.523,1.235-1.236a1.131,1.131,0,0,0,0-1.6L23.1,1.771A1.126,1.126,0,0,0,22.306,1.44Z"
          transform="translate(13.286 12.326)"
          fill="currentColor"
        />
        <path
          id="Fill_5"
          data-name="Fill 5"
          d="M9.95.211a.72.72,0,0,0-1.018,0L.211,8.932A.72.72,0,1,0,1.229,9.95L9.95,1.229a.72.72,0,0,0,0-1.018"
          transform="translate(21.388 22.863)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default ProfileEditIcon;
