export const reportingEvent = {
  E_MEDIA: "E_MEDIA",
  E_MEDIA_INIT: "E_MEDIA_INIT",
  E_MEDIA_PLAY: "E_MEDIA_PLAY",
  E_MEDIA_PAUSE: "E_MEDIA_PAUSE",
  E_MEDIA_SEEK: "E_MEDIA_SEEK",
  E_MEDIA_FORWARD: "E_MEDIA_FORWARD",
  E_MEDIA_REWIND: "E_MEDIA_REWIND",
  E_MEDIA_BUFFER: "E_MEDIA_BUFFER",
  E_MEDIA_BG: "E_MEDIA_BG",
  E_MEDIA_SWITCHBIT: "E_MEDIA_SWITCHBIT",
  E_MEDIA_SWITCH: "E_MEDIA_SWITCH ",
  E_MEDIA_ERROR: "E_MEDIA_ERROR",
  E_MEDIA_ONPLAYING: "E_MEDIA_ONPLAYING",
  E_MEDIA_END: "E_MEDIA_END",
  E_ERROR: "E_ERROR",
  E_ERROR_INTERFACE: "E_ERROR_INTERFACE",
  E_ERROR_CRASH: "E_ERROR_CRASH",
  E_LOGIN_IN: "E_LOGIN_IN",
  E_LOGIN_OUT: "E_LOGIN_OUT",
  E_ALIVE: "E_ALIVE"
};

export const reportingPageEvent = {
  //Entrance
  E_PAGE_MENUETV: "E_PAGE_MENUETV",
  E_PAGE_MENUETVGUIDE: "E_PAGE_MENUETVGUIDE",
  E_PAGE_MENUESEARCH: "E_PAGE_MENUESEARCH",
  E_PAGE_MENUESETTING: "E_PAGE_MENUESETTING",
  E_PAGE_MENUPROFILE: "E_PAGE_MENUPROFILE",
  E_PAGE_MUNUSETTING_PACKAGE: "E_PAGE_MUNUSETTING_PACKAGE",
  E_PAGE_MUNUSETTING_PURCHASEHISTORY: "E_PAGE_MUNUSETTING_PURCHASEHISTORY",
  E_PAGE_MUNUSETTING_LANGUAGE: "E_PAGE_MUNUSETTING_LANGUAGE",
  E_PAGE_MUNUSETTING_PARENTCONTROL: "E_PAGE_MUNUSETTING_PARENTCONTROL",
  E_PAGE_MUNUSETTING_CHANNELFAVORATE: "E_PAGE_MUNUSETTING_CHANNELFAVORATE",
  E_PAGE_MUNUSETTING_VERSION: "E_PAGE_MUNUSETTING_VERSION ",
  E_PAGE_MUNUSETTING_NPVR: "E_PAGE_MUNUSETTING_NPVR",
  E_PAGE_MENUESPECIALTOME: "E_PAGE_MENUESPECIALTOME",
  E_PAGE_MENUESERIES: "E_PAGE_MENUESERIES",
  E_PAGE_MUNUPURCHASE: "E_PAGE_MUNUPURCHASE",
  E_PAGE_MUNUCHILDREN: "E_PAGE_MUNUCHILDREN",
  E_PAGE_MENUEVOD: "E_PAGE_MENUEVOD",
  E_PAGE_SEARCHFROMNAVBAR: "E_PAGE_SEARCHFROMNAVBAR",
  E_PAGE_SEARCHFROMDETAIL: "E_PAGE_SEARCHFROMDETAIL",

  //pre-page of vod detail

  E_PAGE_VODDETAILFROM_SEARCH: "E_PAGE_VODDETAILFROM_SEARCH ",
  E_PAGE_VODDETAILFROM_VOD: "E_PAGE_VODDETAILFROM_VOD",
  E_PAGE_VODDETAILFROM_SPECIALTOME: "E_PAGE_VODDETAILFROM_SPECIALTOME",
  E_PAGE_PREVUDETAILFROM_CHANNEL: "E_PAGE_PREVUDETAILFROM_CHANNEL",

  E_PAGE_VODRECOMMENDFROM_BANNER: "E_PAGE_VODRECOMMENDFROM_BANNER",
  E_PAGE_RECOMMENDFROM_VODDETAIL: "E_PAGE_RECOMMENDFROM_VODDETAIL",
  E_PAGE_RECOMMENDFROM_VODCOLUMNE: "E_PAGE_RECOMMENDFROM_VODCOLUMNE",

  //General Page
  E_PAGE_TV: "E_PAGE_TV",
  E_PAGE_TVGUIDE: "E_PAGE_TVGUIDE",
  E_PAGE_VOD: "E_PAGE_VOD",
  E_PAGE_VODDETAIL: "E_PAGE_VODDETAIL",
  E_PAGE_PREVUEDETAIL: "E_PAGE_PREVUEDETAIL",

  E_PAGE_PLAY: "E_PAGE_PLAY",
  E_PAGE_SEARCH: "E_PAGE_SEARCH",
  E_PAGE_PROFILE: "E_PAGE_PROFILE",
  E_PAGE_PACKAGE: "E_PAGE_PACKAGE",
  E_PAGE_PURCHASEHISTORY: "E_PAGE_PURCHASEHISTORY",

  E_PAGE_LANGUAGESETTING: "E_PAGE_LANGUAGESETTING",
  E_PAGE_SWITCHUSERLEVEL: "E_PAGE_SWITCHUSERLEVEL",
  E_PAGE_CHANNELLOCK: "E_PAGE_CHANNELLOCK",
  E_PAGE_CHANNELFAVORATE: "E_PAGE_CHANNELFAVORATE",
  E_PAGE_VERSION: "E_PAGE_VERSION",

  E_PAGE_NPVR: "E_PAGE_NPVR",
  E_PAGE_NPVRDETAIL: "E_PAGE_NPVRDETAIL",
  E_PAGE_PAY: "E_PAGE_PAY",
  E_PAGE_SPECIALTOME: "E_PAGE_SPECIALTOME",
  E_PAGE_SERIES: "E_PAGE_SERIES",
  E_PAGE_CHILDREN: "E_PAGE_CHILDREN"
};

export const reportingCustomEvent = {
  E_CUSTOM_SEARCH: "E_CUSTOM_SEARCH",
  E_CUSTOM_FAVORITE: "E_CUSTOM_FAVORITE",
  E_CUSTOM_BOOKMARK: "E_CUSTOM_BOOKMARK",
  E_CUSTOM_NPVR: "E_CUSTOM_NPVR",
  E_CUSTOM_RATING: "E_CUSTOM_RATING",
  E_CUSTOM_PROFILE: "E_CUSTOM_PROFILE",
  E_CUSTOM_SUBSCRIBE: "E_CUSTOM_SUBSCRIBE"
};
