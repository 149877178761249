import React from "react";

const PopupCloseIcon = () => {
  return (
    <svg
      id="Actions_Navigation_close_24"
      data-name="Actions / Navigation / close / 24"
      xmlns="http://www.w3.org/2000/svg"
      width="15.863"
      height="15.863"
      viewBox="0 0 15.863 15.863"
    >
      <path
        id="Fill"
        d="M15.863,1.388,14.475,0,7.931,6.543,1.388,0,0,1.388,6.543,7.931,0,14.475l1.388,1.388L7.931,9.319l6.543,6.543,1.388-1.388L9.319,7.931Z"
        fill="#fff"
      />
    </svg>
  );
};

export default PopupCloseIcon;
