import React from "react";
import CoveragePackageInstall from "../../assets/icons/svg/CoveragePackageInstall";
import RtukIcon from "../../assets/icons/svg/RtukIcon";
import localizationStrings from "../../statics/localization";

const ChannelControl = () => {
  return (
    <div className="coverage">
      <div className="channel-coverage">
        {localizationStrings.contentControl}
      </div>
      <div className="coverage-phone">
        <div className="rtuk">
          <RtukIcon />
        </div>
      </div>
      <div className="package-install">
        <CoveragePackageInstall />
        &nbsp;
        <div style={{ marginBottom: "0.5vw" }}>
          {localizationStrings.programMonitoring}
        </div>
      </div>
      <div className="package-control">
        {localizationStrings.formatString(
          localizationStrings.packageInstall.control,
          {
            adjustment: (
              <span style={{ color: "#00deff" }}>
                {localizationStrings.packageInstall.adjustment}
              </span>
            ),
            parentalControl: (
              <span style={{ color: "#00deff" }}>
                {localizationStrings.packageInstall.parentalControl}
              </span>
            )
          }
        )}
      </div>
    </div>
  );
};

export default ChannelControl;
