let pinCode = "";
let i = 0;
let sI = 0;
export const firefoxPinSecret = (keyEvent = false, mouseEvent = false) => {
  if (mouseEvent) {
    i = mouseEvent.target?.selectionEnd;
    sI = mouseEvent.target?.selectionStart;
    if (mouseEvent.target?.textLength == 0) {
      pinCode = "";
      i = 0;
    }
    return [pinCode, i];
  }
  if (keyEvent) {
    i = keyEvent.target?.selectionEnd;
    sI = keyEvent.target?.selectionStart;
    if (keyEvent.target?.textLength == 0) {
      pinCode = "";
      i = 0;
    }
  }
  if (keyEvent.key == "ArrowLeft" && pinCode.length >= i && i > 0) {
    i = i - 1;
    return [pinCode, i];
  } else if (keyEvent.key == "ArrowRight" && pinCode.length > i && i >= 0) {
    i = i + 1;
    return [pinCode, i];
  } else if (keyEvent.key == "ArrowUp") {
    i = 0;
    return [pinCode, i];
  } else if (keyEvent.key == "ArrowDown") {
    i = keyEvent.target?.textLength;
    return [pinCode, i];
  } else if (keyEvent.key == " ") {
    return [pinCode, i];
  } else if (keyEvent.key == "Backspace" && i != 0) {
    if (i === sI) {
      pinCode =
        pinCode.substring(0, i - 1) + pinCode.substring(i, pinCode.length);
      i = i - 1;
      return [pinCode, i];
    }
    pinCode = pinCode.substring(0, sI) + pinCode.substring(i, pinCode.length);
    i = sI;
    return [pinCode, i];
  } else if (keyEvent.key == "Delete") {
    if (i === sI) {
      pinCode =
        pinCode.substring(0, i) + pinCode.substring(i + 1, pinCode.length);
      return [pinCode, i];
    }
    pinCode = pinCode.substring(0, sI) + pinCode.substring(i, pinCode.length);
    i = sI;
    return [pinCode, i];
  } else if (pinCode.length < 4 || i != sI) {
    keyEvent.key = keyEvent.key.replace(/\D/g, "");
    if (i === sI) {
      pinCode =
        pinCode.substring(0, i) +
        keyEvent.key +
        pinCode.substring(i, pinCode.length);
      i = i + 1;
      return [pinCode, i];
    }
    pinCode =
      pinCode.substring(0, sI) +
      keyEvent.key +
      pinCode.substring(i, pinCode.length);
    i = sI + 1;
  }
  return [pinCode, i];
};

export default firefoxPinSecret;
