import React from "react";

const FavoriteIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 17.262 17.262"
    >
      <g id="plus" transform="translate(0 0)">
        <rect
          id="rectangle"
          width="17.262"
          height="1.918"
          rx="0.959"
          transform="translate(0 7.672)"
          fill="currentColor"
        />
        <rect
          id="rectangle-2"
          data-name="rectangle"
          width="17.262"
          height="1.918"
          rx="0.959"
          transform="translate(7.672 17.262) rotate(-90)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default FavoriteIcon;
