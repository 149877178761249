import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import ChannelDetail from "../../components/channelDetail";
import ProgramInfo from "../../components/programInfo";
import { SessionContext } from "../../context/SessionContext";
import ModalRouting from "../../components/modalRouting/ModalRouting";
import { useHistory, useParams } from "react-router-dom";
import ChannelListWidget from "../../components/channelListWidget/ChannelListWidget";
import { getChannels, toggleLockById } from "../../api/ltv/ltv";
import ChannelListItem from "../../components/channelListItem";
import localizationStrings from "../../statics/localization";
import ArrowRightPurple from "../../assets/icons/svg/ArrowRightPurple";
import { ltvEvent } from "../../api/event/event";

const LiveTv = () => {
  const [genres, setGenres] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [term, setTerm] = useState("");
  const [itemCount, setItemCount] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [isTimeshiftEnable, setIsTimeshiftEnable] = useState(null);

  const {
    selectedChannel,
    setSelectedChannel,
    userFrameConfig,
    setPath,
    isChangeLockChannel,
    setSelectedCutvProgram,
    isChildProfile,
    setIsChangeLockChannel,
    currentProgramTimes,
    setCurrentProgramTimes,
    adminProfile,
    profile,
    selectedGenre,
    setSelectedGenre,
    channelItems,
    setChannelItems,
    setSelectedMenuId
  } = useContext(SessionContext);
  const history = useHistory();
  const myTvChannelProgram = useRef(null);
  const intervalId = useRef(null);
  const analytInterval = useRef(null);
  const filterTimeoutId = useRef(null);
  const inputRef = useRef(null);
  const allChannels = useRef([]);

  let { channelNo } = useParams();

  useEffect(() => {
    const filteredChannels = allChannels.current.filter(
      (channel) =>
        channel.genre === selectedGenre || selectedGenre === "Tüm Kanallar"
    );
    filteredChannels.sort((a, b) => a.priority - b.priority);
    setChannelItems(filteredChannels);
  }, [selectedGenre]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSelectedMenuId("livetv");
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (channelNo && channelItems?.length) {
      setElapsedTime(0);
      const pickedChannel = channelItems.find(
        (channel) => channel?.id == channelNo
      );

      setSelectedChannel(pickedChannel);
    }
  }, [channelNo, channelItems]);

  useEffect(() => {
    analytInterval.current = setInterval(() => {
      setElapsedTime(prevElapsedTime => prevElapsedTime + 60);
    }, 60000);
    return () => {
      clearInterval(analytInterval.current)};
  }, []);

  useEffect(() => {
    if (elapsedTime > 0 && elapsedTime % 60 === 0) {
      ltvEvent(channelNo, elapsedTime);
    }
  }, [elapsedTime]);


  const channelSelection = (item, isRefresh = false) => {
    history.push(`/dashboard/livetv/${item?.id}`);
    if (isRefresh) {
      setSelectedChannel((prevState) => {
        let newObj = {};
        Object.assign(newObj, prevState);
        return newObj;
      });
    } else {
      setSelectedChannel(item);
    }
    setPath(`/dashboard/livetv/${item?.id}`);
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  };

  const getChannelItems = async (fromChange) => {
    const data = await getChannels();

    /* console.log({ data }); */

    let channels = data.data;
    channels = channels.sort((a, b) => {
      return a?.id - b?.id;
    });
    channels = channels.sort((a, b) => {
      return parseInt(a?.no) - parseInt(b?.no);
    });

    let allGenres = Array.from(
      channels.reduce((acc, cur) => {
        acc.add(cur.genre);
        return acc;
      }, new Set(["Tüm Kanallar"]))
    );

    setGenres(allGenres);
    setSelectedGenre(allGenres[0]);

    channels.sort((a, b) => a.priority - b.priority);
    allChannels.current = channels;
    setChannelItems(channels);

    if (!!channelNo && channelNo !== "all" && channelNo !== "first") {
      let item = channels.find((el) => el?.channelcode === channelNo);
      if (
        item &&
        (item?.channelcode !== selectedChannel?.channelcode || fromChange)
      )
        channelSelection(item);
    } else {
      channelSelection(channels[0]);
    }
  };

  useEffect(() => {
    getChannelItems();
  }, []);

  return (
    <div className="container">
      <div
        className="filter-row"
        style={{ marginBottom: isSearchOpen && itemCount ? "15px" : "" }}
      >
        <div className="filter-box">
          <div
            className={`tv-filter ${isFilterOpen ? "selected" : ""}`}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            <div className="label-text">{selectedGenre}</div>
            <div
              className="filter-icon"
              style={{
                transform: isFilterOpen ? "rotate(0deg)" : "rotate(180deg)",
                marginInline: "30px"
              }}
            >
              <ArrowRightPurple />
              <ArrowRightPurple />
            </div>
          </div>
        </div>
      </div>
      <div className={`channel-container ${isFilterOpen ? "extend" : ""}`}>
        <div className={`channel-list-column ${isFilterOpen ? "extend" : ""}`}>
          <ChannelListWidget
            key="channellist-1"
            uKey="channellist-1"
            channels={channelItems}
            genres={genres}
            selectedGenre={selectedGenre}
            setSelectedGenre={(val) => {
              setSelectedGenre(val);
              setTerm("");
              setIsSearchOpen(false);
              setItemCount(null);
            }}
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            onSelectChannel={channelSelection}
            isChangeLockChannel={isChangeLockChannel}
            setSelectedCutvProgram={() => {
              /* setSelectedCutvProgram(null); */
            }}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            adminProfile={adminProfile}
            profile={profile}
            userFrameConfig={userFrameConfig}
            setLockChannelCode={(channelId, isLocked) => {
              if (channelId) {
                setChannelItems((prevState) => {
                  return prevState.map((item) => {
                    if (item.id === channelId) {
                      // Check if 'locked' is true, add 'CHANNEL_LOCK', otherwise remove it
                      if (isLocked) {
                        item.interactions = [
                          ...item.interactions,
                          "CHANNEL_LOCK"
                        ];
                      } else {
                        item.interactions = item.interactions.filter(
                          (interaction) => interaction !== "CHANNEL_LOCK"
                        );
                      }
                    }
                    setSelectedChannel({ ...item });
                    return item;
                  });
                });
              }
            }}
          />
        </div>
        <div
          className={`channel-detail-column ${isFilterOpen ? "extend" : ""}`}
        >
          <div className="detail-wrapper">
            <div className="channel-detail-box">
              <ChannelDetail
                selectedChannel={selectedChannel}
                channels={channelItems}
                genres={genres}
                selectedGenre={selectedGenre}
                setSelectedGenre={(val) => {
                  setSelectedGenre(val);
                  setTerm("");
                  setIsFilterOpen(false);
                }}
                searchFilter={(val) => {
                  if (val.length) {
                    setTerm(val);
                    setIsSearchOpen(true);
                  } else {
                    setIsSearchOpen(false);
                    setTerm("");
                  }
                }}
                setLockChannelCode={(channelId, isLocked) => {
                  console.log("livetv setlock");
                  setChannelItems((prevState) => {
                    return prevState.map((item) => {
                      if (item.id === channelId) {
                        // Check if 'locked' is true, add 'CHANNEL_LOCK', otherwise remove it
                        if (isLocked) {
                          item.interactions = [
                            ...item.interactions,
                            "CHANNEL_LOCK"
                          ];
                        } else {
                          item.interactions = item.interactions.filter(
                            (interaction) => interaction !== "CHANNEL_LOCK"
                          );
                        }
                      }
                      setSelectedChannel({ ...item });
                      return item;
                    });
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalRouting />
    </div>
  );
};

export default LiveTv;
