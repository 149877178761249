import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import Mainboard from "../../components/mainboard";
import { SessionContext } from "../../context/SessionContext";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Carousel from "../../components/carousel";
import CategoryCarousel from "../../components/categoryCarousel";
import AdComponent from "../../components/adComponent";
import ModalRouting from "../../components/modalRouting/ModalRouting";
import Record from "../record/Record";
import { ChannelCarousel } from "../../components/channelCarousel/ChannelCarousel";
import {
  childMenuId,
  getIsFromPip,
  setIsFromPip,
  specialForMeId
} from "../../statics/config";
import InfiniteScroll from "react-infinite-scroll-component";
import useQuery from "../../utils/useQuery";
import MiniVodDetail from "../../components/miniVodDetail/MiniVodDetail";
import MiniProgramDetail from "../../components/miniProgramDetail/MiniProgramDetail";
import {
  getCategoriesById,
  getLabelsById
} from "../../api/category/categoryApi";
import localizationStrings from "../../statics/localization";

const Home = () => {
  const [labels, setLabels] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bannerPosition, setBannerPosition] = useState(null);
  const [recordRefreshCounter, setRecordRefreshCounter] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showMiniVodDetail, setShowMiniVodDetail] = useState(false);
  const [showMiniProgramDetail, setShowMiniProgramDetail] = useState(false);
  const [mainBoardVods, setMainBoardVods] = useState(null);
  const [lastWatchedChannelsId, setLastWatchedChannelsId] = useState(null);

  const { setPath, setSelectedMenuId, profile } = useContext(SessionContext);
  const allVodColumns = useRef([]);
  const columnCounter = useRef(1);
  const history = useHistory();
  let { menuId, categoryId } = useParams();
  let { url } = useRouteMatch();
  let isFromPip = getIsFromPip();
  const previewData = useRef({});
  const isLeftRef = useRef(null);
  const isRightRef = useRef(null);
  const programPreviewData = useRef({});

  useEffect(() => {
    labels.forEach((label) => {
      if (label?.name === localizationStrings.lastWatchedChannels) {
        setLastWatchedChannelsId(label?.id);
      }
    });
  }, [labels]);

  const getLabels = async (id) => {
    const { data } = await getLabelsById(id);
    if (!!data?.labels) {
      allVodColumns.current = data.labels;
      // setLabels(allVodColumns.current.slice(0, 5));
      setLabels(data.labels);
    }
  };

  const getCategories = async (id) => {
    const { data } = await getCategoriesById(id);
    if (!!data?.categories) {
      setCategories(data?.categories);
      setMainBoardVods(data?.banners);
      if (categoryId === "all") {
        history.push(`/dashboard/home/${menuId}/${data?.categories[0]?.id}`);
      }
    }
  };

  useEffect(() => {
    if (!!menuId) {
      setCategories([]);
      window.scrollTo(0, 0);
      setSelectedMenuId(menuId);
      getCategories(menuId);
      setPath(url);
      //findBannerPosition(menuId);
    }
    return () => {
      setSelectedMenuId(null);
    };
  }, [menuId]);

  useEffect(() => {
    if (!!categoryId) {
      setLabels([]);
      allVodColumns.current = [];
      if (categoryId !== "all" && categoryId != "undefined") {
        columnCounter.current = 1;
        getLabels(categoryId);
        setPath(url);
      } else if (!!categories?.length) {
        history.push(`/dashboard/home/${menuId}/${categoryId}`);
      }
    }
  }, [categoryId]);

  useEffect(() => {
    if (isFromPip) {
      let obj = {};
      obj.record = isFromPip;
      obj.isPlay = true;
      setSelectedRecord(obj);
      setIsFromPip(null);
    }
  }, [isFromPip]);

  const [scrollState, setScrollState] = useState("top");
  const [scrollFirstState, setScrollFirstState] = useState(true);
  const [adComponentTop, setAdComponentTop] = useState(0);
  const adComponentRef = useRef(null);

  useEffect(() => {
    setScrollFirstState(true);
  }, [url]);

  useLayoutEffect(() => {
    const adComponent = adComponentRef.current;
    if (adComponent) {
      const navbarTopPosition = adComponent.getBoundingClientRect().top;
      setAdComponentTop(navbarTopPosition);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = document.scrollingElement.scrollTop;

      if (scrolled >= adComponentTop + 10 && scrollFirstState) {
        if (scrollState !== "amir" && scrollState !== "cooldown") {
          setScrollState("amir");
          setTimeout(() => {
            setScrollFirstState(false);
            setScrollState("cooldown");
          }, 6000);
        }
      } else if (scrolled === 0) {
        if (scrollState !== "top") {
          setScrollState("top");
        }
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrollState, adComponentTop, scrollFirstState]);

  const renderedCarouselList = labels?.map((item) => {
    return (
      <div key={item.id}>
        <div>
          {item.id === "userrecords" ? (
            <Record
              onHoverProgramItem={(isShow, data) => {
                if (isShow) {
                  programPreviewData.current = data;
                }
                setShowMiniProgramDetail(isShow);
              }}
              recordRefreshCounter={recordRefreshCounter}
              selectedRecordItem={selectedRecord}
              setSelectedRecordItem={(val) => setSelectedRecord(val)}
            />
          ) : (
            <Carousel
              label={item}
              onHoverItem={(isShow, data, isLeft, isRight) => {
                if (isShow) {
                  previewData.current = data;
                  isLeftRef.current = isLeft;
                  isRightRef.current = isRight;
                }
                setShowMiniVodDetail(isShow);
              }}
            />
          )}
        </div>
      </div>
    );
  });

  let isMain = categories[0]?.isMain;
  let hasGradient = profile?.kid || +menuId === childMenuId;

  return (
    <div>
      <div ref={adComponentRef} className={`ad-component ${scrollState}`}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <AdComponent adId="div-gpt-ad-1716922867281-0" style={true} />
        </div>
      </div>
      {scrollState === "amir" && (
        <div style={{ height: adComponentRef.current.offsetHeight }}></div> // Placeholder div to prevent content shift
      )}
      <Mainboard
        bannerPosition={bannerPosition}
        mainBoardVods={mainBoardVods}
      />
      <div className="main">
        <div style={{ position: "relative" }}>
          <AdComponent adId="div-gpt-ad-1716923158063-0" />
        </div>
        <div className="sub-main">
          {hasGradient ? <div className="child-gradient"></div> : null}

          {!isMain && <CategoryCarousel items={categories} />}

          {+menuId === specialForMeId && (
            <div>
              <ChannelCarousel labelId={lastWatchedChannelsId} />
            </div>
          )}

          <InfiniteScroll
            dataLength={renderedCarouselList.length}
            //next={setRenderedColumn}
            hasMore={true}
            className={`${+menuId !== specialForMeId ? "main-scroller" : ""}`}
          >
            {renderedCarouselList}
          </InfiniteScroll>
        </div>
        <div style={{ position: "relative" }}>
          <AdComponent adId="div-gpt-ad-1716923191262-0" />
        </div>
      </div>
      <ModalRouting />
      {/* {showBannerAd ? (
        <div
          className={
            showBannerAd ? "advertisement-box show" : "advertisement-box hide"
          }
        >
          <BannerAd
            key="home-banner-ad"
            bannerId="home-banner-ad"
            configShowAd={userFrameConfig?.showGoogleAD === "1"}
            userInfo={userInfo}
            bannerConfig={homeBannerAdConfig}
            setShowBannerAd={(val) => {
              setShowBannerAd(val);
            }}
            isHome={true}
            menuId={menuId}
          />
        </div>
      ) : null} */}

      {showMiniVodDetail ? (
        <MiniVodDetail
          vodData={previewData.current}
          closeModal={() => {
            setShowMiniVodDetail(false);
          }}
          isLeft={isLeftRef.current}
          isRight={isRightRef.current}
        />
      ) : null}

      {showMiniProgramDetail ? (
        <MiniProgramDetail
          programData={programPreviewData.current}
          closeModal={() => {
            setShowMiniProgramDetail(false);
          }}
          setRecordRefresh={(val) => {
            if (val) {
              let cnt = recordRefreshCounter;
              setRecordRefreshCounter(++cnt);
            }
          }}
          onSelectRecord={(item, isPlay) => {
            let obj = {};
            obj.record = item;
            obj.isPlay = isPlay;
            setSelectedRecord(obj);
          }}
        />
      ) : null}
    </div>
  );
};

export default Home;
