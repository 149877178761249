import React from "react";

const RtukSexualIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 41 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.111 6.10352e-05C26.8272 0.404 30.3567 1.83854 33.3008 4.14191C36.245 6.44527 38.4868 9.52575 39.7732 13.0356C41.0596 16.5454 41.3393 20.3449 40.5809 24.0053C39.8225 27.6657 38.056 31.0413 35.481 33.751L33.542 31.431L36.23 29.17L33.53 25.945L30.844 28.204L29.009 26.004C30.047 24.6275 30.7145 23.0077 30.9476 21.2995C31.1806 19.5913 30.9714 17.8518 30.34 16.2476C29.7085 14.6434 28.676 13.2281 27.3411 12.137C26.0063 11.046 24.4138 10.3157 22.716 10.0161V5.09406L25.811 8.04901L28.535 5.17804L23.118 0.00506592L23.111 6.10352e-05ZM32.371 36.424C29.0363 38.7539 25.066 40.0024 20.998 40.0001C15.9121 39.9853 11.0217 38.0392 7.31613 34.5556C3.6106 31.072 1.36659 26.3108 1.03815 21.2355C0.709706 16.1602 2.32133 11.1496 5.54697 7.21747C8.7726 3.28531 13.3714 0.725162 18.413 0.0549927L13.053 5.17303L15.781 8.04901L18.529 5.42401V10.186C16.1542 10.7941 14.0826 12.2473 12.7023 14.2733C11.322 16.2992 10.7277 18.7589 11.0307 21.1916C11.3338 23.6242 12.5134 25.8629 14.3485 27.4882C16.1837 29.1136 18.5486 30.0141 21 30.0211C22.7294 30.0238 24.4296 29.5757 25.933 28.721L27.71 30.8491L25.022 33.1091L27.722 36.336L30.408 34.076L32.374 36.43L32.371 36.424ZM25.861 23.731L23.861 25.412C22.9793 25.8789 21.9966 26.1223 20.999 26.121C19.6027 26.1248 18.2463 25.6552 17.1512 24.789C16.056 23.9228 15.2867 22.711 14.9688 21.3514C14.6508 19.9918 14.803 18.5646 15.4004 17.3026C15.9978 16.0405 17.0053 15.0182 18.2584 14.4023C19.5116 13.7864 20.9364 13.6133 22.3005 13.9113C23.6647 14.2093 24.8876 14.9608 25.7698 16.0432C26.6519 17.1255 27.1413 18.4747 27.1581 19.8709C27.1748 21.2671 26.718 22.6278 25.862 23.731H25.861Z"
      />
    </svg>
  );
};

export default RtukSexualIcon;
