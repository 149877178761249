import React, { useContext, useEffect, useRef, useState } from "react";
import IconRender from "../../components/iconRender";
import { NavLink, useHistory, Link } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";
import { defaultProfileAvatar } from "../../statics/config";
import useOutsideClick from "../../utils/useOutsideClick";
import SearchIcon from "../../assets/icons/svg/SearchIcon";
import Notification from "../notification";
import { getMenuItems } from "../../api/category/categoryApi";
import { logout } from "../../api/login/loginApi";

const Navbar = () => {
  const [posterUrl, setPosterUrl] = useState("");
  const [navbarElements, setNavbarElements] = useState([]);
  const [scrollState, setScrollState] = useState("top");
  const [showOptions, setShowOptions] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [isLogout, setIsLogout] = useState(false);
  const { userFrameConfig, allProfiles } = useContext(SessionContext);
  const settingsRef = useRef(null);
  const history = useHistory(null);

  useOutsideClick(settingsRef, () => {
    setShowOptions(false);
  });

  let listener = null;
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  let loggedIn = JSON.parse(localStorage.getItem("hasLoggedIn"));
  let free = localStorage.getItem("logintype");
  //console.log("logcontrol",loggedIn);

  const clickNotConfirm = () => {
    //onaylamayı tıklayın
    setShowNotification(false);
  };

  const clickedLogout = () => {
    //tıklandı çıkış
    setNotificationText(localizationStrings.logoutText);
    setShowNotification(true);
  };

  const renderedIcons = navbarElements?.map((el) => {
    return <IconRender key={el.id} text={el.name} link={el.id} align={true} />;
  });

  const renderedProfiles = allProfiles?.map((item) => {
    return item?.id != currentProfile?.id ? (
      <NavbarProfileItem key={item?.id} item={item} />
    ) : null;
  });

  const navigateHome = () => {
    if (localStorage.getItem("usertype") === "0") {
      // let lang = localStorage.getItem("language");
      // let deviceid = localStorage.getItem("deviceid");
      // let softLoginUrl = localStorage.getItem("loginurl");
      // let loginUrl = `${softLoginUrl}?lan=${
      //   lang ? lang : "tur"
      // }&deviceid=${deviceid}`;
      // window.location.replace(loginUrl);
      history.push(`/dashboard/home/${userFrameConfig?.Video_Column}`);
    } else if (localStorage.getItem("usertype") === "1") {
      history.push(`/dashboard/home/${userFrameConfig?.Special_For_Me_Column}`);
    }
  };

  const getNavbarItems = async () => {
    const { data } = await getMenuItems();
    let free = localStorage.getItem("logintype");
    if (free === "free") {
      data.splice(0, 1);
    }

    data.push({ id: "livetv", name: "Canlı TV" });
    setNavbarElements(data);
  };

  const onLogout = async () => {
    const { data } = await logout();
    localStorage.setItem("hasLoggedIn", false);
    localStorage.removeItem("userToken");
    localStorage.removeItem("profileToken");
    history.push("/login");
  };

  const [navbarTop, setNavbarTop] = useState(0);
  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      const navbarTopPosition = navbar.getBoundingClientRect().top;
      setNavbarTop(navbarTopPosition);
    }
  }, []);

  useEffect(() => {
    listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= (navbarTop+10)) {
        if (scrollState !== "amir") {
          setScrollState("amir");
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top");
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);

  useEffect(() => {
    getNavbarItems();
  }, []);

  // ${
  //   !document.getElementsByClassName("aswift_1_host")[0]
  //     ? "placeholder"
  //     : ""
  // }

  return (
    <nav className={`navbar ${scrollState} `}>
      <div className="navbar-row">
        <div className="menu-item-wrapper">
          <div className="main-logo" onClick={() => navigateHome()}>
            <img
              alt="main logo"
              src={require("../../assets/logo/filbox-logo.png")}
            ></img>
          </div>
          {renderedIcons}
        </div>
        {/* <div className="buttons-wrapper">
          <a className="logout" onClick={() => onLogout()}>
            Çıkış Yap
          </a>
        </div> */}
        {loggedIn && free !== "free" ? (
          <div
            className="profile-item-nav"
            onClick={() => history.push("/selectprofile")}
          >
            <img src={currentProfile?.posterimage} />
          </div>
        ) : (
          <div className="buttons-wrapper">
            <a className="login" onClick={() => history.push("/login")}>
             {localizationStrings.logSignIn}
            </a>
          </div>
        )}
      </div>
      {showNotification ? (
        <Notification
          text={notificationText}
          confirmText={localizationStrings.yes}
          notConfirmText={localizationStrings.no}
          // onClickConfirm={}
          onClickNotConfirm={clickNotConfirm}
        />
      ) : null}
    </nav>
  );
};

const NavbarProfileItem = ({ item }) => {
  const [profileAvatar, setProfileAvatar] = useState(null);

  useEffect(() => {
    if (item) {
      if (item.kid) {
        item.photo = "kids" + item.posterName;
      }
      setProfileAvatar(
        `${process.env.PUBLIC_URL}/profilePosters/${item?.posterName}.png`
      );
    }
  }, [item]);

  return (
    <NavLink
      to={{
        pathname: `/profiles/${item.id}`
      }}
      style={{ textDecoration: "none" }}
    >
      <div className="profile-option-item">
        <div className="profile-img-wrapper">
          <img
            src={profileAvatar}
            alt="profile avatar"
            onError={() => setProfileAvatar(defaultProfileAvatar)}
          />
        </div>
        <div className="profile-name">
          {decodeURIComponent(decodeURIComponent(item?.name))}
        </div>
      </div>
    </NavLink>
  );
};

export default Navbar;
