import React from "react";

const RtukSevenAgeIcon = () => {
  return (
    <svg
      id="rtük_Yaş"
      data-name="rtük Yaş"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
    >
      <g id="Component_179_1" data-name="Component 179 – 1">
        <path
          id="Path_58"
          data-name="Path 58"
          d="M315.886,1861.6a20,20,0,1,0,20,20,20.038,20.038,0,0,0-20-20Zm-5.839,30.757c.341-1.261.723-2.527,1.136-3.793s.9-2.61,1.455-4.02q1.583-4.095,3.79-8.7h-8.106v-3.422h12.525v1.419a86.2,86.2,0,0,0-3.777,9.147,75.894,75.894,0,0,0-2.577,9.366Zm14.536-15.1h3.109v2.9h2.9v3.108h-2.9v2.9h-3.109v-2.9h-2.9v-3.108h2.9v-2.9Zm-8.7-12.321a16.667,16.667,0,1,0,16.666,16.666,16.7,16.7,0,0,0-16.666-16.666Z"
          transform="translate(-295.885 -1861.605)"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default RtukSevenAgeIcon;
