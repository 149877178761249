import React, { useEffect, useState } from "react";

const ImageView = ({ src, defaultPoster, borderRadius }) => {
  const [posterUrl, setPosterUrl] = useState(src);

  useEffect(() => {
    setPosterUrl(src);
  }, [src]);

  return (
    <img
      src={posterUrl}
      onError={() => setPosterUrl(defaultPoster)}
      style={{
        width: "100%",
        height: "auto",
        borderRadius: borderRadius ? borderRadius : ""
      }}
    />
  );
};

export default ImageView;
