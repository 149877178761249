import React from "react";

const PackageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86.076"
      height="78.26"
      viewBox="0 0 86.076 78.26"
    >
      <g
        id="Group_84"
        data-name="Group 84"
        transform="translate(-2074.282 -699.267)"
        opacity="0.13"
      >
        <path
          id="Path_28"
          data-name="Path 28"
          d="M2096.9,711.062h2.623q27.3,0,54.608,0a19.515,19.515,0,0,1,2.526.082,3.97,3.97,0,0,1,3.593,4.94c-1.719,7.743-3.438,15.487-5.3,23.2a19.375,19.375,0,0,1-18.8,14.7q-11.592.051-23.185-.1c-8.583-.119-16.348-6.85-18.264-15.908s-3.946-18.1-5.932-27.152c-.568-2.587-1.7-3.527-4.39-3.588-1.9-.043-3.8.06-5.692-.044-2.887-.159-4.53-1.83-4.4-4.308.119-2.2,1.82-3.531,4.618-3.534a45.879,45.879,0,0,1,7.785.171c5.149.892,8.193,4.274,9.57,9.233C2096.466,709.487,2096.665,710.223,2096.9,711.062Z"
          fill="#fff"
        />
        <path
          id="Path_29"
          data-name="Path 29"
          d="M2145.361,855.751a7.81,7.81,0,0,1-15.619-.108,7.81,7.81,0,1,1,15.619.108Z"
          transform="translate(-32.068 -85.891)"
          fill="#fff"
        />
        <path
          id="Path_30"
          data-name="Path 30"
          d="M2230.384,863.417a7.716,7.716,0,0,1-7.749-7.761,7.8,7.8,0,1,1,15.608.087A7.764,7.764,0,0,1,2230.384,863.417Z"
          transform="translate(-85.782 -85.891)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default PackageIcon;
