import React from "react";

const Icon = () => {
  return (
    <svg
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="12"
      viewBox="0 0 22 12"
    >
      <rect id="Rectangle" width="22" height="2" rx="1" fill="currentColor" />
      <rect
        id="Rectangle-2"
        data-name="Rectangle"
        width="15"
        height="2"
        rx="1"
        transform="translate(7 5)"
        fill="currentColor"
      />
      <rect
        id="Rectangle-3"
        data-name="Rectangle"
        width="8"
        height="2"
        rx="1"
        transform="translate(14 10)"
        fill="currentColor"
      />
    </svg>
  );
};

export default Icon;
