import React from "react";

const EpisodesIcon = () => {
  return (
    <svg
      id="Actions_Controls_pause_24"
      data-name="Actions / Controls / pause / 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
    >
      <path
        id="Fill"
        d="M13.5,22.5H3A1.5,1.5,0,0,1,1.5,21V10.5A1.5,1.5,0,0,1,3,9H13.5A1.5,1.5,0,0,1,15,10.5V21A1.5,1.5,0,0,1,13.5,22.5ZM3,10.5V21H13.5V10.5ZM18.75,17.25h-1.5V6.75H6.75V5.25h10.5a1.5,1.5,0,0,1,1.5,1.5v10.5ZM22.5,12H21V3H12V1.5h9A1.5,1.5,0,0,1,22.5,3v9Z"
        transform="translate(-1.5 -1.5)"
        fill="currentColor"
      />
    </svg>
  );
};

export default EpisodesIcon;
