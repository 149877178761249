import * as mock from "../mockup/mockup";
import axios from "axios";

const getPackageList = async () => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiUrl}/sdk_getuserorderlist.jsp`, {
    params: {
      producttype: 0
    }
  });
};

const getProductDetails = async (codes) => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiUrl}/sdk_getupdateproduct.jsp`, {
    params: {
      productcode: codes
    }
  });
};

const getUserVersionInfo = async () => {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(mock.userVersion);
    }, 400);
  });
};

export { getUserVersionInfo, getPackageList, getProductDetails };
