import axios from "axios";
import moment from "moment";

const addFavorite = async (favoriteType, contentCode, channelName) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_addfavorite.jsp`, {
    params: {
      favoritetype: favoriteType,
      contentcode: contentCode,
      favoritename: channelName
    }
  });
};

const deleteFavorite = async (
  favoriteType,
  contentCode,
  columnCode,
  channelName
) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_delfavorite.jsp`, {
    params: {
      favoritetype: favoriteType,
      contentcode: contentCode,
      columncode: columnCode,
      favoritename: channelName
    }
  });
};

const getRelatedVods = async (programCode, contentCode, filterColumnCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiEndpoint}/sdk_getuserlikedata.jsp`, {
    params: {
      algorithm: 1,
      contenttype: "1,14",
      programcode: programCode,
      contentcode: contentCode,
      requestnum: 10,
      bocode: "001",
      filtercolumncode: filterColumnCode
    }
  });
};

const getUserMaybeLikeData = async (columnCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getuserlikedata.jsp`, {
    params: {
      algorithm: 4,
      contenttype: "1,14",
      filtercolumncode: columnCode,
      seriestype: "1,3",
      requestnum: 20
    }
  });
};

const getSearchReccomend = async (columnCode) => {
  let apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiEndpoint}/sdk_getstaticrecommend.jsp`, {
    params: {
      algorithm: 1,
      requestnum: 40,
      pageno: 1,
      numperpage: 40,
      bocode: "001"
    }
  });
};

const addLockToChannel = async (contentCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/addlock.jsp`, {
    params: {
      limittype: 5,
      contentcode: contentCode
    }
  });
};

const deleteChannelLock = async (contentCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/dellock.jsp`, {
    params: {
      limittype: 5,
      contentcode: contentCode
    }
  });
};

const getAllThumbnail = async (cmsid, pContentId, playType,programBegin, programEnd) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getallthumbnails.jsp`, {
    params: {
      cmsid: cmsid,
      physicalcontentid: pContentId,
      playtype: playType,
      programbegin: encodeURIComponent(programBegin),
      programend: encodeURIComponent(programEnd) 
    }
  });
};

const getProgramAndNpvrThumbnail = async (
  cmsid,
  channelCode,
  beginTime,
  endTime,
  fromRecord = false
) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");

  let params = {};
  if (fromRecord) {
    params = {
      cmsid: cmsid,
      channelcode: channelCode,
      playtype: 3,
      programbegin: beginTime + " 00",
      programend: endTime + " 00"
    };
  } else {
    params = {
      cmsid: cmsid,
      physicalcontentid: channelCode,
      playtype: 2,
      programbegin: beginTime + " 00",
      programend: endTime + " 00"
    };
  }
  return await axios.get(`${apiEndpoint}/sdk_getallthumbnails.jsp`, {
    params: params
  });
};

const addBookmark = async (
  bookmarkType,
  contentCode,
  columnCode,
  breakpoint,
  duration
) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.post(
    `${apiEndpoint}/sdk_addbookmark.jsp`,
    `bookmarktype=${bookmarkType}&contentcode=${contentCode}&columncode=${columnCode}&breakpoint=${breakpoint}&bookmarknamedesc=${duration}`
  );
};

const deleteBookmarkContent = async (bookmarkType, contentCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiEndpoint}/sdk_delbookmark.jsp`, {
    params: {
      bookmarktype: bookmarkType,
      contentcode: contentCode
    }
  });
};

const getBookmarkOfContent = async (bookmarkType, contentCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_checkisbookmarked.jsp`, {
    params: {
      bookmarktype: bookmarkType,
      contentcode: contentCode
    }
  });
};

const setCookieApi = async (key, value) => {
  /* var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_setcookie.jsp`, {
    params: {
      key: key,
      value: value
    }
  }); */
};

const sendHeartbeatApiCall = async () => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  apiEndpoint = apiEndpoint.slice(0, apiEndpoint.lastIndexOf("/"));
  return await axios.get(`${apiEndpoint}/datasource/heartbeat.jsp`, {
    params: {
      timestamp: moment().unix()
    }
  });
};

export {
  addFavorite,
  getRelatedVods,
  deleteFavorite,
  addLockToChannel,
  deleteChannelLock,
  getAllThumbnail,
  getUserMaybeLikeData,
  addBookmark,
  getProgramAndNpvrThumbnail,
  getBookmarkOfContent,
  setCookieApi,
  sendHeartbeatApiCall,
  getSearchReccomend,
  deleteBookmarkContent
};
