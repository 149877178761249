import React, { useRef, useState } from "react";
import FacebookIcon from "../../assets/icons/svg/FacebookIcon";
import InstagramIcon from "../../assets/icons/svg/InstagramIcon";
import LinkedinIcon from "../../assets/icons/svg/LinkedinIcon";
import ShareIcon from "../../assets/icons/svg/ShareIcon";
import TwitterIcon from "../../assets/icons/svg/TwitterIcon";
import YayIcon from "../../assets/icons/svg/YayIcon";
import localizationStrings from "../../statics/localization";
import VisibilitySensor from "react-visibility-sensor";

const ShareAction = ({ vod, isPlayer }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const isVisibleRef = useRef(false);

  return (
    <div
      className={`action-button secondary ${
        showShareOptions ? "selected" : ""
      }`}
      onClick={() => {
        setShowShareOptions(!showShareOptions);
        if (showShareOptions) {
          setTimeout(() => {
            setIsRight(!isVisibleRef.current);
          }, 300);
        } else {
          setIsRight(!isVisibleRef.current);
        }
      }}
    >
      <span className="icon">
        <ShareIcon />
      </span>
      <VisibilitySensor>
        {({ isVisible }) => {
          isVisibleRef.current = isVisible;
          return (
            <div
              className={`custom-tooltip ${
                isRight ? "tooltip-right" : "tooltip-left"
              } ${showShareOptions ? "open" : ""}  ${
                isPlayer ? "player-tooltip" : ""
              }`}
            >
              {!isPlayer ? (
                <div className="tooltip-text">
                  {localizationStrings.shareNow}
                </div>
              ) : null}
              <div className="tooltip-button">
                <span className="tooltip-icon">
                  <FacebookIcon />
                </span>
              </div>
              <div className="tooltip-button">
                <span className="tooltip-icon">
                  <TwitterIcon />
                </span>
              </div>
              <div className="tooltip-button">
                <span className="tooltip-icon">
                  <InstagramIcon />
                </span>
              </div>
              <div className="tooltip-button">
                <span className="tooltip-icon">
                  <LinkedinIcon />
                </span>
              </div>
              <div className="tooltip-button">
                <span className="tooltip-icon">
                  <YayIcon />
                </span>
              </div>
            </div>
          );
        }}
      </VisibilitySensor>
    </div>
  );
};

export default ShareAction;
