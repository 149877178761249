import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { callVerifyToken, resetPassword } from "../../api/login/loginApi";
import localizationStrings from "../../statics/localization";
import useQuery from "../../utils/useQuery";
import LoginWrapper from "../loginWrapper";
import mainLoadingGif from "../../assets/loading.gif";
import ShowPasswordIcon from "../../assets/icons/svg/ShowPasswordIcon";
import HidePasswordIcon from "../../assets/icons/svg/HidePasswordIcon";
import successLogo from "../../assets/images/success.png";
import errorLogo from "../../assets/images/error.png";
import { useHistory } from "react-router-dom";

const ResetPassword = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isShownPassword, setIsShownPassword] = useState(false);
  const [showButtonLoading, setShowButtonLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [logo, setLogo] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const { emptyMessageText } = localizationStrings;
  const { password } = localizationStrings.login;

  const registerOptions = {
    email: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 3,
        message: "E-posta giriniz."
      }
    },
    password: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 8,
        message: "En az 8 karakter giriniz."
      }
    },
    confirmPassword: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 8,
        message: "En az 8 karakter giriniz."
      },
      validate: (val) => {
        if (watch("password") != val) {
          return "Şifreler eşleşmiyor";
        }
      }
    }
  };

  let content = null;
  let query = useQuery();
  let token = query.get("token");

  const verifyToken = async (token) => {
    const { data } = await callVerifyToken(token);

    setTokenVerified(data == 0);
    setIsLoading(false);
  };

  const handlePasswords = async (_data) => {
    setShowButtonLoading((prevState) => !prevState);
    const { data } = await resetPassword(token, _data.password);
    console.log("data:", data);
    if (data == 0) {
      setResponseMessage(
        "Şifreniz başarılı bir şekilde değiştirilmiştir. Yeni şifrenizle giriş yapabilirsiniz."
      );
      setLogo(successLogo);
    } else {
      setResponseMessage(
        "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."
      );
      setLogo(errorLogo);
    }
    setIsSubmitted((prevState) => !prevState);
    setShowButtonLoading((prevState) => !prevState);
  };

  const handleTogglePassword = () => {
    setIsShownPassword((prevState) => !prevState);
  };

  const handleError = (errors) => {};

  useEffect(() => {
    if (token) {
      verifyToken(token);
    }
  }, [token]);

  if (isLoading) {
    content = <div style={{ color: "white" }}>Yükleniyor...</div>;
  } else if (isSubmitted || !tokenVerified) {
    content = (
      <LoginWrapper>
        <div className="done-section">
          <img
            src={logo}
            alt="icon"
            style={{ maxWidth: "200px", height: "auto" }}
          />
          <p className="done-message">{responseMessage}</p>
          <button
            className="continue-button"
            onClick={() => history.push("/login")}
          >
            Giriş Yap
          </button>
        </div>
      </LoginWrapper>
    );
  } else if (tokenVerified) {
    content = (
      <LoginWrapper>
        <p className="login-title" style={{ color: "white" }}>
          Şifre Sıfırlama
        </p>

        <div className="password-section">
          <input
            className="input-password"
            name="password"
            type={isShownPassword ? "text" : "password"}
            placeholder={`${password}`}
            {...register("password", registerOptions.password)}
            style={errors.password ? { border: "2px solid red" } : {}}
          />
          <div className="input-error">
            <p> {errors?.password && errors.password.message}</p>
          </div>
          <span className="eye-icon" onClick={handleTogglePassword}>
            {isShownPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
          </span>
        </div>

        <div className="password-section">
          <input
            className="input-password"
            name="confirmPassword"
            type={isShownPassword ? "text" : "password"}
            placeholder={`Şifre tekrar`}
            {...register("confirmPassword", registerOptions.confirmPassword)}
            style={errors.confirmPassword ? { border: "2px solid red" } : {}}
          />
          <div className="input-error">
            <p> {errors?.confirmPassword && errors.confirmPassword.message}</p>
          </div>
          <span className="eye-icon" onClick={handleTogglePassword}>
            {isShownPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
          </span>
        </div>

        <button
          onClick={handleSubmit(handlePasswords, handleError)}
          className="continue-button"
          style={{ cursor: showButtonLoading ? "not-allowed" : "" }}
          disabled={showButtonLoading}
        >
          {showButtonLoading ? (
            <div className="loading-gif">
              <img src={mainLoadingGif} />
            </div>
          ) : (
            localizationStrings.continue
          )}
        </button>
      </LoginWrapper>
    );
  }

  return content;
};

export default ResetPassword;
