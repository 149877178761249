import React, { useContext, useEffect, useState } from "react";

import CoveragePackageInstall from "../../assets/icons/svg/CoveragePackageInstall";

import localizationStrings from "../../statics/localization";
import Channellocking from "../../assets/icons/svg/Channellocking";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import TvLockIcon from "../../assets/images/lock-icon.png";

const ChannelLocking = () => {
  const [freeControl, setFreeControl] = useState(false);
  const history = useHistory();

  const { hasAccess } = useContext(SessionContext);

  useEffect(() => {
    let free = localStorage.getItem("logintype");
    if (free === "free") {
      setFreeControl(true);
    }
  }, []);

  return (
    <div className="coverage">
      <div className="channel-coverage">
        {localizationStrings.channelLockingPackage}
      </div>
      <div className="coverage-locking">
        <div className="locking">
          <Channellocking />
        </div>
      </div>

      <div className="package-install">
        {freeControl ? (
          <div
            className="package-button"
            style={{ cursor: "" }}
            onClick={() => {
              history.push(`/signup`);
            }}
          >
            <span>{localizationStrings.signInNow}</span>
          </div>
        ) : !hasAccess?.customerHasAccess ? (
          <div
            className="package-button"
            style={{ cursor: "" }}
            onClick={() => {
              history.push(`/account/package`);
            }}
          >
            <span>{localizationStrings.packageUpNow}</span>
          </div>
        ) : null}
      </div>
      <div className="package-locking">
        {localizationStrings.packageInstall.channelLockPackage}
      </div>
    </div>
  );
};

export default ChannelLocking;
