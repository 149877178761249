import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import localizationStrings from "../../statics/localization";
import ModalPortal from "../modalPortal";
import ProgramDetail from "../programDetail";
import VerticalProgramListItem from "../verticalProgramListItem";

const VerticalProgramListWidget = ({
  programs,
  fromPlayer,
  onSelectCutvProgram,
  onSelectLive,
  isLocked,
  userRating,
  setSelectedCutvProgram,
  selectedChannel,
  userFrameConfig,
  userInfo,
  location
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProgramCode, setSelectedProgramCode] = useState(null);
  const [selectedProgramdDetail, setSelectedProgramDetail] = useState(null);
  const history = useHistory();

  const onSelect = (program) => {
    setShowModal(true);
    setSelectedProgramCode(program?.epgProgramId);
    setSelectedProgramDetail(program);
  };

  const renderedItems = programs.map((program, index) => {
    return (
      <VerticalProgramListItem
        key={program?.epgProgramId}
        program={program}
        index={index}
        onSelect={onSelect}
        onSelectCutvProgram={onSelectCutvProgram}
        onSelectLive={onSelectLive}
        isLocked={isLocked}
        userRating={userRating}
        //selectedChannelRating={selectedChannel?.prevueratingid}
      />
    );
  });

  return (
    <div>
      <div>
        {renderedItems?.length ? (
          renderedItems
        ) : (
          <div className="no-program-info">{localizationStrings.noProgram}</div>
        )}
      </div>
      {!fromPlayer && showModal ? (
        <ModalPortal viewClass="vod-modal">
          <ProgramDetail
            prevueCode={selectedProgramCode}
            closeModal={() => setShowModal(false)}
            fromLive={true}
            setSelectedCutvProgram={setSelectedCutvProgram}
            selectedChannel={selectedChannel}
            userFrameConfig={userFrameConfig}
            userInfo={userInfo}
            location={location}
            history={history}
            programData={selectedProgramdDetail}
          />
        </ModalPortal>
      ) : null}
    </div>
  );
};

export default VerticalProgramListWidget;
