import { axiosInstance } from "../config/configApi";

const getMenuItems = async () => {
  return await axiosInstance.get("/menu");
};

const getCategoriesById = async (id) => {
  return await axiosInstance.get("/menu/" + id);
};

const getLabelsById = async (id) => {
  return await axiosInstance.get("/category/" + id);
};

export { getMenuItems, getCategoriesById, getLabelsById };
