import React from "react";

const InstagramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.373"
      height="12.394"
      viewBox="0 0 12.373 12.394"
    >
      <g id="bxl-instagram-alt" transform="translate(-4.495 -4.47)">
        <path
          id="Path_142"
          data-name="Path 142"
          d="M16.829,8.129a4.487,4.487,0,0,0-.288-1.523A3.168,3.168,0,0,0,14.732,4.8a4.539,4.539,0,0,0-1.5-.289c-.661-.03-.87-.038-2.549-.038s-1.893,0-2.549.038a4.539,4.539,0,0,0-1.5.289A3.166,3.166,0,0,0,4.821,6.606a4.5,4.5,0,0,0-.288,1.5c-.03.661-.038.871-.038,2.549s0,1.892.038,2.549a4.529,4.529,0,0,0,.288,1.5,3.168,3.168,0,0,0,1.81,1.808,4.524,4.524,0,0,0,1.5.309c.661.03.871.038,2.549.038s1.893,0,2.549-.038a4.528,4.528,0,0,0,1.5-.288,3.171,3.171,0,0,0,1.809-1.809,4.513,4.513,0,0,0,.288-1.5c.03-.661.038-.87.038-2.549s0-1.891-.04-2.549Zm-6.152,5.7a3.176,3.176,0,1,1,3.177-3.176,3.175,3.175,0,0,1-3.177,3.176Zm3.3-5.73a.74.74,0,1,1,.74-.741A.74.74,0,0,1,13.98,8.1Z"
          fill="#0c1927"
        />
        <path
          id="Path_143"
          data-name="Path 143"
          d="M17.613,15.527a2.063,2.063,0,1,1-2.063-2.064,2.064,2.064,0,0,1,2.063,2.064Z"
          transform="translate(-4.872 -4.874)"
          fill="#0c1927"
        />
      </g>
    </svg>
  );
};

export default InstagramIcon;
