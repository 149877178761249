import React, { useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";
import "./DownloadList.scss";
import { useHistory } from "react-router-dom";
import ReturnIcon from "../../assets/icons/svg/ReturnIcon";
import { episodes } from "../../api/mockup/mockup";
import DownloadItem from "../../components/downloadItem/DownloadItem";

const DownloadList = () => {
  const { userInfo } = useContext(SessionContext);
  const history = useHistory();
  let allDownloads = [
    {
      id: 1,
      name: "Movie 1",
      type: 1,
      description: "İstediğiniz yerden, istediğiniz cihazdan Filbox izleyin."
    },
    {
      id: 2,
      name: "Movie 2",
      type: 1,
      description: "İstediğiniz zaman mobilde Filbox Spor keyfini yaşayın."
    },
    {
      id: 3,
      name: "Movie 3",
      type: 1,
      description:
        "İstediğiniz zaman tüm cihazlarda Filbox spor keyfini yaşayın."
    },
    {
      id: 4,
      name: "Movie 4",
      type: 1,
      description: "İstediğiniz zaman mobilde Filbox Spor keyfini yaşayın."
    },
    {
      id: 5,
      name: "Series 1",
      type: 2,
      episodesList: [1, 2, 3, 4, 7, 8, 9],
      description:
        "İstediğiniz zaman tüm cihazlarda Filbox spor keyfini yaşayın."
    }
  ];
  const renderedDownloads = allDownloads.map((item) => {
    return (
      <DownloadItem key={item.id} item={item} count={allDownloads.length} />
    );
  });
  console.log(allDownloads, renderedDownloads);

  return (
    <div className="download-container">
      <div>
        <div
          className="settings-icon"
          onClick={() => {
            history.goBack();
          }}
        >
          <ReturnIcon />
          <label style={{ marginLeft: "56px" }}>
            {localizationStrings.downloadIcon}
          </label>
        </div>
        <div>{renderedDownloads}</div>
      </div>
    </div>
  );
};

export default DownloadList;
