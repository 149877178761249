import React, { useContext, useEffect, useRef, useState } from "react";
import {
  addProfile,
  deleteProfile,
  getProfileInfo,
  getProfilesApi,
  updateProfile
} from "../../api/user/user";
import localizationStrings from "../../statics/localization";
import { normalAvatars, childAvatars } from "../../api/mockup/mockup";
import { SessionContext } from "../../context/SessionContext";
import Notification from "../notification/Notification";
import { sendCustomProfileReport } from "../../utils/reporting/customReporting";
import addProfileIcon from "../../assets/images/add-profile-button.png";
import ProfileEditIcon from "../../assets/icons/svg/ProfileEditIcon";
import TvLockIcon from "../../assets/icons/svg/TvLockIcon";
import { useHistory, useParams } from "react-router-dom";
import { getMainboards } from "../../api/vod/vod";
import mainLogo from "../../assets/logo/filbox-logo.png";
import PopupCloseIcon from "../../assets/icons/svg/PopupCloseIcon";
import useQuery from "../../utils/useQuery";
import { isFirefox } from "react-device-detect";
import firefoxPinSecret from "../../utils/firefoxPinSecret";

const ProfileSettings = () => {
  const [profileName, setProfileName] = useState("");
  const [selectedProfile, setSelectedProfile] = useState();
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [focusedAvatar, setFocusedAvatar] = useState(null);
  const [isChildProfile, setIsChildProfile] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [password, setPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [showRemovePasswordNotification, setShowRemovePasswordNotification] =
    useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [showAvatars, setShowAvatars] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showProfileNameAlert, setShowProfileNameAlert] = useState(false);
  const [profileAvatar, setProfileAvatar] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);
  const [i, setI] = useState(0);
  const [secondI, setSecondI] = useState(0);
  const [pinText, setPinText] = useState("");
  const [secondPinText, setSecondPinText] = useState("");
  const {
    userFrameConfig,
    profile,
    profileInfo,
    allProfiles,
    setProfile,
    setAdminProfile
  } = useContext(SessionContext);
  let { profileCode } = useParams();
  const history = useHistory();
  let query = useQuery();
  let page = query.get("page");

  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  let deleteButton = currentProfile.main && !(currentProfile.id == profileCode) ? true : false;
  //console.log(currentProfile.main, currentProfile.id, deleteButton, profileCode, "current profile");

  const getProfile = (id) => {
    let profileData = allProfiles.find((item) => item.id === +id);
    if (profileData) {
      setSelectedProfile(profileData);
      setProfileName(profileData.name);
      setProfileAvatar(
        `${process.env.PUBLIC_URL}/profilePosters/${profileData?.posterName}.png`
      );
      setIsChildProfile(profileData.kid);

      let avatar = (profileData.kid ? childAvatars : normalAvatars).find(
        (el) => el.poster === profileData.posterName
      );
      setSelectedAvatar(avatar);
    }
  };

  const goBack = () => {
    if (!!page) {
      history.goBack();
    } else {
      history.push("/profiles/all");
    }
  };

  const refreshProfiles = async () => {
    const data = await getProfilesApi();
    console.log({ data });
    if (data.status === 200) {
      let profileData = data.data;
      let item = profileData.find((el) => el.id === profile?.id);
      item && setProfile(item);
      if (currentProfile?.main) {
        setAdminProfile(item);
      }
      goBack();
    }
  };

  const saveProfile = async (isRemovePassword = false) => {
    const { id, name, hasPin } = selectedProfile;
    if (profileName) {
      if (
        isRemovePassword ||
        (hasPin && (password.length == 4 || !password)) ||
        (!hasPin && !!password && password.length === 4) ||
        (!hasPin && !password)
      ) {
        const data = await updateProfile(
          id,
          profileName,
          password,
          selectedAvatar
            ? selectedAvatar?.poster
            : isChildProfile
            ? "kids1"
            : "default",
          isChildProfile
        );

        if (data) {
          // removed page control because page control prevents profile refresh
          if (id == profileCode) {
            refreshProfiles();
          } else {
            goBack();
          }
        }
      } else {
        setShowNotification(true);
        setNotificationText(localizationStrings.profile.pinLength);
      }
    } else {
      setShowProfileNameAlert(true);
    }
  };

  const addNewProfile = async () => {
    if (profileName) {
      if (password.length == 4 || !password) {
        const { data } = await addProfile(
          profileName,
          isChildProfile,
          password,
          selectedAvatar
            ? selectedAvatar?.poster
            : isChildProfile
            ? "kids1"
            : "default"
        );
        if (!!data) {
          goBack();
        }
      }
    }
  };

  const delProfile = async () => {
    const { data } = await deleteProfile(profileCode);
    goBack();
  };

  const avatarItems = avatars?.map((item, index) => {
    return (
      <div
        key={item.id}
        className={`avatar-item ${
          focusedAvatar?.id == item?.id ? "selected" : null
        }`}
        onClick={() => {
          setFocusedAvatar(item);
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/profilePosters/${item.poster}.png`}
        />
      </div>
    );
  });

  useEffect(() => {
    if (profileCode !== "new") {
      getProfile(profileCode);
    } else {
      setProfileAvatar(addProfileIcon);
    }
  }, [userFrameConfig, allProfiles]);

  useEffect(() => {
    setAvatars(isChildProfile ? childAvatars : normalAvatars);
  }, [isChildProfile]);

  return (
    <section className="profile-container">
      <div className="row">
        <div className="logo-wrapper">
          <img
            src={mainLogo}
            alt="tivibu logo"
            style={{ maxWidth: "200px", height: "auto" }}
          />
        </div>
        <div
          className="close"
          onClick={() => {
            if (showAvatars) {
              setShowAvatars(false);
            } else {
              history.push("/selectprofile");
            }
          }}
        >
          <a className="logout">Geri Dön</a>
        </div>
      </div>
      <section className="profile-settings-container">
        {!showAvatars ? (
          <div className="profile-settings-box">
            <div className="current-avatar-wrapper">
              <img
                src={profileAvatar}
                onError={() => setProfileAvatar(addProfileIcon)}
              />
              <div
                className="edit-icon"
                onClick={() => {
                  setShowAvatars(true);
                }}
              >
                <ProfileEditIcon />
              </div>
            </div>
            <div className="form-wrapper">
              <form id="profileSettingsForm">
                <div className="form-group">
                  <input
                    className="profile-input"
                    style={{ width: "100%" }}
                    autoComplete="off"
                    name="profileName"
                    id="profileName"
                    type="text"
                    value={decodeURIComponent(profileName)}
                    placeholder={localizationStrings.profileName}
                    onChange={(e) => {
                      showProfileNameAlert && setShowProfileNameAlert(false);
                      setProfileName(e.target.value);
                    }}
                    maxLength="15"
                  />
                  {showProfileNameAlert ? (
                    <div className="alert-text">
                      {localizationStrings.profileNameWarning}
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  {!isFirefox ? (
                    <input
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                        e.preventDefault()
                      }
                      className="profile-input pin"
                      style={{ width: "100%" }}
                      autoComplete="off"
                      name="profileName"
                      id="profileName"
                      type="number"
                      value={password}
                      placeholder={`${localizationStrings.login.password} (${localizationStrings.optional})`}
                      onChange={(e) => {
                        if (e.target.value.length > 4) {
                          e.target.value = e.target.value.slice(0, 4);
                        }
                        setPassword(e.target.value);
                        if (
                          e.target.value?.length <= secondPassword?.length &&
                          e.target.value !== secondPassword
                        ) {
                          setShowAlert(true);
                        } else {
                          setShowAlert(false);
                        }
                      }}
                    />
                  ) : (
                    <input
                      style={{ width: "100%" }}
                      className="profile-input"
                      autoComplete="off"
                      name="profileName"
                      id="profileName"
                      type="text"
                      onMouseUp={(e) => {
                        let [pin, i] = firefoxPinSecret(null, e);
                        setI(i);
                        setPinText(pin.replace(/[0-9,•]/g, "•"));
                        setPassword(pin);
                      }}
                      onKeyDown={(e) => {
                        let [pin, i] = firefoxPinSecret(e, null);
                        setI(i);
                        setPinText(pin.replace(/[0-9,•]/g, "•"));
                        setPassword(pin);
                        if (
                          pin?.length <= secondPassword?.length &&
                          pin !== secondPassword
                        ) {
                          setShowAlert(true);
                        } else {
                          setShowAlert(false);
                        }
                      }}
                      onChange={(e) => {
                        e.target.value = pinText;
                        e.target.selectionEnd = i;
                        e.target.selectionStart = i;
                      }}
                      //value={password.replace(/[0-9]/g, "•")}
                      placeholder={`${localizationStrings.login.password} (${localizationStrings.optional})`}
                    />
                  )}
                </div>

                <div className="form-group">
                  {!isFirefox ? (
                    <input
                      className="profile-input pin"
                      style={{ width: "100%" }}
                      autoComplete="off"
                      name="profileName"
                      id="profileName"
                      type="number"
                      maxLength="4"
                      value={secondPassword}
                      placeholder={`${localizationStrings.login.password} (${localizationStrings.again})`}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                        e.preventDefault()
                      }
                      onChange={(e) => {
                        if (e.target.value.length > 4) {
                          e.target.value = e.target.value.slice(0, 4);
                        }
                        setSecondPassword(e.target.value);
                        if (
                          password?.length <= e.target.value?.length &&
                          password !== e.target.value
                        ) {
                          setShowAlert(true);
                        } else {
                          setShowAlert(false);
                        }
                      }}
                    />
                  ) : (
                    <input
                      className="profile-input"
                      style={{ width: "100%" }}
                      autoComplete="off"
                      name="profileName"
                      id="profileName"
                      type="text"
                      onMouseUp={(e) => {
                        let [pin, i] = firefoxPinSecret(null, e);
                        setSecondI(i);
                        setSecondPinText(pin.replace(/[0-9,•]/g, "•"));
                        setSecondPassword(pin);
                      }}
                      onKeyDown={(e) => {
                        let [pin, i] = firefoxPinSecret(e, null);
                        setSecondI(i);
                        setSecondPinText(pin.replace(/[0-9,•]/g, "•"));
                        setSecondPassword(pin);

                        if (
                          password?.length <= secondPassword?.length &&
                          secondPassword !== password
                        ) {
                          setShowAlert(true);
                        } else {
                          setShowAlert(false);
                        }
                      }}
                      onChange={(e) => {
                        e.target.value = secondPinText;
                        e.target.selectionEnd = secondI;
                        e.target.selectionStart = secondI;
                      }}
                      //value={secondPassword.replace(/[0-9]/g, "•")}
                      placeholder={`${localizationStrings.login.password} (${localizationStrings.again})`}
                    />
                  )}
                  {showAlert ? (
                    <div className="alert-text">
                      {localizationStrings.passwordsMatch}
                    </div>
                  ) : null}
                </div>
                {/*<div className="form-group">
                  <div className="kid-checkbox-row">
                    <div
                      className={`kid-checkbox ${
                        isChildProfile ? "checked" : ""
                      }`}
                      onClick={() => {
                        setSelectedAvatar(null);
                        setProfileAvatar(addProfileIcon);
                        setIsChildProfile(!isChildProfile);
                      }}
                    ></div>
                    <span>{localizationStrings.kidsProfile}</span>
                  </div>
                    </div>*/}

                <div className="form-buton-group">
                  {profileCode !== "new" && deleteButton ? (
                    <div
                      className="white-button"
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        delProfile();
                      }}
                    >
                      {localizationStrings.profile.delete}
                    </div>
                  ) : null}
                  <div
                    className="yellow-button"
                    onClick={() => {
                      if (profileCode === "new") {
                        if (!!password) {
                          if (password === secondPassword) {
                            addNewProfile();
                          } else {
                            setShowAlert(true);
                          }
                        } else {
                          addNewProfile();
                        }
                      } else {
                        if (!!password) {
                          if (password === secondPassword) {
                            saveProfile();
                          } else {
                            setShowAlert(true);
                          }
                        } else {
                          saveProfile();
                        }
                      }
                    }}
                  >
                    {localizationStrings.saveProfile}
                  </div>
                </div>
                {profileCode !== "new" &&
                selectedProfile?.hasPin &&
                (currentProfile?.main || !selectedProfile?.kid) ? (
                  <div
                    className="form-buton-group"
                    style={{ marginTop: "1vw" }}
                    onClick={() => setShowRemovePasswordNotification(true)}
                  >
                    <div className="white-button">
                      <TvLockIcon />
                      {localizationStrings.removeProfilePassword}
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        ) : (
          <div className="avatar-wrapper">
            <div
              className="avatar-box"
              style={{ marginTop: isChildProfile ? "10vw" : "" }}
            >
              {avatarItems}
            </div>
            <div className="save-button">
              <div
                className="yellow-button"
                onClick={() => {
                  setSelectedAvatar(focusedAvatar);
                  setProfileAvatar(
                    `${process.env.PUBLIC_URL}/profilePosters/${focusedAvatar?.poster}.png`
                  );
                  setShowAvatars(false);
                }}
              >
                {localizationStrings.profile.save}
              </div>
            </div>
          </div>
        )}

        {showNotification ? (
          <Notification
            text={notificationText}
            confirmText={localizationStrings.close}
            onClickConfirm={() => setShowNotification(false)}
          />
        ) : null}

        {showRemovePasswordNotification ? (
          <Notification
            text={localizationStrings.removeProfilePasswordText}
            confirmText={localizationStrings.yes}
            notConfirmText={localizationStrings.cancel}
            onClickConfirm={() => saveProfile(true)}
            onClickNotConfirm={() => setShowRemovePasswordNotification(false)}
          />
        ) : null}
      </section>
      <div className="profile-gradient"></div>
      <div className="profile-gradient-2"></div>
    </section>
  );
};

export default ProfileSettings;
