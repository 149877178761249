import videojs from "video.js";

function sortSprites(sprites) {
  sprites.sort(function (e1, e2) {
    if (e1.start < e2.start) return -1;
    else if (e1.start === e2.start) return 0;
    else return 1;
  });
}

function convertSecondToTimeString(seconds) {
  var resultSecond = 0;
  var resultMinute = 0;
  var resultHour = 0; // 00:00:00 Format

  if (seconds <= 0) {
    return "00:00:00";
  } else if (seconds < 60) {
    // less than a minute
    resultSecond = seconds;
    resultSecond = resultSecond < 10 ? "0" + resultSecond : resultSecond;
    return "00:00:" + resultSecond;
  } else if (seconds < 3600) {
    // less than an hour
    resultMinute = parseInt("" + seconds / 60);
    resultMinute = resultMinute < 10 ? "0" + resultMinute : resultMinute;
    resultSecond = seconds % 60;
    resultSecond = resultSecond < 10 ? "0" + resultSecond : resultSecond;
    return "00:" + resultMinute + ":" + resultSecond;
  } else {
    // more than an hour
    resultHour = parseInt("" + seconds / 3600);
    resultHour = resultHour < 10 ? "0" + resultHour : resultHour;
    resultMinute = parseInt("" + (seconds % 3600) / 60);
    resultMinute = resultMinute < 10 ? "0" + resultMinute : resultMinute;
    resultSecond = parseInt("" + (((seconds % 3600) % 60) % 60));
    resultSecond = resultSecond < 10 ? "0" + resultSecond : resultSecond;
    return resultHour + ":" + resultMinute + ":" + resultSecond;
  }
}

function displayWarning(message) {
  console.warn("[videojs-thumbnail-sprite] " + message);
}

function checkOverlap(sprites) {
  // Check if the sprite thumbnails have overlapping section among them,
  // so that previews display their corresponding points of time correctly
  for (var i = 0; i < sprites.length - 1; i++) {
    var e1Start = sprites[i].start;
    var e1End = e1Start + sprites[i].duration;
    var e2Start = sprites[i + 1].start;
    var e2End = e2Start + sprites[i + 1].duration;

    if (e2Start < e1End) {
      var overlapStart = e1Start;
      var overlapEnd = Math.max(e1End, e2End); // If there is an overlap, generate warning on console

      displayWarning(
        "Provided thumbnail sprites have overlapping sections among them: [" +
          convertSecondToTimeString(overlapStart) +
          " ~ " +
          convertSecondToTimeString(overlapEnd) +
          "] Preview images may not match the corresponding point of time correctly."
      );
    }
  }
}

function checkOptions(sprites) {
  // Check if the sprite thumbnails have all required options properly,
  // so that generating each previews executes correctly
  for (var i = 0; i < sprites.length; i++) {
    var _a = sprites[i],
      start = _a.start,
      duration = _a.duration,
      interval = _a.interval,
      width = _a.width,
      height = _a.height,
      url = _a.url; // # `start` should be zero or positive value

    if (start < 0) {
      displayWarning(
        "`start` should be zero or positive value: [" +
          convertSecondToTimeString(start) +
          " ~ " +
          convertSecondToTimeString(start + duration - 1) +
          "] Preview images may not show as expected"
      );
    } // # `width` and `height` should not be undefined

    if (width === undefined || height === undefined) {
      displayWarning(
        "`width` and `height` should be provided: [" +
          convertSecondToTimeString(start) +
          " ~ " +
          convertSecondToTimeString(start + duration - 1) +
          "] Preview images may not show as expected"
      );
    } // # `interval` should be able to fully cover entire duration

    if (duration % interval !== 0) {
      displayWarning(
        "`duration` should be multiple of `interval` so that it can fully cover entire duration: [" +
          convertSecondToTimeString(start) +
          " ~ " +
          convertSecondToTimeString(start + duration - 1) +
          "] Preview images may not show as expected"
      );
    }
  }
}

function applyStyle(parent, style) {
  var keys = Object.keys(style);
  keys.map(function (key) {
    var value = style[key];
    if (value !== "") parent.style.setProperty(key, value);
    // If key's value is empty, remove that style
    else parent.style.removeProperty(key);
  });
}

function generatePreview(player, controls, sprites) {
  var dom = videojs.dom;
  var sprite; // 3-dimension approach

  var hoverPoint = -1; // which point of time currently hovering

  var optionIndex = -1; // which sprite image to use

  var spriteIndex = -1; // which one inside a sprite image to use
  // The case that Progress Control UI does not exist have already been filtered before this function is called

  var progressCtrl = controls["progressControl"]; // If there is no Seek Bar UI or Mouse Time Display UI,
  // No Need to initialize the plugin

  if (progressCtrl["seekBar"] === null) return;
  var seekBar = progressCtrl["seekBar"];
  if (seekBar["mouseTimeDisplay"] === null) return;
  var mouseTimeDisplay = seekBar["mouseTimeDisplay"];
  if (mouseTimeDisplay["timeTooltip"] === null) return;
  var timeTooltip = mouseTimeDisplay["timeTooltip"]; // The components used to calculate the current point of time

  var mouseTimeDisplayEl = mouseTimeDisplay.el(); // The component to apply preview image on

  var timeTooltipEl = timeTooltip.el(); // from mouse pointer's location, get the point of time

  hoverPoint = parseFloat(mouseTimeDisplayEl.style.left);
  hoverPoint = player.duration() * (hoverPoint / seekBar.currentWidth()); // from point of time currently hovering, get the corresponding preview image

  if (!isNaN(hoverPoint)) {
    // determine where the `hoverPoint` belongs
    for (var i = 0; i < sprites.length; i++) {
      var sprite_1 = sprites[i];

      if (hoverPoint < sprite_1.start + sprite_1.duration) {
        optionIndex = i;
        break;
      }
    }
  } else {
    // if `hoverPoint` has a strange value, assign default value as 0
    hoverPoint = 0;
  } // if `optionIndex` is -1, it means corresponding thumbnail sprite does not exist
  // so just use the first sprite
  // calculate which image inside the sprite to use

  spriteIndex =
    optionIndex !== -1
      ? (hoverPoint - sprites[optionIndex].start) /
        sprites[optionIndex].interval
      : hoverPoint;
  sprite = optionIndex !== -1 ? sprites[optionIndex] : sprites[0]; // create temporary `img` element to get the size of sprite thumbnail

  var image = dom.createEl("img", {
    src: sprite.url
  });
  var imageWidth = image.naturalWidth;
  var imageHeight = image.naturalHeight; // get the coordinate to extract preview image from sprite thumbnail

  var columns = imageWidth / sprite.width;
  var columnTop = Math.floor(spriteIndex / columns) * -sprite.height;
  var columnLeft = Math.floor(spriteIndex % columns) * -sprite.width; // get the position to display preview image

  var controlsTop = dom.getBoundingClientRect(controls.el()).top;
  var seekBarTop = dom.getBoundingClientRect(controls.el()).top;
  var topOffset = -sprite.height - Math.max(0, seekBarTop - controlsTop); // apply image preview

  applyStyle(timeTooltipEl, {
    width: sprite.width + "px",
    height: sprite.height + "px",
    "background-image": "url(" + sprite.url + ")",
    "background-repeat": "no-repeat",
    "background-position": columnLeft + "px " + columnTop + "px",
    "background-size": imageWidth + "px " + imageHeight + "px;",
    top: topOffset + "px",
    color: "#ffffff",
    "text-shadow": "1px 1px #000000",
    border: "1px 1px #000000",
    margin: "0 1px"
  }); // TODO
  // apply global style from `options` parameter
}

export function initializeThumbnailSprite(player, options) {
  // If there is no option provided,
  // No need to initialize the plugin
  if (options.sprites === undefined) return;
  var sprites = options.sprites; // If there is no Control Bar UI or no Progress Control UI,
  // No need to initialize the plugin

  if (player.controlBar === undefined) return;
  var controls = player.controlBar;
  if (controls["progressControl"] === undefined) return;
  var progressCtrl = controls["progressControl"]; // Sort sprite images to prevent inappropriate order

  sortSprites(sprites); // Check if the sprite thumbnails have overlapping section among them,
  // so that previews display their corresponding points of time correctly

  checkOverlap(sprites); // Check if the sprite thumbnails have all required options properly,
  // so that generating each previews executes correctly

  checkOptions(sprites); // Register event listener for hovering on progress control

  progressCtrl.on("mousemove", function () {
    return generatePreview(player, controls, sprites);
  });
  progressCtrl.on("touchmove", function () {
    return generatePreview(player, controls, sprites);
  }); // Add class to enable styling

  player.addClass("vjs-sprite-thumbnails");
}
