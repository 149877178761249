let logger;

if (process.env.REACT_APP_ENVIRONMENT !== undefined) {
  const winston = require("winston");
  logger = winston.createLogger({
    level: process.env.REACT_APP_ENVIRONMENT,
    transports: [new winston.transports.Console()],
    format: winston.format.simple(),
  });
} else {
  logger = {
    error: () => {},
    warn: () => {},
    info: () => {},
    http: () => {},
    verbose: () => {},
    debug: () => {},
    silly: () => {},
  };
}

export default logger;
