import { getColectAgent } from "../../statics/config";
import { reportingPageEvent } from "../../statics/reportEventId";
import moment from "moment";
import { query } from "winston";

let collectAgent;
let query_;

export const pageInitCollectAgent = () => {
  collectAgent = getColectAgent();
};

export const pageChanged = (currentPage, prevPage, query, params_) => {
  if (collectAgent) {
    let pageReportObject = {
      eventtype: "E_PAGE",
      eventid: "E_PAGE_IN",
      eventtime: "",
      lastpageid: "",
      currentpageid: "",
      currententryid: "",
      actiontime: "",
      result: "0",
      errorMsg: "no error",
      reseverse7: window.location.origin
    };
    let now = moment().utc().format("yyyyMMDDHHmmss");
    let pathObj = getHomePageId(currentPage, prevPage, query);

    if (pathObj.p1 != null) {
      pageReportObject.currentpageid = pathObj.p1;
      pageReportObject.lastpageid = pathObj.p2;
      pageReportObject.currententryid = pathObj.currententryid
        ? pathObj.currententryid
        : "";
      pageReportObject.actiontime = now;
      pageReportObject.eventtime = now;

      if (!query) {
        query = query_;
      } else {
        query_ = query;
      }

      if (currentPage.includes("/voddetail")) {
        let params = getParams(currentPage, query);
        setParams(pageReportObject, params);
      }

      if (
        currentPage.includes("/npvrdetail") ||
        currentPage.includes("/programdetail")
      ) {
        pageReportObject.reseverse1 = params_.prevuecode;
        pageReportObject.reseverse2 = params_.seriesheadid;
        pageReportObject.reseverse3 = params_.channelcode;
      }

      if (currentPage.includes("/profile/")) {
        let i = currentPage.lastIndexOf("/");
        let profileCode = currentPage.slice(i + 1, currentPage.length);
        pageReportObject.reseverse1 = profileCode;
      }

      collectAgent.logPage(pageReportObject);
    }
  }
};

const setParams = (obj1, obj2) => {
  obj1.reseverse1 = obj2.contentcode;
  obj1.reseverse2 = obj2.programtype;
  obj1.reseverse3 = obj2.programcode;
  if (obj2.programtype == "14") {
    obj1.reseverse4 = obj2.programcode;
  } else if (obj2.programtype == "10") {
    obj1.reseverse3 = obj2.episodecode;
    obj1.reseverse4 = obj2.programcode;
  }
};

const getParams = (val, query) => {
  let pIndex = val.indexOf("/voddetail");
  let programCode = val.substr(pIndex + 11, val.length);

  let typeIndex = query.indexOf("ptype");
  let pType;
  let contentCode;
  let episodeCode;

  if (query.includes("&")) {
    let i = query.indexOf("&");
    pType = query.slice(typeIndex + 6, i);
  } else {
    pType = query.slice(typeIndex + 6, query.length);
  }

  if (query.includes("contentcode")) {
    let i = query.indexOf("contentcode=");
    let lastIndex;

    if (query.includes("fromsearch")) {
      lastIndex = query.indexOf("&fromsearch");
    } else {
      lastIndex = query.length;
    }
    contentCode = query.slice(i + 12, lastIndex);
  }

  if (query.includes("episodecode")) {
    let i = query.indexOf("episodecode=");
    let lastIndex;

    if (query.includes("&recommend")) {
      lastIndex = query.indexOf("&recommend");
    } else {
      lastIndex = query.length;
    }
    episodeCode = query.slice(i + 12, lastIndex);
  }

  let obj = {
    programcode: programCode,
    contentcode: contentCode,
    episodecode: episodeCode,
    programtype: pType
  };

  return obj;
};

const getHomePageId = (p1, p2, q) => {
  let forMeMenu = localStorage.getItem("forMeColumn");
  let movieMenu = localStorage.getItem("vodColumn");
  let seriesMenu = localStorage.getItem("seriesColumn");
  let rentMenu = localStorage.getItem("rentColumn");
  let kidMenu = localStorage.getItem("kidColumn");
  let obj = { p1: "", p2: "" };

  if (p1.includes("/search")) {
    obj.p1 = reportingPageEvent.E_PAGE_SEARCH;
  } else if (p1.includes("/voddetail")) {
    if (q.includes("fromsearch=true")) {
      obj.currententryid = reportingPageEvent.E_PAGE_VODDETAILFROM_SEARCH;
    }

    if (q.includes("recommend")) {
      let index;
      let recommendType;
      index = q.indexOf("recommend");
      recommendType = q.slice(index + 10, q.length);

      if (recommendType === "banner") {
        obj.currententryid = reportingPageEvent.E_PAGE_VODRECOMMENDFROM_BANNER;
      } else if (recommendType === "column") {
        obj.currententryid = reportingPageEvent.E_PAGE_RECOMMENDFROM_VODCOLUMNE;
      } else if (recommendType === "vod") {
        obj.currententryid = reportingPageEvent.E_PAGE_RECOMMENDFROM_VODDETAIL;
      }
    }
    obj.p1 = reportingPageEvent.E_PAGE_VODDETAIL;
  } else if (p1.includes("/settings")) {
    obj.p1 = reportingPageEvent.E_PAGE_MENUESETTING;
  } else if (p1.includes("/records")) {
    obj.p1 = reportingPageEvent.E_PAGE_NPVR;
  } else if (p1.includes("/parentalcontrol")) {
    obj.p1 = reportingPageEvent.E_PAGE_SWITCHUSERLEVEL;
  } else if (p1.includes("/favlockchannel")) {
    obj.p1 = reportingPageEvent.E_PAGE_CHANNELLOCK;
  } else if (p1.includes("/language")) {
    obj.p1 = reportingPageEvent.E_PAGE_LANGUAGESETTING;
  } else if (p1.includes("/version")) {
    obj.p1 = reportingPageEvent.E_PAGE_VERSION;
  } else if (p1.includes("/dashboard/home")) {
    if (p1.includes(forMeMenu)) {
      obj.p1 = reportingPageEvent.E_PAGE_SPECIALTOME;
    } else if (p1.includes(movieMenu)) {
      obj.p1 = reportingPageEvent.E_PAGE_VOD;
    } else if (p1.includes(seriesMenu)) {
      obj.p1 = reportingPageEvent.E_PAGE_SERIES;
    } else if (p1.includes(rentMenu)) {
      obj.p1 = reportingPageEvent.E_PAGE_PAY;
    } else if (p1.includes(kidMenu)) {
      obj.p1 = reportingPageEvent.E_PAGE_CHILDREN;
    }
  } else if (p1.includes("/profile")) {
    obj.p1 = reportingPageEvent.E_PAGE_PROFILE;
  } else if (p1.includes("/programdetail")) {
    obj.p1 = reportingPageEvent.E_PAGE_PREVUEDETAIL;
  } else if (p1.includes("/npvrdetail")) {
    obj.p1 = reportingPageEvent.E_PAGE_NPVRDETAIL;
  } else if (p1.includes("/dashboard/livetv")) {
    obj.p1 = reportingPageEvent.E_PAGE_TV;
  }

  if (p2.includes("/search")) {
    obj.p2 = reportingPageEvent.E_PAGE_SEARCH;
  } else if (p2.includes("/voddetail")) {
    obj.p2 = reportingPageEvent.E_PAGE_VODDETAIL;
  } else if (p2.includes("/settings")) {
    //to do
    if (p2.includes("/settings/main")) {
      obj.p2 = reportingPageEvent.E_PAGE_MENUESETTING;
    } else if (p2.includes("/settings/records")) {
      obj.p2 = reportingPageEvent.E_PAGE_NPVR;
    } else if (p2.includes("/settings/parentalcontrol")) {
      obj.p2 = reportingPageEvent.E_PAGE_SWITCHUSERLEVEL;
    } else if (p2.includes("/settings/lockchannel")) {
      obj.p2 = reportingPageEvent.E_PAGE_CHANNELLOCK;
    } else if (p2.includes("/settings/favchannel")) {
      obj.p2 = reportingPageEvent.E_PAGE_CHANNELFAVORATE;
    } else if (p2.includes("/settings/purchasehistory")) {
      obj.p2 = reportingPageEvent.E_PAGE_PURCHASEHISTORY;
    } else if (p2.includes("/settings/language")) {
      obj.p2 = reportingPageEvent.E_PAGE_LANGUAGESETTING;
    } else if (p2.includes("/settings/version")) {
      obj.p2 = reportingPageEvent.E_PAGE_VERSION;
    } else if (p2.includes("/settings/package")) {
      obj.p2 = reportingPageEvent.E_PAGE_PACKAGE;
    }
  } else if (p2.includes("/dashboard/home")) {
    if (p2.includes(forMeMenu)) {
      obj.p2 = reportingPageEvent.E_PAGE_SPECIALTOME;
    } else if (p2.includes(movieMenu)) {
      obj.p2 = reportingPageEvent.E_PAGE_VOD;
    } else if (p2.includes(seriesMenu)) {
      obj.p2 = reportingPageEvent.E_PAGE_SERIES;
    } else if (p2.includes(rentMenu)) {
      obj.p2 = reportingPageEvent.E_PAGE_PAY;
    } else if (p2.includes(kidMenu)) {
      obj.p2 = reportingPageEvent.E_PAGE_CHILDREN;
    }
  } else if (p2.includes("/profile")) {
    obj.p2 = reportingPageEvent.E_PAGE_PROFILE;
  } else if (p2.includes("/programdetail")) {
    obj.p2 = reportingPageEvent.E_PAGE_PREVUEDETAIL;
  } else if (p2.includes("/npvrdetail")) {
    obj.p2 = reportingPageEvent.E_PAGE_NPVRDETAIL;
  } else if (p2.includes("/dashboard/livetv")) {
    obj.p2 = reportingPageEvent.E_PAGE_TV;
  }

  return obj;
};
