import React from "react";

const PlayerDownArrowIcon=()=> {
  return (<svg xmlns="http://www.w3.org/2000/svg"
    width="1.7em"
    height="1em"
    viewBox="0 0 22.758 14.052"
    > <path id="Icon_material-navigate-next"
    data-name="Icon material-navigate-next"
    d="M2.674,22.758,0,20.084l8.685-8.7L0,2.674,2.674,0,14.052,11.379Z"
    transform="translate(22.758) rotate(90)"
    fill="#bdc2c3"
    /> </svg>);
}

;

export default PlayerDownArrowIcon;