import React from "react";

const RecordingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 28.247 28.247"
    >
      <ellipse
        id="Ellipse_5"
        data-name="Ellipse 5"
        cx="14.124"
        cy="14.124"
        rx="14.124"
        ry="14.124"
        fill="#d10000"
      />
    </svg>
  );
};

export default RecordingIcon;
