import localizationStrings from "../statics/localization";

export const prettyTimeFormatter = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (hours === 0 && minutes === 0) {
    return `${seconds} ${localizationStrings.shortSeconds}`;
  } else if (hours === 0) {
    return `${minutes} ${localizationStrings.shortMinutes} ${seconds} ${localizationStrings.shortSeconds}`;
  } else if (minutes === 0 && seconds === 0) {
    return `${hours} ${localizationStrings.shortHours}`;
  } else if (minutes === 0) {
    return `${hours} ${localizationStrings.shortHours} ${seconds} ${localizationStrings.shortSeconds}`;
  } else if (seconds === 0) {
    return `${hours} ${localizationStrings.shortHours} ${minutes} ${localizationStrings.shortMinutes}`;
  } else {
    return `${hours} ${localizationStrings.shortHours} ${minutes} ${localizationStrings.shortMinutes} ${seconds} ${localizationStrings.shortSeconds}`;
  }
};
