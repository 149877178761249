import React from "react";

const EnterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 17.986 13.406"
    >
      <g
        id="Group_866"
        data-name="Group 866"
        transform="translate(27.354 18.651) rotate(180)"
      >
        <g
          id="Group_496"
          data-name="Group 496"
          transform="translate(26.354 17.236) rotate(180)"
        >
          <path
            id="Path_144"
            data-name="Path 144"
            d="M.178,10.579,4.5,5.917A.814.814,0,0,0,4.6,4.5L0,0"
            transform="translate(11.017)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_22"
            data-name="Line 22"
            x1="15.976"
            transform="translate(0 5.289)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default EnterIcon;
