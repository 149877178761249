import React from "react";

const TwitterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.665"
      height="9.607"
      viewBox="0 0 11.665 9.607"
    >
      <path
        id="Twitter"
        d="M10.29,1.519A2.427,2.427,0,0,0,11.343.177a4.761,4.761,0,0,1-1.52.588A2.376,2.376,0,0,0,8.076,0,2.409,2.409,0,0,0,5.683,2.425a2.463,2.463,0,0,0,.062.553A6.764,6.764,0,0,1,.812.444a2.449,2.449,0,0,0,.74,3.238,2.36,2.36,0,0,1-1.084-.3c0,.01,0,.02,0,.03a2.419,2.419,0,0,0,1.92,2.378,2.365,2.365,0,0,1-1.081.042A2.4,2.4,0,0,0,3.543,7.513,4.762,4.762,0,0,1,.571,8.551,4.733,4.733,0,0,1,0,8.517a6.707,6.707,0,0,0,3.669,1.09,6.812,6.812,0,0,0,6.809-6.9q0-.157-.007-.314a4.893,4.893,0,0,0,1.194-1.255A4.738,4.738,0,0,1,10.29,1.519Z"
        fill="#0c1927"
      />
    </svg>
  );
};

export default TwitterIcon;
