import React from "react";

const TrashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15.745 19.392"
    >
      <g id="delete" transform="translate(-15 -14)">
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M15.737,3.776A1.234,1.234,0,0,0,15.45,2.77a1.139,1.139,0,0,0-.88-.324q-2.332,0-4.664,0H9.693c-.018-.33-.028-.626-.051-.921A1.619,1.619,0,0,0,8.222.052.853.853,0,0,1,8.064,0H7.722a1.006,1.006,0,0,1-.178.051A1.608,1.608,0,0,0,6.112,1.426a6.97,6.97,0,0,0-.038.755c-.006.265,0,.265-.267.265q-2.332,0-4.664,0a1.025,1.025,0,0,0-1.122.929c-.122.89.3,1.459,1.139,1.459q6.711,0,13.423,0a1.041,1.041,0,0,0,1.155-1.058"
          transform="translate(15 14)"
          fill="currentColor"
        />
        <path
          id="Fill_4"
          data-name="Fill 4"
          d="M10.658,13.325H2.578A2.581,2.581,0,0,1,0,10.748V0H13.235V10.748A2.581,2.581,0,0,1,10.658,13.325ZM2.981,2.4a.55.55,0,0,0-.2.038.659.659,0,0,0-.419.618q0,1.357,0,2.714v.9q0,1.762,0,3.524v.01a1.02,1.02,0,0,0,.021.253A.625.625,0,0,0,3,10.944a.717.717,0,0,0,.082,0,.654.654,0,0,0,.529-.629c0-.515,0-1.037,0-1.542q0-.289,0-.579V6.919c0-1.248,0-2.538,0-3.808a.717.717,0,0,0-.191-.517A.606.606,0,0,0,2.981,2.4ZM9.639,6.676h0V7.711c0,.845,0,1.719,0,2.585a.644.644,0,0,0,.531.644.757.757,0,0,0,.081,0,.616.616,0,0,0,.621-.5,1.314,1.314,0,0,0,.017-.3c0-.027,0-.055,0-.082q0-1.162,0-2.325,0-2.325,0-4.649a.679.679,0,0,0-.291-.584.558.558,0,0,0-.329-.111.6.6,0,0,0-.281.073.626.626,0,0,0-.35.585q0,1.358,0,2.715v.9ZM6.62,2.41H6.606A.636.636,0,0,0,6,3.1q0,1.45,0,2.9V7.908c0,.774,0,1.575,0,2.368a.631.631,0,0,0,.608.665h.009a.631.631,0,0,0,.634-.667q0-1.2,0-2.4V5.324c0-.744,0-1.513,0-2.269A.624.624,0,0,0,6.62,2.41Z"
          transform="translate(16.249 20.066)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default TrashIcon;
