import React, { createContext, useState } from "react";
import { getLoginToken } from "./../api/login/loginApi";
import { getProfilesApi, loginWithProfile } from "./../api/user/user";
import { loginEvent } from "./../api/event/event";

export const SessionContext = createContext();

const SessionProvider = ({ children, values }) => {
  const [path, setPath] = useState(null);
  const [profile, setProfile] = useState(null);
  const [userFrameConfig, setUserFrameConfig] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [myProgram, setMyProgram] = useState({});
  const [userInfo, setUserInfo] = useState(null);
  const [profileInfo, setProfileInfo] = useState({}); //to get teamid..
  const [ppvProduct, setPpvProduct] = useState({});
  const [ppvProgram, setPpvProgram] = useState({});
  const [packageId, setPackageId] = useState(null);
  const [isRestartProgram, setIsRestartProgram] = useState(null);
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const [isChangeFavList, setIsChangeFavList] = useState(false);
  const [recordStatus, setRecordStatus] = useState(null);
  const [isClickedRecord, setIsClickedRecord] = useState(null);
  const [selectedCutvProgram, setSelectedCutvProgram] = useState(null);
  const [isLiveSelected, setIsLiveSelected] = useState(null);
  const [adminProfile, setAdminProfile] = useState(null);
  const [isChangeLockChannel, setIsChangeLockChannel] = useState(null);
  const [isChildProfile, setIsChildProfile] = useState(null);
  const [currentProgramTimes, setCurrentProgramTimes] = useState(null);
  const [prevMytvChannelPrograms, setPrevMytvChannelPrograms] = useState([]);
  const [nextMytvChannelPrograms, setNextMytvChannelPrograms] = useState([]);
  const [mytvChannelPosterPath, setMytvChannelPosterPath] = useState("");
  const [mesMessage, setMesMessage] = useState(null);
  const [selectedMytvChannelProgram, setSelectedMytvChannelProgram] =
    useState(null);
  const [userPackage, setUserPackage] = useState(null);
  const [isCollectScriptInitialized, setIsCollectScriptInitialized] =
    useState(null);
  const [isLogout, setIsLogout] = useState(null);
  const [channelItems, setChannelItems] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [allProfiles, setAllProfiles] = useState([]);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [programInfo, setProgramInfo] = useState(null);
  const [mesFingerPrintParam, setMesFingerPrintParam] = useState(null); //store finger print param coming from MES
  const [pictureInPicture, setPictureInPicture] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [hasAccess, setHasAccess] = useState(null);
  const [playUrl, setPlayUrl] = useState(null);

  const loginEventControl = async () => {
    const { event } = await loginEvent();
  };

  const handleLoginProfile = async () => {
    const data = await getProfilesApi();
    console.log(data.data[0]);
    if (!!data) {
      const data1 = await loginWithProfile(data.data[0].id, data.data[0].pin);
      console.log(data1.data);

      if (!!data1.data && data1.data?.token) {
        localStorage.setItem("profileToken", data1.data.token);
        localStorage.setItem("profile", JSON.stringify(data.data[0]));
        loginEventControl();
      }
    }
  };

  const handleLogin = async () => {
    const loginCredentials = { password: "filboxfree", userName: "filboxfree" };
    const { data } = await getLoginToken(
      loginCredentials.userName,
      loginCredentials.password
    );
    console.log(data);

    if (!!data && data?.token) {
      localStorage.setItem("userToken", data.token);
      localStorage.setItem("hasLoggedIn", true);
      setHasLoggedIn(true);
      handleLoginProfile();
    }
  };

  const loginGuessProfile = async () => {
    localStorage.setItem("logintype", "free");
    localStorage.removeItem("userToken");
    localStorage.removeItem("profileToken");
    localStorage.removeItem("hasLoggedIn");
    await handleLogin();
  };

  const handleLogout = () => {
    setHasLoggedIn(false);
    setProfile(null);
    localStorage.removeItem("userToken");
    localStorage.removeItem("profileToken");
    localStorage.setItem("hasLoggedIn", false);
    localStorage.removeItem("profile");
    loginGuessProfile();
  };

  const appData = {
    packageId, 
    setPackageId,
    path,
    setPath,
    profile,
    setProfile,
    userFrameConfig,
    setUserFrameConfig,
    selectedChannel,
    setSelectedChannel,
    hasAccess,
    setHasAccess,
    userInfo,
    setUserInfo,
    profileInfo,
    setProfileInfo,
    myProgram,
    setMyProgram,
    ppvProduct,
    setPpvProduct,
    isRestartProgram,
    setIsRestartProgram,
    ppvProgram,
    setPpvProgram,
    selectedMenuId,
    setSelectedMenuId,
    isChangeFavList,
    setIsChangeFavList,
    recordStatus,
    setRecordStatus,
    isClickedRecord,
    setIsClickedRecord,
    selectedCutvProgram,
    setSelectedCutvProgram,
    isLiveSelected,
    setIsLiveSelected,
    adminProfile,
    setAdminProfile,
    isChangeLockChannel,
    setIsChangeLockChannel,
    isChildProfile,
    setIsChildProfile,
    currentProgramTimes,
    setCurrentProgramTimes,
    nextMytvChannelPrograms,
    setNextMytvChannelPrograms,
    prevMytvChannelPrograms,
    setPrevMytvChannelPrograms,
    mytvChannelPosterPath,
    setMytvChannelPosterPath,
    selectedMytvChannelProgram,
    setSelectedMytvChannelProgram,
    userPackage,
    setUserPackage,
    mesMessage,
    setMesMessage,
    isCollectScriptInitialized,
    setIsCollectScriptInitialized,
    isLogout,
    setIsLogout,
    channelItems,
    setChannelItems,
    selectedGenre,
    setSelectedGenre,
    allProfiles,
    setAllProfiles,
    showLoginMessage,
    setShowLoginMessage,
    programInfo,
    setProgramInfo,
    mesFingerPrintParam,
    setMesFingerPrintParam,
    pictureInPicture,
    setPictureInPicture,
    hasLoggedIn,
    setHasLoggedIn,
    playUrl,
    setPlayUrl,
    handleLogout,
    loginGuessProfile
  };

  return (
    <SessionContext.Provider value={values ? values : appData}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
