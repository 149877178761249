import React, { useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";

const ProtectedRoute = ({ children, ...rest }) => {
  const history = useHistory();
  let lang = localStorage.getItem("language");
  let deviceid = localStorage.getItem("deviceid");
  let loginUrl = `${localStorage.getItem(
    "loginurl"
  )}?lan=${lang}&deviceid=${deviceid}`;
  let hasLoggedIn = JSON.parse(localStorage.getItem("hasLoggedIn"));

  return (
    <Route
      {...rest}
      render={({ location }) =>
        hasLoggedIn ? children : history.push("/login")
      }
    />
  );
};

export default ProtectedRoute;
