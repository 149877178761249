import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const AdComponent = ({ adId }) => {
  const adContainerRef = useRef(null);
  window.googletag = window.googletag || { cmd: [] };
  const googletag = window["googletag"] || {};

  useEffect(() => {
    googletag.cmd.push(function () {
      googletag.display(adId);

      const slot = googletag.pubads().getSlots().find(slot => slot.getSlotElementId() === adId);

      if (slot) {
        googletag.pubads().refresh([slot]);
      }
    });
  }, [adId]);

  return <div id={adId} ref={adContainerRef}></div>;
};

AdComponent.propTypes = {
  adId: PropTypes.string.isRequired
};

export default AdComponent;
