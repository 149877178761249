import React, { useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../../context/SessionContext";
import { getUserVersionInfo } from "../../api/settings/Settings";
import localizationStrings from "../../statics/localization";
import { appVersion } from "../../statics/config";
import CheckedIcon from "../../assets/icons/svg/CheckedIcon";
import RecordingIcon from "../../assets/icons/svg/RecordingIcon";
import { useHistory } from "react-router-dom";
import ReturnIcon from "../../assets/icons/svg/ReturnIcon";
import { getSubscription } from "../../api/login/loginApi";
import AngleRightPurple from "../../assets/icons/svg/AngleRightPurple";

const VersionInfo = () => {
  const { userInfo } = useContext(SessionContext);
  const history = useHistory();
  const [subscriptionData, setSubscriptionData] = useState(null);

  const getUserSubscription = async () => {
    const { data } = await getSubscription();
    setSubscriptionData(data[0]);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserSubscription();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (subscriptionData) {
      console.log(subscriptionData.customer);
    }
  }, [subscriptionData]);

  return (
    <div className="settings-container">
      <div>
        <div
          className="settings-icon"
          onClick={() => {
            history.goBack();
          }}
        >
          <ReturnIcon />
          <label style={{ marginLeft: "56px" }}>
            {localizationStrings.versionAndUser}
          </label>
        </div>
      </div>
      <ul className="list">
        <li>
          <label>Kullanıcı Mail</label>
          {subscriptionData && (
            <span className="span">{subscriptionData.customer.email}</span>
          )}
        </li>
        <li>
          <label>{localizationStrings.userCode}</label>
          {/*<span className="span">
            {window.EncryptUtils.dencryptStr(
              decodeURIComponent(userInfo?.usercode)
            )}
          </span>*/}
        </li>
        <li>
          <label>{localizationStrings.appVersion}</label>
          <span className="span">{appVersion}</span>
        </li>
        <li>
          <label>{localizationStrings.ipStatus}</label>
          <span className="span">{userInfo?.userip}</span>
        </li>
        <li>
          <label>{localizationStrings.networkStatus}</label>
          <span className="span">
            {navigator.onLine ? <CheckedIcon /> : <RecordingIcon />}
          </span>
        </li>
        <li>
          <label>{localizationStrings.mwAuth}</label>
          <span className="span">
            {userInfo ? <CheckedIcon /> : <RecordingIcon />}
          </span>
        </li>
        <li>
          <label>{localizationStrings.videoStreamStatus}</label>
          <span className="span">
            <CheckedIcon />
          </span>
        </li>
        <li>
          <label>{localizationStrings.videoDecodeStatus}</label>
          <span className="span">
            <CheckedIcon />
          </span>
        </li>
      </ul>
    </div>
  );
};

export default VersionInfo;
