import React from "react";

const ShareIcon = () => {
  return (
    <svg
      id="share"
      xmlns="http://www.w3.org/2000/svg"
      width="18.498"
      height="18.498"
      viewBox="0 0 18.498 18.498"
    >
      <path
        id="Path_50"
        data-name="Path 50"
        d="M18.232,7.9,10.139,11.95l-.518-1.033L17.714,6.87,18.232,7.9Zm-.518,10.405L9.621,14.262l.518-1.033,8.093,4.047-.518,1.033Z"
        transform="translate(-4.677 -3.34)"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        id="Path_51"
        data-name="Path 51"
        d="M15.608,4.625A1.734,1.734,0,1,0,13.874,2.89,1.734,1.734,0,0,0,15.608,4.625Zm0,1.156a2.89,2.89,0,1,0-2.89-2.89A2.89,2.89,0,0,0,15.608,5.781Zm0,11.561a1.734,1.734,0,1,0-1.734-1.734A1.734,1.734,0,0,0,15.608,17.342Zm0,1.156a2.89,2.89,0,1,0-2.89-2.89A2.89,2.89,0,0,0,15.608,18.5ZM2.89,10.983A1.734,1.734,0,1,0,1.156,9.249,1.734,1.734,0,0,0,2.89,10.983Zm0,1.156A2.89,2.89,0,1,0,0,9.249a2.89,2.89,0,0,0,2.89,2.89Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ShareIcon;
