import React from "react";
import { useHistory } from "react-router-dom";

import LockOpenIcon from "../../assets/icons/svg/LockOpenIcon";
import "./SelectLoginPage.scss";
import localizationStrings from "../../statics/localization";
import LoginWrapper from "../loginWrapper";

const SelectLoginPage = () => {
  const { push } = useHistory();

  const { title, subtitle, easyLogin, or, onePasswordLogin } =
    localizationStrings.login;

  const handleClickOnePassword = () => {
    push("/login");
  };

  return (
    <LoginWrapper>
      <p className="login-title">{title}</p>
      <p className="login-subtitle">{subtitle}</p>
      <button className="easy-login-button">
        <span>
          <LockOpenIcon />
          <p> {easyLogin}</p>
        </span>
        <p className="chip">{localizationStrings.login.new}</p>
      </button>
      <p className="article-1">{or}</p>
      <button onClick={handleClickOnePassword} className="one-password-button">
        {onePasswordLogin}
      </button>
    </LoginWrapper>
  );
};

export default SelectLoginPage;
