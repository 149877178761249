import React, { useContext, useEffect, useRef, useState } from "react";
import SinglePlayIcon from "../../assets/icons/svg/SinglePlayIcon";
import localizationStrings from "../../statics/localization";
import { fancyTimeFormat } from "../../utils/fancyTimeFormat";
import { SessionContext } from "../../context/SessionContext";
import { useHistory, useLocation } from "react-router-dom";
import LikeAction from "../likeAction";
import FavoriteAction from "../favoriteAction";
import useOutsideEnter from "../../utils/useOutsideEnter";
import TrailerPlayer from "../trailerPlayer";
import AudioOnIcon from "../../assets/icons/svg/AudioOnlIcon";
import AudioOffIcon from "../../assets/icons/svg/AudioOffIcon";
import {
  setTrailerAudioOnGlobal,
  getTrailerAudioOnGlobal
} from "../../statics/config";
import mainLoadingGif from "../../assets/loading.gif";
import { getTrailerPlayUrlById, getVodById } from "../../api/vod/vod";
import { prettyTimeFormatter } from "../../utils/prettyTimeFormatter";
import { findPoster } from "../../utils/findPoster";

const MiniVodDetail = ({
  closeModal,
  vodData,
  isVodDetail,
  isFirstRow,
  isRight,
  isLeft
}) => {
  const [hasScale, setHasScale] = useState(false);
  const [width, setWidth] = useState(vodData?.position?.width);
  const [trailerPlayUrl, setTrailerPlayUrl] = useState(null);
  const [canWatch, setCanWatch] = useState(true);
  const [isAudioOn, setIsAudioOn] = useState(getTrailerAudioOnGlobal()); //trailer player sound
  const [showLoading, setShowLoading] = useState(false);
  const [vodItem, setVodItem] = useState(null);

  const { path, setShowLoginMessage, hasLoggedIn } = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();
  const positionLeft = useRef(
    vodData?.position?.x - (0.2 * vodData?.position?.width) / 2
  );
  const positionTop = useRef(null);
  const leaveTimeoutRef = useRef();
  const timeoutRef = useRef(null);
  const modalRef = useRef(null);
  const loadingTimeoutId = useRef(null);

  useOutsideEnter(modalRef, () => {
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
    }
    // leaveTimeoutRef.current = setTimeout(() => {
    setHasScale(false);
    closeModal();
    // leaveTimeoutRef.current = setTimeout(() => {
    // }, 500);
  });

  const calcDuration = () => {
    if (vodItem?.type === "MOVIE") {
      return prettyTimeFormatter(vodItem?.duration);
    } else if (vodItem?.type === "SERIES") {
      return `${vodItem?.children?.length + " " + localizationStrings.season}`;
    }
  };

  const onClickVod = (isPlay = false) => {
    let item = vodData.vod;
    history.push(`${path}/voddetail/${item?.id}${isPlay ? "?play=1" : ""}`);

    loadingTimeoutId.current && clearTimeout(loadingTimeoutId.current);
    loadingTimeoutId.current = setTimeout(() => {
      setShowLoading(false);
      closeModal();
    }, 2000);
  };

  const getVodItem = async (vodId) => {
    const data = await getVodById(vodId);

    getTrailerUrl(vodId);

    const vodInfo = data.data;

    setVodItem(vodInfo);
  };

  const getTrailerUrl = async (vodId) => {
    try {
      const { data } = await getTrailerPlayUrlById(vodId);

      setTimeout(() => {
        setTrailerPlayUrl(data);
      }, 500);
    } catch (err) {
      setTrailerPlayUrl(null);
    }
  };

  useEffect(() => {
    if (vodData?.vod && vodItem) {
      let vod_ = vodData.vod;
      let modalWidth;

      if (vodData?.posterPosition == 7) {
        //yatay poster
        positionTop.current =
          window.pageYOffset +
          vodData?.position.y -
          vodData?.position.height / 2;
        modalWidth = vodData?.position.width * 1.3;
      } else {
        //dikey poster
        positionTop.current =
          window.pageYOffset +
          vodData?.position.y -
          vodData?.position.height / 7;
        modalWidth = vodData?.position.width * 1.7;
      }
      modalWidth = 400;

      positionLeft.current =
        vodData?.position.x - (modalWidth - vodData.position.width) / 2;

      if (isVodDetail) {
        positionTop.current =
          vodData?.position.top - vodData?.position.height / 2;
        positionLeft.current =
          vodData?.position.left +
          7.5 -
          window.innerWidth / 4 -
          (modalWidth - vodData.position.width) / 2;
      }
      if (isFirstRow) {
        positionTop.current = vodData?.position.y;
      }

      if (isLeft) {
        positionLeft.current = 0;
      }

      if (positionLeft.current + modalWidth > window.innerWidth) {
        positionLeft.current = window.innerWidth - modalWidth;
      }

      if (positionLeft.current < 0) {
        positionLeft.current = 0;
      }

      if (vod_?.hastrailer === "1") {
        // getTrailerUrl(vod_.programcode);
      }
      timeoutRef.current && clearTimeout(timeoutRef.current);
      setHasScale(true);
      setWidth(modalWidth);
    }
  }, [vodData, vodItem]);

  useEffect(() => {
    getVodItem(vodData?.vod?.id);
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div
      className={`modal-hover ${hasScale ? "scale" : ""} `}
      style={{
        top: positionTop.current + "px",
        width: width + "px",
        left: positionLeft.current + "px"
      }}
      ref={modalRef}
    >
      <div className={`modal-preview-vod`}>
        <div className="mini-vod-detail-container">
          <div className="image-wrapper">
            {trailerPlayUrl ? (
              <TrailerPlayer
                playUrl={trailerPlayUrl}
                poster={findPoster(vodItem?.posters, "horizontal")}
                isAudioOn={isAudioOn}
              />
            ) : (
              <img
                className={`vod-preview-img ${hasScale ? "scale" : ""} `}
                src={findPoster(vodItem?.posters, "horizontal")}
              />
            )}

            {trailerPlayUrl ? (
              <div
                className={`action-button secondary absolute`}
                onClick={() => {
                  setIsAudioOn(!isAudioOn);
                  setTrailerAudioOnGlobal(!isAudioOn);
                }}
              >
                <span className="icon">
                  {isAudioOn ? <AudioOnIcon /> : <AudioOffIcon />}
                </span>
              </div>
            ) : null}
          </div>
          <div className={`preview-info ${hasScale ? "scale" : ""}`}>
            {!vodItem?.isUnlocked ? (
              <div className="mini-voddetail-row">
                <div className="button-group" style={{ width: "100%" }}>
                  <div
                    className="inspect-button"
                    style={{
                      marginRight: "10px",
                      cursor: !canWatch ? "not-allowed" : ""
                    }}
                    onClick={() => {
                      if (canWatch) {
                        onClickVod(true);
                      }
                    }}
                  >
                    {localizationStrings.preview}
                  </div>{" "}
                  <div className="inspect-button" onClick={() => onClickVod()}>
                    {localizationStrings.inspect}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="mini-voddetail-row">
              <div className="button-group">
                <div
                  className="action-button primary"
                  onClick={() => {
                    if (canWatch && !showLoading) {
                      if (hasLoggedIn) {
                        setShowLoading(true);
                        onClickVod(true);
                      } else {
                        setShowLoginMessage(true);
                      }
                    }
                  }}
                  style={{
                    display: vodItem?.isUnlocked ? "" : "none",
                    cursor: !canWatch ? "not-allowed" : ""
                  }}
                >
                  <span className="icon">
                    {showLoading ? (
                      <div className="loading-gif">
                        <img src={mainLoadingGif} />
                      </div>
                    ) : (
                      <SinglePlayIcon />
                    )}
                  </span>
                </div>
                <FavoriteAction vod={vodItem} />
                <LikeAction vod={vodItem} />
                {/* <ShareAction vod={vodData?.vod} /> */}
              </div>

              <div
                className="inspect-button"
                style={{
                  display: vodItem?.isUnlocked ? "" : "none"
                  // width: vodData?.vod.auth_returncode !== "0" ? "" : "4.3vw"
                }}
                onClick={() => onClickVod()}
              >
                {localizationStrings.inspect}
              </div>
            </div>
            <div className="mini-voddetail-row">
              <div className="description" title={vodItem?.summary}>
                {vodItem?.summary}
              </div>
              {/* 09.04.22 */}
            </div>
            <div className="mini-voddetail-row">
              <div className="vod-info-wrapper">
                <div className="vod-info white">
                  {vodItem?.type === "MOVIE"
                    ? localizationStrings.movie
                    : localizationStrings.series}
                </div>
                <div className="vod-info-seperator"></div>
                <div className="vod-info">{calcDuration()}</div>
                <div className="vod-info-seperator"></div>
                <div className="vod-info">{vodItem?.year}</div>
                <div
                  className="vod-info"
                  style={{
                    marginLeft: "auto",
                    display: !vodItem?.imdbRating ? "none" : ""
                  }}
                >
                  <div className="imdb-point">IMDB {vodItem?.imdbRating}</div>
                </div>
              </div>
            </div>
            {!!vodItem?.imdbRating ? (
              <div className="mini-voddetail-row">
                <div className="vod-info-wrapper">
                  {vodItem?.genre?.split(";").map((item, index) => {
                    return (
                      <div
                        key={item}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="vod-info">{item}</div>
                        {vodItem?.genre?.split(";").length - 1 > index ? (
                          <div className="vod-info-seperator"></div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniVodDetail;
