import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";
import defaultPoster from "../../assets/images/no-portrait-poster.png";
import defaultHorizontalPoster from "../../assets/images/default-vod-horizontal-poster.png";
import TrashIcon from "../../assets/icons/svg/TrashIcon";
import useOutsideClick from "../../utils/useOutsideClick";
import { findPoster } from "../../utils/findPoster";

const VodItem = ({
  item,
  totalCount,
  itemsToShow,
  useSubType,
  showProgress,
  isFavList,
  isVertical,
  fromSearch,
  onHoverItem,
  useContentType,
  index,
  slidesToScroll
}) => {
  const [posterUrl, setPosterUrl] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const { path, userInfo } = useContext(SessionContext);
  const history = useHistory();
  const optionRef = useRef(null);
  const itemRef = useRef(null);
  const timeoutRef = useRef(null);
  const programTypeRef = useRef(null);

  useOutsideClick(optionRef, () => {
    setShowOptions(false);
  });

  const onClickVod = () => {
    history.push(`${path}/voddetail/${item?.id}`);
  };

  useEffect(() => {
    if (item) {
      setPosterUrl(
        isVertical
          ? findPoster(item?.posters, "vertical")
          : findPoster(item?.posters, "horizontal")
      );
    }
  }, [item, isVertical]);

  return (
    <div
      ref={itemRef}
      className="vod-item"
      // style={{
      //   padding:
      //     index === 0
      //       ? "0.78vw 0.78vw 0.78vw 0vw"
      //       : index === totalCount.current - 1
      //       ? "0.78vw 0vw 0.78vw 0.78vw"
      //       : "0.78vw",
      //   position: "relative",
      //   width: itemsToShow ? `${100 / itemsToShow}%` : ""
      // }}
      // onMouseEnter={() => {
      //   if (timeoutRef.current) {
      //     clearTimeout(timeoutRef.current);
      //   }
      //   timeoutRef.current = setTimeout(() => {
      //     let pos = itemRef.current?.getClientRects();
      //     if (pos?.length) {
      //       pos = pos[0];
      //     }
      //     let _data = {
      //       position: pos,
      //       posterPosition: 6,
      //       vod: item
      //     };

      //     onHoverItem(true, _data, index === 0, index % slidesToScroll === 0);
      //   }, 500);
      // }}
      // onMouseLeave={() => {
      //   if (!useContentType) {
      //     clearTimeout(timeoutRef.current);
      //   }
      // }}
    >
      <div
        key={item?.id}
        className="carousel-item"
        style={itemsToShow ? { display: "flex" } : null}
        onClick={(e) => {
          onClickVod();
        }}
      >
        <img
          alt={item?.title}
          className="carousel-poster"
          src={posterUrl}
          onError={() =>
            setPosterUrl(isVertical ? defaultPoster : defaultHorizontalPoster)
          }
        />

        {isFavList ? (
          <div ref={optionRef} className="dot-options">
            <div className="dots-wrapper">
              <div className={`options-box ${showOptions ? "show" : ""}`}>
                <div className="tri-angle"></div>
                <div className="options">
                  <div
                    className="option"
                    // onClick={() => removeFromFav(item, false)}
                  >
                    <TrashIcon />
                    <span>{localizationStrings.removeFromMyList}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {showProgress ? (
        <div className="bookmark-progress">
          <div
            className="progress"
            style={{ width: `${item?.progress}%` }}
          ></div>
        </div>
      ) : null}
    </div>
  );
};

export default VodItem;
