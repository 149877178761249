import React, { useEffect, useContext } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import LiveTv from "../../views/liveTv";
import Navbar from "../navbar";
import Footer from "../footer";
import { getSubscription } from "../../api/login/loginApi";
import Home from "../../views/home/Home";
import { getLoginToken } from "../../api/login/loginApi";
import { getProfilesApi, loginWithProfile } from "../../api/user/user";
import { loginEvent } from "../../api/event/event";
import { SessionContext } from "../../context/SessionContext";

const Routing = () => {
  const history = useHistory();
  const { setHasLoggedIn } = useContext(SessionContext);

  const getUserSubscription = async () => {
    const { data } = await getSubscription();
    if (!data?.length) {
      history.push("/purchase");
    } else {
      history.push("/dashboard/livetv");
    }
  };

  useEffect(() => {
    // localStorage.removeItem("userToken");
    // localStorage.removeItem("profileToken");
    // localStorage.removeItem("hasLoggedIn");
    //getUserSubscription();
  }, []);

  return (
    <div>
      <Navbar />
      <Switch>
        <Route path={`/dashboard/livetv/:channelNo`} component={LiveTv} />
        <Redirect path={`/dashboard/livetv`} to={`/dashboard/livetv/all`} />
        <Route path={`/dashboard/home/:menuId/:categoryId`} component={Home} />
        {/* <Redirect path={`/dashboard`} to={`/dashboard/home/1/all`} /> */}
        {/* <Route path={`/dashboard/tvguide`} component={TvGuide} />
        <Route path={`/dashboard/settings`} component={TivibuSettings} />
        <Route path={`/dashboard/language`} component={Language} />
        <Route path={`/dashboard/version`} component={VersionInfo} />
        <Route
          path={`/dashboard/purchasehistory`}
          component={PurchaseHistory}
        />
        <Route
          path={`/dashboard/suggestioncomplaint`}
          component={SuggestionComplain}
        />
        <Route
          path={`/dashboard/parentalcontrol`}
          component={ParentalControl}
        />
        <Route path={`/dashboard/records`} component={UserRecords} />
        <Route path={`/dashboard/package`} component={PackageList} />
        <Route path={`/dashboard/favlockchannel`} component={LockedChannel} />
        <Route
          path={`/dashboard/genre/:columnCode/:columnName`}
          component={GridView}
        />
        <Route path={`/dashboard/search/:term?`} component={Search} />
        <Redirect
          path={`/dashboard`}
          to={`/dashboard/home/${specialForMeId}/all`}
        /> */}
      </Switch>
      {/* <ModalRouting /> */}

      <Switch></Switch>
      <Footer />
    </div>
  );
};

export default Routing;
