import React from "react";

const PlayerPlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 0 29.154 31.805"
    >
      <path
        id="arrow"
        d="M26.7,20.113,7.292,31.159A4.875,4.875,0,0,1,0,26.943V4.857A4.873,4.873,0,0,1,7.292.647L26.7,11.687a4.842,4.842,0,0,1,0,8.426Z"
        fill="#fff"
      />
    </svg>
  );
};

export default PlayerPlayIcon;
