import React, { useEffect, useRef, useState } from "react";
import SinglePlayIcon from "../../assets/icons/svg/SinglePlayIcon";
import defaultPoster from "../../assets/images/default-vod-horizontal-poster.png";
import localizationStrings from "../../statics/localization";
import { fancyTimeFormat } from "../../utils/fancyTimeFormat";
import useOutsideEnter from "../../utils/useOutsideEnter";
import moment from "moment";
import TrashIcon from "../../assets/icons/svg/TrashIcon";
import Notification from "../notification";
import { deleteNpvrRecord, deleteSerieNpvrRecord } from "../../api/npvr/npvr";
import { sendCustomNpvrReport } from "../../utils/reporting/customReporting";
import ToBeReacordIcon from "../../assets/icons/svg/ToBeReacordIcon";

const MiniProgramDetail = ({
  closeModal,
  programData,
  setRecordRefresh,
  onSelectRecord
}) => {
  const [posterUrl, setPosterUrl] = useState(null);
  const [hasScale, setHasScale] = useState(false);
  const [width, setWidth] = useState(programData?.position?.width);
  const [showNotification, setShowNotification] = useState(false);
  const positionLeft = useRef(
    programData?.position?.x - (0.2 * programData?.position?.width) / 2
  );
  const positionTop = useRef(null);
  const leaveTimeoutRef = useRef();
  const timeoutRef = useRef(null);
  const modalRef = useRef(null);
  const isShowNotification = useRef(false);

  const calcDuration = (beginTime, endTime) => {
    let begintTime_ = moment(beginTime, "YYYY.MM.DD HH:mm");
    let endTime_ = moment(endTime, "YYYY.MM.DD HH:mm");
    let diff = endTime_.diff(begintTime_) / 1000;
    return fancyTimeFormat(diff);
  };

  const deleteSingleRecord = async (item) => {
    const { data } = await deleteNpvrRecord(item?.prevuecode);
    if (data.returncode === "0") {
      setRecordRefresh(true);
    }
    sendCustomNpvrReport(
      item?.prevuecode,
      item.channelcode,
      "",
      0,
      data.returncode,
      data.errormsg
    );
    setShowNotification(false);
    isShowNotification.current = false;
  };

  const deleteSeriesRecord = async (item) => {
    const { data } = await deleteSerieNpvrRecord(item?.seriesheadid);
    if (data.returncode === "0") {
      setRecordRefresh(true);
    }
    sendCustomNpvrReport(
      item.prevuecode,
      item.channelcode,
      item.seriesheadid,
      0,
      data.returncode,
      data.errormsg
    );
    setShowNotification(false);
    isShowNotification.current = false;
  };

  useOutsideEnter(modalRef, () => {
    if (!isShowNotification.current) {
      if (leaveTimeoutRef.current) {
        clearTimeout(leaveTimeoutRef.current);
      }
      setHasScale(false);
      closeModal();
    }
  });

  useEffect(() => {
    if (programData?.data) {
      let url = `${programData?.data.posterpath}/${
        programData?.data.posterfilelist?.split(";")[2]
      }`;
      setPosterUrl(url);
    }
  }, [programData]);

  useEffect(() => {
    if (programData) {
      let modalWidth;
      positionTop.current =
        window.pageYOffset +
        programData?.position.y -
        programData?.position.height / 10;

      modalWidth = 400;

      positionLeft.current =
        programData?.position.x - (modalWidth - programData.position.width) / 2;

      if (
        positionLeft.current + programData?.position.width * 1.3 >
        window.innerWidth
      ) {
        positionLeft.current = window.innerWidth - modalWidth - 15;
      }

      if (positionLeft.current < 0) {
        positionLeft.current = 0;
      }

      if (positionLeft.current + modalWidth > window.innerWidth) {
        positionLeft.current = window.innerWidth - modalWidth;
      }

      timeoutRef.current && clearTimeout(timeoutRef.current);
      setHasScale(true);
      setWidth(modalWidth);
    }
  }, [programData]);

  return (
    <div
      className={`modal-hover ${hasScale ? "scale" : ""} `}
      style={{
        top: positionTop.current + "px",
        width: width + "px",
        left: positionLeft.current + "px"
      }}
      ref={modalRef}
    >
      <div className={`modal-preview-vod`}>
        <div className="mini-vod-detail-container">
          <div className="image-wrapper">
            <img
              className={`vod-preview-img ${hasScale ? "scale" : ""} `}
              src={posterUrl}
              onError={() => setPosterUrl(defaultPoster)}
            />
          </div>
          <div className={`preview-info ${hasScale ? "scale" : ""}`}>
            <div className="mini-voddetail-row">
              <div className="button-group">
                {programData?.data?.status === "1" ? ( //  && programData?.data?.isseries === "0"
                  <div
                    className="action-button primary"
                    onClick={() => onSelectRecord(programData?.data, true)}
                  >
                    <span className="icon">
                      <SinglePlayIcon />
                    </span>
                  </div>
                ) : null}
                {programData?.data?.status !== "1" ? (
                  <div
                    className="action-button secondary"
                    style={{
                      color:
                        programData?.data?.status === "0"
                          ? "#00DEFF"
                          : "#E9253E"
                    }}
                    title={
                      programData?.data?.status === "0"
                        ? localizationStrings.toBeRecorded
                        : programData?.data?.status === "999"
                        ? localizationStrings.recording
                        : ""
                    }
                  >
                    <span className="icon">
                      <ToBeReacordIcon />
                    </span>
                    <span
                      className="text"
                      style={{
                        color:
                          programData?.data?.status === "0"
                            ? "#00DEFF"
                            : "#E9253E"
                      }}
                    >
                      {programData?.data?.status === "0"
                        ? localizationStrings.toBeRecorded
                        : programData?.data?.status === "999"
                        ? localizationStrings.recording
                        : ""}
                    </span>
                  </div>
                ) : null}
              </div>

              <div
                className="inspect-button"
                style={{ width: "4.7vw" }}
                onClick={() => onSelectRecord(programData?.data, false)}
              >
                {localizationStrings.inspect}
              </div>
            </div>
            <div className="mini-voddetail-row">
              <div className="description">
                {programData?.data?.description}
              </div>
            </div>
            <div className="mini-voddetail-row">
              <div className="vod-info-wrapper">
                <div className="vod-info white">
                  {programData?.data?.npvrtype === "2"
                    ? localizationStrings.series
                    : localizationStrings.record}
                </div>
                <div className="vod-info-seperator"></div>
                <div className="vod-info">
                  {calcDuration(
                    programData?.data?.begintime,
                    programData?.data?.endtime
                  )}
                </div>
                <div className="vod-info-seperator"></div>
                <div className="vod-info">{programData?.data.channelname}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showNotification ? (
        <Notification
          text={localizationStrings.deleteRecordConfirmText}
          confirmText={localizationStrings.yes}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={() => {
            if (programData?.data?.isseries === "1") {
              deleteSeriesRecord(programData?.data);
            } else {
              deleteSingleRecord(programData?.data);
            }
          }}
          onClickNotConfirm={() => {
            setShowNotification(false);
            isShowNotification.current = false;
          }}
        />
      ) : null}
    </div>
  );
};

export default MiniProgramDetail;
