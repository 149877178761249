import {
  browserName,
  fullBrowserVersion,
  isEdge,
  isOpera,
  isSafari,
  isFirefox,
  isChrome
} from "react-device-detect";
import { axiosInstance } from "../config/configApi";

let terminalOsType = null;
switch (true) {
  case isChrome:
    terminalOsType = "WebChrome";
    break;
  case isSafari:
    terminalOsType = "WebSafari";
    break;
  case isEdge:
    terminalOsType = "WebEdge";
    break;
  case isFirefox:
    terminalOsType = "WebChrome";
    break;
  case isOpera:
    terminalOsType = "WebChrome";
    break;
  default:
    terminalOsType = "Web";

    break;
}

const loginEvent = async () => {
  return await axiosInstance.post( 'analytics-client/event/login', null,{
    headers: {
      "Device-Type": terminalOsType
    }
  });
};
const vodEvent = async (id) => {
  return await axiosInstance.post(`analytics-client/event/vod-watch/${id}`,null,{
    headers: {
      'Device-Type': terminalOsType
    },
  });
};
const ltvEvent = async (id, duration) => {
  return await axiosInstance.post(`analytics-client/event/ltv-watch/${id}?duration=${duration}`,null,{
    headers: {
      'Device-Type': terminalOsType
    },
  });
};

export { loginEvent, vodEvent, ltvEvent };
